@import "../scss/vars.scss";
.promo-summo {
  background-size: cover;
  background-position: bottom center;
}

.x-sumo-flow {
  padding: 20px 20px 80px 20px;
  min-width: 480px;
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //  box-shadow: 15px 15px 0 rgba(black, 0.8);
  .x-summo-header {
    display: flex;
    justify-content: center;
    img {
      width: 160px;
      &:last-child {
        width: 160px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  h1 {
    font-family: $magdaClean;
    font-size: 28px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
    letter-spacing: 0;
  }
  p {
    text-align: center;
  }
}
