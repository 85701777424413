@import "../scss/vars";

$aitem-width: 220px;
$aitem-height: 357px;

.dna-action-item-card {
  width: $aitem-width;
  height: $aitem-height;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 3px 10px 2px rgba(255, 255, 255, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in;
  background: linear-gradient(to top, rgba(white, 0.98) 30%, rgba(white, 0.85) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .x-hive {
    position: absolute;
    bottom: 40px;
    z-index: 9;
  }

  .ai-nuke {
    position: absolute;
    display: inline-block !important;
    left: 4px;
    top: 3px;
  }

  .ai-tile-footer {
    position: absolute;
    bottom: 20px;
    div {
      font-size: 14px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        font-size: 16px;
        transform: translateY(3px);
      }
    }
  }

  &:hover {
    transform: scale3d(1.1, 1.1, 1);
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.3);
    .ait-timer {
      opacity: 1;
    }
  }

  &.dna-action-done {
    background: linear-gradient(to left, #faffd1, #deffc9) !important;

    color: #131313;
  }

  h3.dna-action-item-title {
    text-align: center;
    font-size: 16px !important;
    font-weight: normal !important;
    max-width: 100%;
    line-height: 21px;
    display: block;
    word-wrap: break-word;
    margin-top: 4px;
    padding: 0 0px;
    margin-bottom: 15px;
  }

  .dna-tile-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    padding-top: 15px;
    user-select: none;

    > label {
      margin: 0;
      font-weight: normal;
      margin-bottom: 4px;
      font-size: 14px;
      opacity: 0.5;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease-in;
      &:hover {
        transform: scale3d(1.1, 1.1, 1);
      }
      //color: $light;

      &.dna-action-title-label {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0px;
      }

      &.dna-action-item-action-label {
        margin-bottom: 7px;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .tile-mission-img {
      transition: all 0.3s ease-in;
      width: 170px;
      height: 140px;
      background-size: cover;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      transform: translateY(-50%);

      z-index: 2;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action-item-finish-this {
      width: 50px;

      position: absolute;
      z-index: 9;
      bottom: 70px;
      right: 10px;
      &.dna-icon-check {
        transform: translateX(60px);
      }
    }

    .tile-msgs {
      position: absolute;
      top: 0px;
      right: 2px;
      display: inline-block;
      width: auto;
      .MuiBadge-badge {
        transform: translate(10px, -11px);
      }
    }

    .dna-action-item-meta {
      position: absolute;
      top: 6px;
      left: 4px;
      display: block !important;
      width: 30px !important;
      text-align: center;
      .dna-sonar-wave {
        margin: 0;
      }
    }

    .tile-mission-title {
      color: white;
      font-size: 13px;
      opacity: 0.5;
      margin: 0;
      text-align: center;
      padding: 5px;
      border-radius: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      display: block;
      font-weight: normal;

      cursor: pointer;
      &:hover {
        color: black;
        background-color: $lime;
        opacity: 1;
      }
    }

    .dna-action-tile-msg {
      text-align: center;

      background: rgba($dark, 0.9);
      color: $light;
      opacity: 0;
      border-radius: 5px;
      line-height: normal;
      font-size: 15px;
      padding: 3px 7px;
      margin: 0 10px;
      transform: translateY(30px);
      transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .invite-msg {
      opacity: 1;
      transform: translateY(-10px);
    }

    .dna-progress-bar {
      width: $aitem-width - 20;
    }
  }

  .ait-timer {
    display: inline-block !important;
    position: absolute;
    left: 20px;
    bottom: 75px;
    width: 40px;
    max-width: 50px;
    transition: all 0.2s;
    opacity: 0;
    &:hover {
      transform: scale(1.1);
    }
  }

  .ait-time {
    width: 100%;
    text-align: center;
    padding-top: 50px;
    font-size: 30px;
    position: absolute;
    bottom: 70px;
  }

  .ait-player {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }

  .ait-exit-player {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  .dna-progress-bar {
    max-width: 400px;
    margin-top: 30px;
    position: absolute;
    bottom: 10px;

    h4 {
      left: 0;
      width: 100%;
      transform: translateY(-16px) !important;
    }
  }

  .clip-0 {
    clip-path: polygon(68% 9%, 96% 33%, 82% 79%, 52% 100%, 0 61%, 24% 0);
  }
  .clip-1 {
    clip-path: polygon(65% 0, 100% 24%, 100% 96%, 65% 100%, 11% 79%, 0 21%);
  }
  .clip-2 {
    clip-path: polygon(30% 0, 89% 12%, 100% 53%, 72% 100%, 11% 79%, 0 21%);
  }
  .clip-3 {
    clip-path: polygon(54% 16%, 92% 3%, 100% 66%, 43% 96%, 0 50%, 21% 16%);
  }
  .clip-4 {
    clip-path: polygon(60% 0, 96% 33%, 100% 83%, 61% 73%, 23% 100%, 0 17%);
  }
  .clip-5 {
    clip-path: polygon(50% 0%, 95% 9%, 100% 75%, 72% 98%, 20% 75%, 7% 25%);
  }
  .clip-6 {
    clip-path: polygon(75% 1%, 95% 23%, 100% 75%, 52% 100%, 7% 79%, 17% 25%);
  }
  .clip-7 {
    clip-path: polygon(68% 0, 96% 33%, 100% 69%, 52% 100%, 21% 91%, 0 23%);
  }
  .clip-8 {
    clip-path: polygon(54% 16%, 92% 3%, 100% 66%, 43% 96%, 0 50%, 21% 16%);
  }
}

.dna-dark {
  .dna-action-item-card {
    box-shadow: 0 3px 8px 1px rgba(255, 255, 255, 0.5);
    background: linear-gradient(to top, rgba(#242425, 0.98) 30%, rgba(#242425, 0.85) 100%);

    &:hover {
      box-shadow: 0 5px 15px 2px rgba(255, 255, 255, 0.5);
    }
  }
}
