.dna-welcome-to-mission {
  min-height: 300px;

  .x-verified-org-badge {
    width: 18px;
    margin-right: 4px;
  }

  .mission-theme {
    padding: 20px 20px;
    position: relative;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    top: 0;
    width: 100%;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    flex-direction: column;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
    min-height: 200px;

    h1 {
      text-align: center;
      line-height: auto;
      font-size: 34px;
      font-weight: 100;
      margin-top: 5px;
    }
  }

  .me-avatar-invite {
    margin-top: -80px;
  }

  h4 {
    margin: 0px 0 0 0;
  }
  h2 {
    margin: 30px 0 0 0;
    font-size: 24px;
  }
  p {
    text-align: center;
    padding: 0 20px;
    margin: 0;
  }
  .nice-msg {
    font-size: 15px;
  }
  .welcome-msg {
    margin-top: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .dna-round-avatar {
      margin-bottom: 10px;
      box-shadow: 0 0 0 4px silver;
    }

    p {
      text-align: left;

      &:last-child {
        margin-top: 5px;
      }
    }
    margin-bottom: 30px;
  }

  .welcome-our-mission {
    text-align: center;
    label {
      padding-left: 19px;
    }
    p {
    }

    margin-bottom: 80px;
  }

  .accept-invite-button {
    position: relative;
    z-index: 20;
    min-width: 200px;
    margin-top: 5px;
  }

  .reject-invite {
    margin-top: 20px;
  }
}
