$mw: 290px;
$mh: 200px;

.dna-mission-tile {
  width: $mw;
  height: $mh;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0px 10px 2px rgba(255, 255, 255, 0.3), 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
  background-size: cover;
  opacity: 0.98;

  &.is-older-tile {
    opacity: 1;
    transform: scale(0.95);
    filter: grayscale(0.7) blur(1px);
  }

  &:hover {
    transform: scale3d(1.03, 1.03, 1);
    opacity: 1 !important;
    filter: none;
    .dna-tile-bg,
    h3 {
      opacity: 1 !important;
    }

    .dna-mission-tile-mkh,
    .dna-mission-tile-plan {
      opacity: 0.9;
      transform: scale(1);
    }
  }

  .dna-pill-box {
    margin-left: 50%;
    transform: translate(-50%, 4px);
    font-size: 12px !important;
    margin-bottom: -15px;
  }

  @include dna-tile-mask;

  .dna-mission-tile-mkh,
  .dna-mission-tile-plan {
    position: absolute;
    width: 90px;
    height: 90px;

    padding-top: 15px;
    background: url(/img/lip-circle.png);
    background-size: cover;
    mix-blend-mode: screen;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 5px;
    font-weight: bold;

    cursor: pointer;
    transition: all 0.3s ease-in;
    pointer-events: all;
    opacity: 0.2;
    filter: grayscale(1);
    transform: scale(0.8);

    &:hover {
      opacity: 1;
      transform: scale(1.2);
      filter: none;
    }
  }

  h1 {
    margin: 0;
    line-height: 50px;
    margin-top: 0px;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 0;
    mask-image: linear-gradient(to top, rgba(black, 0.3) 0%, black 76%);
  }

  .dna-mission-tile-mkh {
    right: 0;
    bottom: 0;
  }
  .dna-mission-tile-plan {
    right: 0;

    top: 30px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    line-height: normal;
    font-weight: 300;
    margin-bottom: 10px;
    opacity: 1 !important;
    margin-top: 10px;
    padding: 0 10px;
  }

  .dna-mission-tile-phases {
    width: $mw;
    position: relative;
    z-index: 0;
    .no-activities {
      font-size: 14px;
      opacity: 0.2;
      text-align: center;
      margin-top: 10px;
    }

    .mt-bar-container {
      transition: all 0.3s ease-in;

      border-radius: 3px;
      height: 30px;
      padding-top: 7px;
      display: block;

      &:hover {
        transform: scale(1.05);
        background: url(/img/noisy.png) rgba($light, 0.2);
        opacity: 1;
        h4 {
          opacity: 0.9;
        }
      }
    }

    .mt-bar-container {
      padding-left: 10px;
      &:nth-child(2) {
        opacity: 0.8;
      }
      &:nth-child(3) {
        opacity: 0.5;
      }
      &:nth-child(4) {
        opacity: 0.3;
      }
      &:nth-child(5) {
        opacity: 0.1;
      }
    }
    .dna-progress-bar {
      h4 {
        display: block;
        font-size: 14px;
        margin-bottom: 0px;
        opacity: 0.5;
        text-align: left;
        position: static;
        transform: translateY(4px);
      }
    }
  }

  .dna-tile-bg {
    transition: opacity 0.2s ease-in;
    z-index: 0;
    pointer-events: none;
  }

  .dna-tile-details {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
    padding: 10px;
    width: 100%;
    max-width: none;

    background: $light;

    h2 {
      font-size: 40px;
    }

    label {
      font-weight: normal;
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .mission-overall-bar {
    //  max-width: 400px;
    margin-top: 30px;
    position: absolute;
    bottom: 10px;
  }

  .dna-people {
    position: absolute;
    bottom: 20px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
  }
}

.dna-board-missions-list {
  max-width: 1020px;
  margin: 0 auto;

  transition: width 0.2s ease-in;

  .dna-iso-item {
    margin: 0 16px 36px 16px;
    min-width: $mw;
  }

  //swap
  &.dna-vision-mission {
    max-width: 1400px !important;
    padding: 0 20px;

    width: 100% !important;

    .dna-iso {
      width: 100% !important;
    }

    .dna-iso-item {
      width: 100%;
      margin-right: 0;
      margin-left: 0;

      .dna-mission-tile {
        width: 100%;
        height: 330px;
        min-height: 330px;

        transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        .dna-tile-bg {
          opacity: 0.1 !important;
          filter: grayscale(1);
        }
        .m-summary {
          opacity: 0;
          font-size: 24px;
          line-height: normal;
          pointer-events: none;
          padding: 0 10px;
          position: relative;
          z-index: 0;
        }

        .dna-tile-details {
          opacity: 1;
          pointer-events: all;
          position: absolute;
          top: 0;
          height: 100%;

          > h2 {
            text-align: center;
            margin-bottom: 0px;
          }

          .dna-text-label-block {
            margin: 0 15px;
            h2 {
              display: block;
              height: 90px;
              line-height: 110px;
              font-weight: bold;
              transform: translateY(-3px);
            }
            h4.dna-donut-label {
              font-size: 20px;
            }
            label {
              text-transform: uppercase;
              font-size: 12px;
            }
          }

          .detail-donuts {
          }

          .detail-team {
            position: absolute;
            bottom: 10px;

            .dna-people {
              position: static;
              transform: none;
            }
            label {
              margin-top: 0px;
              color: $dark;
            }
          }
        }
      }
    }
  }

  .cont {
    transition: width 0.3s ease-in;
    width: 90vw;
    display: flex;
    justify-content: center;
  }

  &.dna-vision-mission {
    .dna-light.cont {
      .dna-iso-item {
        .dna-mission-tile {
          background: $light !important;

          h2,
          p,
          div {
            color: $dark;
          }

          .dna-text-label-block {
            label {
              color: $dark;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .dna-board-missions-list {
    width: 480px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 450px) {
  .dna-board-missions-list {
    width: $mw;
    padding-left: 0px;
  }

  .dna-iso-item,
  .dna-mission-tile {
    width: 380px;
    height: 234px;
  }
}
