.dna-home-actions {
  width: 100vw;

  top: 60px !important;
  overflow: visible;
  mask-image: linear-gradient(to bottom, rgba(black, 0) 0%, black 50px);
  .dna-scroll-body {
    &:after {
      display: block;
      width: 1px;
      height: 300px;
      pointer-events: none;
      content: "";
    }
  }
  ul.hm-ha {
    display: block;
    position: relative;
    margin-left: -100px;
    margin-top: -200px;
    left: 50%;
    top: calc(50% - 60px);
    //transform: translate(-50%, -50%);

    > li {
      position: absolute;
    }
  }

  &.time-track {
    > div:first-child {
      opacity: 0;
    }
    .dna-speed-trigger {
      opacity: 0;
    }
    .home-actions-talk {
      opacity: 0;
    }
    .home-actions-reminders {
      opacity: 0;
    }
  }

  .dna-action-tile {
    margin-bottom: 40px;
  }

  .dna-speed-trigger {
    margin-top: 20px;
  }

  .home-actions-talk {
    position: absolute;
    top: 20px;
    left: 50%;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    font-size: 16px;
    transform: translateX(-50%);
    margin-left: 0px;

    p {
      white-space: nowrap;
      font-family: "Magda", "Courier New", Courier;
      font-weight: 300;
      max-width: 500px;
      text-align: center;
    }
  }

  .home-actions-reminders {
    position: fixed;
    left: 20px;
    top: 100px;
    margin-left: 0px;
    font-family: "Magda", cursive;
    font-weight: 300;

    .dna-checkbox {
      padding: 0;
      margin: 0;
      padding-right: 20px;

      svg {
        transform: scale(0.8);
      }
    }

    strong {
      font-weight: 300 !important;
    }

    > div {
      margin-top: 10px;
      > div {
        display: block;

        div {
          line-height: 15px;
        }
      }
    }

    .my-reminder {
      padding: 5px;
      margin-left: -6px;
      margin-bottom: 5px;

      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease-in;
      position: relative;
      max-width: 300px;
      display: inline-block;
      border-radius: 4px;

      svg {
        float: left;
      }

      &.is-done {
        span {
          opacity: 0.3;
          text-decoration: line-through;
        }
      }

      &:hover {
        transform: scale(1.02);
        background: url(/img/noisy.png) rgba($dark, 0.1);

        .reminder-tools {
          opacity: 1;
          transform: translateX(0px);
          pointer-events: all;
        }
      }

      .reminder-tools {
        top: 0;
        right: 0;
        position: absolute;
        transition: all 0.3s ease-in;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-10px);

        .dna-button {
          padding: 0 10px;
          height: auto;

          &.ch {
            padding: 0 8px;
            svg {
              padding-top: 3px;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }

  .dna-my-mkh {
    display: block;
    position: absolute;
    width: 380px;
    bottom: 60px;
    left: 30px;

    > span {
      font-size: 10px;
      opacity: 0.8;
    }

    li {
      display: block;
      transition: all 0.3s ease-in;
      transform-origin: left;
      line-height: 12px;
      margin-bottom: 5px;
      &:hover {
        transform: scale(1.2);
      }
      position: relative;
      div:first-child {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        font-size: 10px;
      }
      div:last-child {
        position: absolute;
        margin: 3px;
        left: 160px;
        background: rgba($light, 0.8);
        height: 4px;
        top: 1px;
      }
      text-transform: uppercase;

      background: rgba($dark, 0.3) url(/img/noisy.png);
      cursor: pointer;
    }
  }
}

@media screen and (max-height: 700px) {
}

@media only screen and (max-height: 800px) and (min-height: 501px) {
  .dna-home-actions {
    ul.hm-ha {
      margin-top: -100px !important;
    }
  }
}
@media only screen and (max-height: 500px) and (min-height: 80px) {
  .dna-home-actions {
    ul.hm-ha {
      margin-top: -90px !important;
    }
  }
}
