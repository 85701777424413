@import "../scss/vars";

$driveToolbarHeight: 80px;

.dna-drive {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  color: $light;
  background-color: rgba($dark, 0.991);
  font-size: 0.9rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999999999;
  outline: none;
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  animation: 100ms openDriveAnimation ease-out forwards;

  @keyframes openDriveAnimation {
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &-toolbar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $driveToolbarHeight;
    padding: 16px;
    z-index: 100;

    &-info {
      display: flex;
      align-items: center;

      &-avatar {
        padding: 0 8px;
      }

      &-details {
        padding-left: 16px;

        &-file-name {
          font-weight: 700;
        }

        &-uploaded {
          font-size: 0.8em;
          opacity: 0.8;
        }
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-separator {
        width: 1px;
        height: 30px;
        background-color: $light;
        margin: 0 8px;
        opacity: 0.2;
      }

      &-item {
        display: flex;
        align-items: center;
        padding: 8px;
        margin: 0 4px;
        cursor: pointer;
      }

      &-more {
        position: relative;
        color: $dark;
      }

      svg.dna-icon-x,
      circle {
        fill: $light !important;
      }

      .dna-icon-dots {
        transition: unset !important;
        transform: unset !important;
      }
    }
  }

  &-footer {
    height: 20px;
  }

  &-main {
    position: relative;
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;
    min-height: 0;

    &-left,
    &-right {
      flex: 0 1 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dna-drive-nav-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s;

      svg {
        fill: $light;
      }

      &:hover {
        background: $blueAccent;
        svg {
          fill: $dark;
        }
      }
    }

    &-left {
      .dna-drive-nav-button {
        transform: rotate(180deg);
      }
    }

    &-view {
      flex: 0 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 0;

      &-container {
        flex: 0 1 100%;
        box-shadow: rgba(black, 0.35) 0 4px 15px 2px;
        max-height: calc(100vh - #{$driveToolbarHeight});
      }

      &-loader {
        display: none;
      }
    }
  }

  .dna-drive-dots-menu {
    top: 100%;
    right: 0;
    min-width: 180px;
    margin-top: 4px;

    &-item {
      display: flex;
      align-items: center;

      &-delete {
        color: red;

        svg {
          fill: red;
        }
      }

      &-icon {
        width: 40px;
        display: flex;
        align-items: center;
      }
    }
  }

  &-view-box {
    box-shadow: rgba(black, 0.35) 0 4px 15px 2px;
  }
}

.dna-drive-is-loading {
  .dna-drive-main {
    .dna-drive-main-view {
      .dna-drive-main-view-container {
        box-shadow: none;
      }

      .dna-drive-main-view-loader {
        display: block;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        width: 40px;
        height: 40px;
        background-color: rgba(white, 1);
        border-radius: 100%;
        animation: sk-scaleout 1s infinite 300ms ease-in-out;
        opacity: 0;
      }
    }
  }

  @keyframes sk-scaleout {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
