@import "../scss/vars.scss";

.dna-toggle-switch {
  $width: 46px;

  $radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  width: $width;
  height: $radius;

  align-items: center;

  &:before {
    content: "";
    border-radius: 20px;
    display: block;

    height: $radius;
    opacity: 0.9;
    transition: all 0s ease-in;
    //transform: translateY(9px);
    @include bg20;
    box-shadow: inset 1px 1px 1px rgba(black, 0.3);
    min-width: $width;
  }

  .dna-toggle-ball {
    border: 1px solid rgba(gray, 0.2);
    background-color: $light;
    width: $radius;
    height: $radius;
    position: absolute;
    z-index: 1;
    transition: transform 0.15s ease-in;
    border-radius: 50%;
    align-self: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.on {
    .dna-toggle-ball {
      background-color: $mint;
      transform: translateX($width - $radius);
    }
  }

  > span {
    padding-left: 10px;
    display: inline-block;
    transform: translate(-1px);
    white-space: nowrap;
  }
}
