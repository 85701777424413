@import "../scss/vars.scss";

.dna-dark {
  .x-tab-bar {
    .x-tab {
      &:hover {
        color: $dark;
      }
    }
  }
}

.x-tab-bar {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .x-tab-more {
    height: 30px;
    font-size: 20px;
    width: 10px;
    cursor: pointer;
    padding: 0;
    &:hover {
      color: orange;
    }

    svg {
      margin-top: 5px;
    }
  }

  .x-tab {
    border: 0;
    padding: 5px 16px 5px 16px !important;
    user-select: none;
    white-space: nowrap;
    margin: 0px;
    background-color: transparent;
    opacity: 1;
    letter-spacing: 0.3px;
    font-size: 16px;
    transition: all 0.2s;
    cursor: pointer;
    flex-grow: 0;
    position: relative;
    padding-right: 3px;
    text-align: center;

    &.hide {
      transition: all 0s !important;
      opacity: 0 !important;
      pointer-events: none;
      position: absolute;
      z-index: -1;
      top: -100px;
    }

    &.x-clear-tab {
      margin-left: 5px;
      padding: 0 !important;
      background: none;
      &:before {
        display: none;
      }
    }

    &:before {
      @include bg10;
      border-radius: 20px;
      display: block;
      transition: all 0.3s;
      content: "";
      height: 100%;
      box-shadow: 0 1px 1px rgba(black, 0.2);

      position: absolute;
      left: 3px;
      right: 3px;
      top: 0;
      z-index: -1;
    }

    &:hover {
      color: white;
      &:before {
        opacity: 1;

        @include bg100;
      }
    }
    &.active {
      color: white !important;
      &:before {
        background-image: radial-gradient(circle at bottom right, gold, $accent);
      }
      &:after {
        content: "▼";
        font-size: 15px;
        display: block;
        color: #fb4b72;
        position: absolute;
        bottom: 8px;
        left: 20px;
        z-index: 0px;
        transform: translate(0%, 100%) scaleY(0.7);
      }
    }
  }
}

.x-tabs-more-menu {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transform: translate(-100%, 20px);
  position: fixed;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 3px rgba(gray, 0.4);
  @include bgSolid;
  transition: max-height 1s;
  max-height: 1px;
  overflow: hidden;
  min-width: 250px;

  &.open {
    opacity: 1;
    z-index: 9999999999;
    max-height: 800px;
    overflow: hidden;
    pointer-events: all;
  }

  li {
    border-radius: 5px;
    @include bg05;
    padding: 10px 7px;
    margin-bottom: 2px;
    cursor: pointer;
    font-size: 15px;
    justify-content: flex-start !important;

    transition: background 0.3s;

    &.hide {
      display: none;
    }

    svg {
      transform: translateY(2px);

      margin-right: 5px;
    }

    &:hover {
      @include bg100;
    }
  }
}
