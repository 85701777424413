.dna-mission-progress-comp {

  max-width: 400px;
  margin: 0 auto;

  .dna-phase-affection-bar{
    cursor: pointer;
    padding: 5px;
    margin-bottom: 10px;
    padding-top: 10px;
    border-radius: 6px;
    margin-left: -10px;
    padding-left: 15px;
    &:hover {
      @include bg10;
    }

    .dna-progress-bar {
      margin-top: 10px;
      margin-bottom: 8px;
    }

    .insights-phase-names{
      display: flex;
      align-items: flex-start;
    }

    .pill {
      padding: 0px 20px 1px 10px;
      border-radius: 12px;
      user-select: none;
      display: inline-block;
      @include bg100;
      transform: translateX(-4px);
      margin-bottom: 15px;
      min-width: 100px;
      max-width: 330px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }

    .progress-pill .dna-pill-box:not(:first-child){
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
      &.active{
        opacity:1
      }
    }

    .progress-pill{
      margin-bottom: 10px;
    }
    >span{

      font-size: 14px !important;
      display: block;

      margin-top: 3px;
    }

  }

  .dna-link{
    display: inline-block;
    width: auto;
  }

  .mp-actions{
    span {
      font-size: 12px;
      display: block;
      margin-top: -5px;
      padding-left: 10px;
      overflow: hidden;
      width:50%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
