@import "../scss/vars";
@mixin comms-bg {
  @include bgSolid;
  border-radius: 15px;
  box-shadow: 0 0 6px rgba(black, 0.01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.panel-notification {
  background-color: #f5f5f5;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  .x-panel-close {
    right: 10px;
  }

  .x-comms-bar {
    width: 70px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    ul {
      display: block;
      padding-left: 13px;
      li {
        border-radius: 6px;
        height: 38px;
        margin-bottom: 12px;
        transition: all 0.3s;
        padding-top: 6px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        align-self: flex-start;
        @include bg05;

        cursor: pointer;
        &:hover,
        &.active {
          @include bg20;
        }
      }

      > spanX {
        &:nth-child(1) {
          li.active {
            background-color: $orange;
            color: white;
          }
        }
        &:nth-child(2) {
          li.active {
            background-color: darken($mint, 10%);
            color: white;
          }
        }
        &:nth-child(3) {
          li.active {
            background-color: $blue;
            color: white;
          }
        }
        &:nth-child(4) {
          li.active {
            background-color: #b68ccf;
            color: white;
          }
        }
      }
    }
  }

  .x-comms-messages {
    @include comms-bg;
    width: 500px;
    position: relative;

    .x-mark-all-as-read {
      cursor: pointer;
      position: absolute;
      transform: translate(0px, -16px);
      text-transform: uppercase;
      font-size: 9px;
    }

    .x-comms-filter-bar {
      transform: translateY(-5px);
      position: absolute;
      right: 21px;
      top: 20px;
      display: flex;
      align-items: center;

      .dna-under-con {
        position: absolute;
        transform: translateX(-100%);
        min-width: 37px;
        min-height: 37px;

        transition: all 0.4s;

        svg {
          font-size: 16px;
          pointer-events: none;
          transition: all 0.4s;
        }

        &:hover {
          background: $orange;
          color: white !important;

          svg {
            transform: scaleX(-1);
            fill: white;
          }
        }
      }

      input {
        border-radius: 8px;
        margin-left: 10px;
        max-width: 100px;
        padding-left: 10px;
        transition: all 0.3s;

        &:focus {
          max-width: 250px;
          background-color: rgba(yellow, 0.2);
        }
      }
    }

    .x-comms-scroll {
      border-top: 1px solid;
      height: calc(100vh - 110px);

      .x-comms-chats {
        padding-right: 10px;
      }
    }

    &.chats {
      .x-comms-scroll {
        height: calc(100vh - 145px);
      }
    }
    &.pinned {
      .x-comms-scroll {
        height: calc(100vh - 145px);
      }
      .x-comms-message-subject {
        span {
          color: $accent;
        }
      }
    }

    h3 {
      font-weight: 100;
    }

    .x-comms-message {
      padding: 10px;
      padding-left: 5px;
      padding-right: 15px;
      margin-top: 7px;
      margin-bottom: 7px;
      display: flex;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      @include bg05;
      &:hover {
        @include bg10;
      }

      .x-comms-distance {
        opacity: 0.5;
        font-size: 11px;
        margin-right: 0px;
        white-space: nowrap;
        padding-left: 5px;
      }

      .x-comms-content {
        min-width: 370px;
        max-width: 370px;
        margin-bottom: 7px;
      }
      .x-comms-message-header {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }
      .x-comms-message-subject {
        color: $blue;
        font-weight: bold;
        width: 100%;
        margin-bottom: 5px;
      }
      .x-comms-message-sub-subject {
        margin-top: -3px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
        opacity: 0.5;
      }
      .x-comms-message-body {
        width: 100%;
        align-self: flex-start;
      }

      &:not(.read) {
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $accent;
          position: absolute;
          display: block;
          z-index: 2;
          pointer-events: none;
          transform: translate(1px, 1px);
        }
      }

      &.read {
        background-color: none;
        .x-comms-message-header {
          font-weight: normal;
        }
        .x-comms-message-subject {
          color: unset;
          font-weight: 100;
          span {
            color: unset;
          }
        }
        .x-comms-message-body {
          font-weight: 100;
          opacity: 0.6;
        }
      }
    }
  }

  .x-comms-chat {
    @include comms-bg;
    height: calc(100vh - 40px);
    padding-top: 10px;
    width: 380px;
    margin: 20px;
    position: relative;
    .x-comms-chat-context {
      height: 55px;
      border-bottom: 1px solid;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .x-link {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 285px;
        align-self: flex-start;
        flex: 1;

        &.t-tip {
          display: block !important;
          width: 30px;
          max-width: 30px;
          margin-left: 5px;
          text-align: center;
        }
      }
      .dna-flex-apart {
        display: flex;
        width: 300px;
        max-width: 285px;
      }
    }

    .x-aix-messages {
      height: calc(100vh - 120px);
      .x-aix-comment {
        padding-left: 0;
      }
      .x-aix-input-wrapper {
        margin-left: 0;
      }
    }
  }
}

.dna-dark {
  .panel-notification {
    background-color: $dark;
  }
}
