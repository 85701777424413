.dna-hill-chart {
        path.recharts-area-curve {
          stroke:rgba($dark,0.5);
        }
}

.dna-dark{
  path.recharts-area-curve {
    stroke:rgba($light,0.8);
  }
}
