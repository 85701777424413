.dna-donut-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.red {
    .recharts-pie-sector:first-child {
      path.recharts-sector {
        fill: $accent !important;
        stroke: 0;
      }
    }
  }

  &.blue {
    .recharts-pie-sector:first-child {
      path.recharts-sector {
        fill: $blueAccent !important;
        stroke: 0;
      }
    }
  }
  &.gold {
    .recharts-pie-sector:first-child {
      path.recharts-sector {
        fill: gold !important;
        stroke: 0;
      }
    }
  }
  &.mint {
    .recharts-pie-sector:first-child {
      path.recharts-sector {
        fill: $mint !important;
        stroke: 0;
      }
    }
  }

  h4.dna-donut-label {
    display: block;
    position: absolute;
    padding-left: 0px;
    margin: 0;
    text-align: center;
    margin: 0;
    font-weight: 100 !important;
    font-size: 24px;
    transform: translateY(0);

    span {
      font-size: inherit;
      font-weight: inherit;
      display: flex;

      margin-left: 5px;
      color: inherit;
      font-weight: 100 !important;

      justify-content: center;
      align-items: flex-start;
      text-align: center;
      transform: translateX(8px);
      sup {
        font-size: 12px;
        opacity: 1;
        display: block;
        transform: translateY(3px);
        font-weight: 100;
      }
    }
  }
  label.dna-sub-label {
    position: fixed;
    z-index: 9;
    font-weight: 100;
    font-size: 12px;
    transform: translate(4px, 14px);
  }
  .recharts-sector {
    fill: $dark;
    stroke: 0;
  }
  .recharts-pie-sector:nth-child(1) {
    opacity: 1;
  }
  .recharts-pie-sector:nth-child(2) {
    opacity: 0.1;
  }
}

.dna-light {
  .dna-donut-chart {
    label,
    span {
      color: $dark;
    }

    .recharts-sector {
      fill: $dark !important;
      stroke: 0;
    }
    .recharts-pie-sector:nth-child(1) {
      opacity: 1;
    }
    .recharts-pie-sector:nth-child(2) {
      opacity: 0.1;
    }
  }
}

.dna-dark {
  .dna-donut-chart {
    .recharts-sector {
      fill: $light;
      stroke: 0;
    }
    .recharts-pie-sector:nth-child(1) {
      opacity: 1;
    }
    .recharts-pie-sector:nth-child(2) {
      opacity: 0.1;
    }
  }
}
