@import "./vars";
@import "./table";
@import "./charts";
@import "./flex";
@import "./dates";
@import "simplebar/dist/simplebar.min.css";
@import "./mission.scss";
@import "../comp/Button.scss";

.mAX {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.x-bg-flip {
  background: #f3f3f3 !important;
}

.dna-dark {
  .x-bg-flip {
    background: $bgDark;
  }
}

.x-bg-orange {
  background: orange;
  color: white;
}
.x-bg-red {
  background: $accent;
  color: white;
}
.x-bg-green {
  background: $mint;
  color: black;
}
.x-hl-text {
  font-weight: 800;
  background-color: white;
  background-image: linear-gradient(135deg, #9e7aff 0%, #fe8bbb 33.33%, #ffbd7a 66.67%, #f8eac3 100%);
  background-image: linear-gradient(
    135deg,
    color(display-p3 0.6196078431 0.4784313725 1/1) 0%,
    color(display-p3 0.9960784314 0.5450980392 0.7333333333/1) 33.33%,
    color(display-p3 1 0.7411764706 0.4784313725/1) 66.67%,
    color(display-p3 0.9725490196 0.9176470588 0.7647058824/1) 100%
  );

  &.blue {
    background-image: linear-gradient(45deg, #4f3ce4 14.64%, #00a0f5 50.01%, #1fd9c1 85.36%);
  }

  &.bold {
    font-weight: 800;
  }

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.x-small-text {
  font-size: 12px;
  font-weight: 100;
  opacity: 0.7;
}

.x-inset-light {
  border-radius: 8px;
  @include bg05;
  padding: 10px;

  .react-date-picker,
  .dna-drop-down input {
    @include bgSolid;
  }

  &.orange {
    background-color: lighten(orange, 44%);
    color: $bgDark !important;
    box-shadow: 2px 2px 0 1px rgba(darken(orange, 10%), 0.2);

    .dna-checkbox {
      path:first-child {
        stroke: $dark;
      }

      &.active {
        path:last-child {
          fill: $dark;
        }
      }
    }

    input {
      border: 1px solid rgba(gray, 0.2);
      color: $dark;
      background-color: white;
    }

    .react-tags {
      button {
        background-color: #f5f5f5;
        color: $dark;
      }
      * {
        color: $dark !important;
      }
      input {
        background-color: white !important;
        color: $dark;
        &::placeholder {
          color: $dark;
        }
      }
    }
  }
  &.mint {
    background-color: rgba($mint, 0.1);
    box-shadow: 2px 2px 0 1px rgba(darken($mint, 10%), 0.2);
  }
  &.red {
    background-color: rgba($accent, 0.1);
    .dna-checkbox {
      path:first-child {
        stroke: $dark;
      }

      &.active {
        path:last-child {
          fill: $dark;
        }
      }
    }

    input {
      border: 1px solid rgba(gray, 0.2);
      color: $dark;
      background-color: white;
    }

    .react-tags {
      button {
        background-color: #f5f5f5;
        color: $dark;
      }
      * {
        color: $dark !important;
      }
      input {
        background-color: white !important;
        color: $dark;
        &::placeholder {
          color: $dark;
        }
      }
    }
  }
}

.x-button-tab {
  height: 40px;
  line-height: 40px;
  @include bg10;
  border-radius: 4px;
  padding: 0 14px;
  display: inline-block;
  margin-right: 3px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    @include bg20;
  }

  &.active {
    cursor: default;
    @include bg100;
  }
}

.a-kill {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $orange;
  }
}
.x-c-change-icon {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 10px;
  transform: rotate(0deg);
  display: inline-block;
  &:hover {
    opacity: 1;
    color: $accent;
    transform: rotate(-45deg);
  }
}
.x-solid-bg {
  @include bgSolid;
}
.x-shrink-space {
  letter-spacing: -0.3px;
}
.x-error-card {
  padding: 10px !important;

  padding-right: 40px !important;
  img {
    height: 50px;
    margin-top: 10px;
  }
  p {
    margin-bottom: 5px;
    margin-left: 10px;
    width: 100%;
    text-align: left;
  }
  .dna-smaller-text {
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.x-chit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 100px;
  padding: 5px;
  padding-right: 20px;
  @include bg05;
  min-width: 150px;

  &.ten {
    @include bg10;
    &:hover {
      @include bg20;
    }
  }

  &:hover {
    @include bg10;
  }
}

.x-person-chit {
  align-items: center;
  display: flex;

  &.red {
    .t-tip,
    > div:first-child {
      background-color: $accent !important;
      background-blend-mode: luminosity !important;
    }
  }
  &.orange {
    .t-tip,
    > div:first-child {
      background-color: $orange !important;
      background-blend-mode: luminosity !important;
    }
  }
  &.gold {
    .t-tip,
    > div:first-child {
      background-color: gold !important;
      background-blend-mode: luminosity !important;
    }
  }
  &.mint {
    .t-tip,
    > div:first-child {
      background-color: $mint !important;
      background-blend-mode: luminosity !important;
    }
  }

  div {
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    &.dna-smaller-text {
      font-weight: 100;
      font-size: 12px;
      opacity: 0.8;
    }
  }
}

.x-hash-bg {
  background-image: linear-gradient(
    -45deg,
    rgba(gray, 0.22) 25%,
    transparent 25%,
    transparent 50%,
    rgba(gray, 0.22) 50%,
    rgba(gray, 0.22) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-size: 4px 4px !important;
}

.x-clamp-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.x-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.x-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.x-unsaved {
  border: 1px solid #cc0000;
  margin-top: 30px;
  border-radius: 3px;
  border-top-left-radius: 0;

  textarea {
    vertical-align: top;
  }
  &:before {
    content: "✋ Unsaved changes️";
    display: inline-block;
    font-size: 10px;

    transform: translate(-1px, -20px);
    padding: 3px 8px 3px 6px;
    background-color: #cc0000;
    z-index: 9999999999999999;
    color: white;
    border-radius: 3px;
    position: absolute;
    text-transform: uppercase;
  }
}

.x-more {
  display: flex;
  span,
  span:after,
  span:before {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    @include bg100;
    display: inline-block;
    position: absolute;
  }
  span {
    &:after {
      content: "";
      transform: translateX(6px);
    }
    &:before {
      content: "";
      transform: translateX(-6px);
    }
  }
}
.x-chart-table {
  width: auto;
  thead {
    td {
      font-size: 8px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  td {
    font-size: 12px;
    text-align: right;
    padding-left: 10px;
    &:first-child {
      text-align: left;

      padding-left: 0;
    }
  }
  .dna-progress-bar-x {
    width: 200px;
    min-height: 6px;
    max-height: 6px;
  }
}
.mx-admin-trigger,
.mx-error-trigger {
  position: fixed;
  top: 50px;
  left: 15px;
  z-index: 2;
  font-size: 10px;
  @include bg10;
  padding: 3px 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: red;
    color: white;
  }
}
.mx-error-trigger {
  left: 70px;
}
.x-help-trigger {
  position: fixed;
  z-index: 1;
  right: 18px;
  bottom: 10px;
  cursor: pointer;

  img {
    transition: all 0.2s ease-out;
    height: 44px;
  }
}

// ReCaptcha - Disable bottom right badge
.grecaptcha-badge {
  visibility: hidden;
}

.x-noisy {
  background: url(/img/noisy.png);
}

.mx-logo-line {
  content: url("/img/mx-logo-line-black.svg");
}

.mx-brand-orange,
.dna-orange {
  font-weight: normal;
  color: $orange;
}

.x-text-pill {
  padding: 1px 8px 1px 7px;
  border-radius: 16px;
  display: inline-block;
  &.orange {
    background-color: rgba($orange, 0.2);
  }
  &.mint {
    background-color: rgba($mint, 0.3);
  }
}

.x-inset {
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 4px 4px 0 black;
  border: 1px solid black;
  @include bgSolid;
  font-size: 14px;
  padding-bottom: 25px;
  transition: all 0.4s;
  &.slim {
    padding: 10px;
  }
  &:hover {
    box-shadow: 8px 8px 0 black;
  }

  &.red {
    background-color: red;
    color: white;
  }
}

.x-scrollbars {
  > div:last-child,
  > div:nth-child(2) {
    > div {
      background-color: #444;
    }

    transition: all 0.2s;
    opacity: 0.2 !important;
  }

  &:hover {
    > div:last-child,
    > div:nth-child(2) {
      opacity: 0.9 !important;
    }
  }
}

.dna-dark {
  .x-inset {
    padding: 20px 20px;
    border-radius: 5px;
    border: 1px solid #fafafa;
    &:hover {
      box-shadow: 8px 8px 0 #fafafa;
    }
  }
  .x-scrollbars {
    > div:last-child,
    > div:nth-child(2) {
      div {
        background: #666 !important;
      }

      //  opacity: 0.5 !important;
    }
  }
  .mx-logo-line {
    content: url("/img/mx-logo-line-white.svg");
  }
}

.x-org-logo-box {
  background-size: contain;
  background-repeat: no-repeat;
}

.x-con {
  width: 30px;
  height: 30px;
  align-self: flex-start;
  border-radius: 50%;
  @include bg10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  svg {
    pointer-events: none;
    font-size: 17px;
  }

  &:hover {
    background: $lime;
    color: black;
  }
}

.x-number-table {
  border-collapse: collapse;
  tbody tr {
    border-bottom: 1px dotted gray;
    &:last-child {
      td {
        padding-bottom: 4px;
      }
      border-bottom: 1px solid white;
    }
    td {
      padding-top: 8px;
    }
  }
  thead {
    td {
      font-weight: normal;
      padding-bottom: 5px;
    }
  }
  td {
    font-size: 13px;
    font-weight: normal;

    &:first-child {
      width: 80%;
      text-align: left;
    }
    &:last-child,
    &.summary-cell {
      width: 20%;
      white-space: nowrap;
      text-align: right;
      padding-left: 10px;
    }
    &.summary-cell {
      padding-right: 8px;
    }

    &.right {
      white-space: nowrap;
      text-align: right;
    }
  }
  tfoot {
    tr {
      border: 0;
    }
    td {
      font-weight: bold;
      padding-top: 7px;
    }
  }
}

.x-sortable-drag {
  opacity: 0 !important;
}
.x-sortable-ghost {
  box-shadow: 0px 0px 0 1px red;
}
.x-sortable-chosen {
  box-shadow: 0px 0px 0 1px black;
  z-index: 9999;
}

@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;

  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;

    @if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left: $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }
}

.x-promo {
  top: 85px;
  cursor: pointer;
  left: 30px;
  position: fixed;
  z-index: 2;

  img {
    width: 25px;
    position: absolute;
  }
}

.pxp-section-marker {
  position: absolute;
  margin-bottom: 80px;
  font-size: 13px;
  width: 64px;
  height: 30px;
  background: #21749a;
  transform: translate(-85px, 18px);
  line-height: 30px;
  span {
    display: inline-block;
    transform: translate(6px, 0);
    color: white;
    text-shadow: -1px -1px 1px darken(#21749a, 7%);
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    @include triangle(8px, darken(#21749a, 15%), down-right);
    top: -8px;
  }
  &:after {
    @include triangle(30px 12px, #21749a, right);
    right: -12px;
  }
}

.dna-app-msg {
  position: fixed;
  z-index: 99999999999;
  background: #ff416c; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 40px;
  left: 0;
  right: 0;
  color: white;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%);
  transition: all 0.3s;
  text-align: center;
  line-height: 40px;
}
.dna-show-msg {
  .dna-app-msg {
    transform: translateY(0%);
    opacity: 1;
  }
}

.dna-cal-trigger {
  position: fixed;
  right: 95px;
  z-index: 2;
  top: 26px;
  display: block;
  opacity: 0.9;
  cursor: pointer;
  transition: transform 0.2s ease;
  transform-origin: center;
  &:hover {
    transform: scale(1.2);
    svg {
      path:last-child {
        fill: $accent;
      }
    }
  }
}
.dna-search-trigger {
  position: fixed;
  z-index: 1;
  right: 90px;
  top: 21px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  .mini-dna & {
    display: none;
  }
}

.dna-speed-trigger {
  position: fixed;
  right: 32px;
  top: 85px;
  cursor: pointer;
  z-index: 1;
  opacity: 1;

  svg {
    transform: scale(0.92);
  }
}

.dna-leave-trigger {
  position: fixed;
  display: block !important;
  right: 33px;

  text-align: center;
  top: 130px;

  cursor: pointer;
  z-index: 1;
  cursor: pointer;
}

.x-mx-admin-control {
  position: fixed;
  right: 34px;
  top: 250px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}

.x-wfh-trigger {
  position: fixed;
  right: 33px;
  top: 207px;
  cursor: pointer;
  z-index: 1;
  opacity: 1;
  font-size: 22px;
  opacity: 0.8;
  transition-delay: 0.3s;
  transition: transform 0.3s;

  &:hover {
    opacity: 1;
    color: #a26eb8;
    transform: scale(1.2);
  }
}

.x-expense-trigger {
  position: fixed;
  right: 35px;
  top: 172px;
  cursor: pointer;
  z-index: 1;
  opacity: 1;
  font-size: 18px;
  opacity: 0.8;
  transition-delay: 0.3s;
  transition: transform 0.3s;

  &:hover {
    opacity: 1;
    color: #79b593;
    transform: scale(1.2);
  }
}

.x-er {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
  }

  h2 {
    margin-top: 30px;
  }

  p {
    max-width: 500px;
    text-align: justify;
    width: 500px;
  }
}
.x-money-pill {
  text-align: right;
  margin: 0;
  font-size: 12px;
  padding: 2px 5px;
  line-height: 14px;
  padding-left: 8px;
  border-radius: 30px;

  display: inline-block;
  background-color: $blueGray;
  color: white;

  svg {
    font-size: 9px;
    color: orange;
    transform: translate(-2px, 0px);
  }
}
.dna-strike {
  text-decoration: line-through;
}
.dna-no-letter-spacing,
.dna-no-letter-spacing * {
  letter-spacing: 0;
}
.dna-progress-bar-x {
  width: 100%;
  min-height: 11px;
  height: 11px;
  @include bg10;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  &.thin {
    min-height: 3px;
    max-height: 3px;
  }

  > div {
    height: 11px;
    @include x-grad;
    transition: width 0.3s ease-in;
  }
}

.bg-10 {
  @include bg10;
}
.bg-5 {
  @include bg05;
}
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
.dna-no-a {
  text-decoration: none;
  font-size: inherit;
  text-transform: none;
  text-shadow: none;
  letter-spacing: inherit;
  color: inherit;
  transition: none;
  cursor: pointer;
  &:hover {
    transform: none;
    text-decoration: underline;
  }
}

.dna-tip {
  position: fixed;
  @include bg100;
  padding: 10px 10px;
  z-index: 99999999999;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(black, 0.3);
  transform: translate(-50%, -100%);
  margin-top: -10px;
  transition: all 0.1s;
  max-width: 180px;
  text-align: center;
  font-size: 14px;

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: black transparent transparent transparent;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }
}

.dna-dark {
  .dna-tip {
    &:after {
      border-color: white transparent transparent transparent;
    }
  }
}

.x-themes {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  li {
    position: relative;
    display: block;
    width: 60px;
    height: 40px;
    margin: 1px;
    background-size: cover;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      z-index: 2;
      transform: scale(1.3);
    }
    &.active {
      box-shadow: 0 0 0 2px gold;
    }
  }
}

.dna-tootip-theme.x-nav-tip {
  position: fixed;
  left: 120px;
  top: 24px;
  color: white;
  padding: 0px 10px !important;
  line-height: 30px;
  svg {
    transform: translateY(2px);
  }
}

.visibility-hidden {
  visibility: hidden;
}
.display-none-print {
  display: none;
}

.dna-disable,
.dna-disabled {
  pointer-events: none;
  cursor: default;
  user-select: none;
}

.dna-disable-kids {
  * {
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
}

.dna-inline {
  display: inline-block;
}

.dna-caps {
  text-transform: uppercase;
}

.no-select {
  user-select: none;
}

.dna-padd-left {
  padding-left: 20px;
}

.cursor {
  cursor: pointer;
}

.x-link {
  flex-grow: 0;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-block;
  transition: all 0.2s;
  line-height: normal;
  color: inherit;
  cursor: pointer;
  @include bg05;
  &.inline {
    display: inline;
  }

  &.orange {
    color: $orange;
  }
  &.gold {
    color: gold;
  }

  &.opacity {
    color: rgba($dark, 0.7);
  }

  &.caps {
    font-size: 12px;
    text-transform: uppercase;

    &.small {
      font-size: 10px;
    }
  }

  &.small {
    font-size: 12px;
  }

  &:hover,
  &.active {
    opacity: 1;

    background-color: lighten(#fc5644, 5%) !important;
    color: white !important;
  }

  &.active {
    &:hover {
      background-color: darken(#fc5644, 11%) !important;
      color: white !important;
    }
  }
}

.dna-dark {
  .x-link {
    &.orange {
      color: $orange;
    }

    &.opacity {
      color: rgba(white, 0.7);
    }

    &:hover {
      opacity: 1;

      background-color: lighten(#fc5644, 5%) !important;
      color: white !important;
    }
  }
}

.dna-smaller-text {
  font-size: 12px;
  line-height: normal;
}
.x-small-text {
  font-size: 12px;
  line-height: normal;
  font-weight: 100;
}

.dna-person-snip {
  div {
    font-size: 14px;
  }
  .x-snip-name {
    font-size: 16px;
  }
}
.dna-person-chit {
  width: 400px;
  display: flex;
  .dna-round-avatar {
    margin-right: 10px;
  }
  strong {
    font-size: 15px;
  }
  p {
    width: 200px;
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
  }
}

.dna-no-click {
  pointer-events: none;
  * {
    pointer-events: none;
  }
}
//BASICS

.sub-label {
  font-size: 14px;
  opacity: 0.8;
}

.dna-dashed-bottom {
  border-bottom: 1px dashed gray;
}

.x-left {
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
}

.dna-ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dna-chat {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 55px;
  height: 55px;
}

.dna-upper-case {
  text-transform: uppercase;
}

.dna-dark {
  .dna-check-row {
    &:before {
      background-position: 0px 0px;
    }
    &.active {
      &:before {
        background-position: center right 0px;
      }
    }
  }
}

.dna-divider {
  &:after {
    display: block;
    content: "";
    height: 2px;
    @include bg80;
    margin-top: 10px;
    width: 50%;
  }
}

.dna-check-row {
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    @include bg05;
  }
  &:before {
    display: block;
    content: "";
    flex-shrink: 0;
    flex-grow: 0;
    width: 39px;
    height: 41px;
    background-image: url("/img/checks.png");
    background-position: 0px 0px;
    background-size: 118px;
    margin-right: 10px;
    background-repeat: no-repeat;
  }

  &.active {
    &:before {
      background-position: center right 0px;
    }
  }
}

.dna-table-label-row {
  td {
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.5;
    vertical-align: top;
  }
}

.dna-main-loading-data {
  position: fixed;
  left: 50%;
  top: 45%;
  z-index: 99;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 500px;
  p {
    letter-spacing: 0px;
    text-transform: uppercase;
    width: 250px;
    text-align: center;
  }
}
.x-caps {
  text-transform: uppercase;
}
.magda {
  font-family: $magda;
}
.magda-clean {
  font-family: $magdaClean;
}

.dna-label {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  font-size: 11px;
  opacity: 0.7;
  margin: 0;
  font-weight: 100;
  text-transform: uppercase;

  &:hover {
    opacity: 1;
  }
}

.courier {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
}

.cap-me {
  text-transform: capitalize;
}

.dna-monies {
  width: 105px;
  text-align: right;
  margin-left: -2px;
}

.dna-lime-hover {
  &:hover {
    background: $lime;
    color: #111;
  }
}

.dna-accept-cookies {
  width: 100%;

  position: fixed;
  bottom: 0;
  padding: 20px;
  background: $dark;
  padding-bottom: 10px;

  z-index: 80;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";

  h2,
  p {
    font-family: "Courier New", Courier, "Lucida Sans Typewriter";
    line-height: 20px;
  }
  h2 {
    font-size: 20px;
  }
  p {
    max-width: 1000px;
    letter-spacing: 2px;
    font-size: 16px;
  }
  a {
    margin-left: 30px;
    &.dna-button {
      margin-left: 0;
      min-width: 120px;
    }
  }
}

body a.reset {
  &,
  &:hover {
    font-family: inherit;
    color: inherit;
    text-shadow: none;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    transform: none;
    line-height: inherit;
    transition: unset;
  }
}

.dna-monies {
  width: 100px;
  text-align: right;
}

.my-badge {
  width: 13px;
  height: 13px;
  background: red;
  font-size: 9px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(1px, -1px);
  padding-left: 1px;
  color: white;
  text-align: center;
}

.cp-intro {
  padding: 50px;
  min-width: 400px;

  span {
    display: inline-block;
    transform: translate(3px, 2px) rotate(45deg);
  }
}

.dna-georgia {
  font-weight: bold;
  font-family: Georgia, serif;
  font-style: italic;
}

.dna-confirm-panel {
  border-radius: 5px;
  background-color: $bgDark;
  background-image: url(/img/noisy.png);
  position: fixed;
  z-index: 99999999999;
  top: 10%;
  left: 50%;
  padding: 20px;
  box-shadow: 0 0 4px rgba(black, 0.3);

  p {
    text-align: center;
    max-width: none;
  }
}

.dna-inset {
  @include bg05;
  border: 1px solid rgba($color: silver, $alpha: 0.8);
  border-radius: 8px;
  padding: 10px 15px;

  &.inset-round {
    border-radius: 30px;
  }

  &.dna-padding {
    padding: 20px;
  }

  &.green {
    border: 1px solid rgba($color: green, $alpha: 0.5);
    background-color: rgba($mint, 0.1);
  }
  &.red {
    border: 1px solid rgba($color: red, $alpha: 0.5);
    background-color: rgba(red, 0.1);
  }
  &.yellow {
    border: 1px solid rgba($color: gold, $alpha: 0.5);
    background-color: rgba(gold, 0.1);
  }
}

.dna-inset-avatars {
  margin-right: 10px;
  width: 42px;
  .dna-round-avatar {
    width: 30px;
    height: 30px;
    clear: both;
    position: absolute;

    &:last-child {
      width: 24px;

      box-shadow: 0 0 0 3px rgba(silver, 0.4);
      height: 24px;
      transform: translate(13px, 13px);
    }
  }
}

.dna-under-con {
  width: 30px;
  height: 30px;
  border-radius: 30px;

  @include bg10;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 0px;
  user-select: none;
  transition: all 0.2s;

  img {
    max-width: 24px;
    max-height: 24px;
  }

  &.t-tip {
    display: flex !important;
    justify-content: center;
    align-items: center;
    span {
      display: flex !important;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: 18px;
      //align-self: center;
    }

    svg {
      line-height: 0;
    }
  }

  &.x-with-badge {
    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: translate(11px, -10px);
      background-color: red;
    }
  }

  &.small-con,
  &.small {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
  &.smaller {
    min-width: 26px;
    min-height: 26px;
    max-width: 26px;
    max-height: 26px;
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }

  &.orange {
    background-color: $orange;
    color: white;
  }

  &.mint {
    background: rgba(lighten($mint, 10%), 1);
    color: $bgDark !important;

    &:hover {
      background: rgba($mint, 1);
    }
  }

  &.uc-notice,
  &.lime {
    background: rgba($lime, 0.5);

    &:hover {
      box-shadow: 0 1px 3px rgba(black, 0.3);
    }
  }
  &.uc-warning,
  &.red,
  &.pink {
    background: rgba(red, 0.1);

    &:hover {
      background: red;
      * {
        color: white !important;
      }
      color: white !important;
    }
  }
  &.flip {
    @include bg100;
  }
  &.full-red {
    background: red;
    color: white;
  }
  &.green {
    background: rgba($mint, 0.3);

    &:hover {
      @include bg10;
    }
  }

  &:hover {
    background: $lime;
    color: $dark;

    svg {
      color: $dark;
    }
  }

  &.active {
    background: $lime;
    color: $dark;
  }
  position: relative;

  cursor: pointer;
}

.dna-dark .dna-under-con {
  &.uc-warning,
  &.red,
  &.pink {
    background: rgba(pink, 0.5);

    &:hover {
      background: red;
      * {
        color: white !important;
      }
      color: white !important;
    }
  }
}

.x-flag {
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(black, 0.2);
  position: relative;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  img {
    height: auto !important;
    display: block !important;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(45deg, rgba(white, 0.3), rgba(black, 0));
  }
}
.dna-under-bar {
  position: fixed;
  display: flex;
  top: 58px;
  left: 16px;

  flex-direction: column;
  overflow: hidden;
  z-index: 8;
}
.dna-dotted-line {
  border-bottom: 1px dashed silver;
}

.dna-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &.wrap {
    flex-wrap: wrap;
  }
}

.x-summary-numbers {
  justify-content: flex-end;
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 3px;
  padding: 6px 15px 8px 15px;
  border-radius: 6px;
  @include bg08;

  &.green {
    background-color: rgba($mint, 0.3);
  }
  &.red {
    background-color: rgba($accent, 0.3);
  }
  &.orange {
    background-color: rgba($orange, 0.3);
  }

  &.left {
    margin-left: 0;
    margin-right: 2px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 28px;
    text-align: right;
  }
  span {
    display: block;
    font-size: 9px;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 1px;
  }
}

.tip-left {
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
}

.dna-inline-flex {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dna-flex-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.dna-flex-end-col {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.dna-flex-left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.dna-flex-end {
  display: flex;
  justify-content: flex-end;
}
.dna-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dna-flex-apart,
.dna-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dna-flex-apart-center {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.dna-col-flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.dna-wrap-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.dna-add-to-do-card {
  background: rgba(60, 60, 60, 99) url(/img/noisy.png) !important;
  position: fixed;
  min-width: 450px;
  left: 180px;
  top: 20px;
  z-index: 99999;
}

.only-print {
  display: none;
}

.dna-hidden {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }
  .display-none-print {
    display: block;
  }
  .only-print {
    display: block;
  }

  .dna-ai-knobs {
    top: 10px !important;
    left: 10px !important;
    width: 40px !important;
    background: none !important;
    .inner-knob {
      display: none !important;
    }
    .k-home {
      display: block !important;
      overflow: visible;
      position: fixed;
      right: 0px;
      top: 0px;
      @include bgSolid;

      img {
        position: fixed;
        right: 0px;
        top: 0px;
      }
    }
  }

  #root {
    display: none;
  }

  .add-sit-knob {
    display: none !important;
    margin-left: 10px;
    margin-top: 10px;
  }
  .dna-me-avatar {
    display: none !important;
  }
}

.dna-fade-control-panel {
  .dna-ai-knobs,
  .dna-tip-notification {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
  .dna-tip-notification-unread,
  .dna-tip-notification-has-unread {
    opacity: 1 !important;
  }
}

.dna-show-control-panel {
  .dna-app-c-panel-adders {
    opacity: 1;
    pointer-events: all;
  }

  .dna-ai-knobs {
    width: 450px !important;
    opacity: 1 !important;
    border-radius: 18px;
    background-color: rgba(60, 60, 60, 0.95) !important;
    backdrop-filter: blur(5px);
    border-top-left-radius: 18px;
    padding-bottom: 4px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .panel-menu {
      .cpanel-chev {
        position: absolute;
        right: 4px;

        transform: translateY(0px) rotate(90deg);
      }
    }
  }
  .dna-add-to-do-card {
    left: 500px;
  }
}

.dna-dark {
  .add-sit-knob,
  .dna-ai-knobs {
    background-color: rgba(#999, 0.4);
  }
}

.add-sit-knob,
.dna-ai-knobs {
  position: fixed;
  background-color: rgba($dark, 0.09);
  background-image: url(/img/noisy.png);
  z-index: 101;
  top: 13px;
}
.add-sit-knob {
  left: 122px;
  cursor: pointer;
  text-align: center;
  background-color: rgba($dark, 0.1);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 80;
  border-radius: 50%;
  height: 34px;
  width: 34px;

  .t-tip {
    display: block;
  }
  &:hover {
    background-color: $lime;
    svg {
      .fill-me {
        stroke: $dark;
      }
      &.dna-icon-path {
        path {
          fill: $dark !important;
        }
      }
    }
    //@include bg20;
  }
  svg {
    transform: translate(-1px, 2px);
  }
}

.x-app-org-logo-trigger {
  display: flex !important;
  position: fixed;
  left: 174px;
  top: 12px;
  z-index: 6;
  padding: 0;

  &.home {
    width: 37px;
    height: 37px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    &:hover {
      box-shadow: 0 0 0 4px rgba(silver, 0.4);
    }
  }

  img {
    min-height: 37px;
    height: 37px;
    max-width: 120px;
  }

  transition: all 0.3s;
  cursor: pointer;
}

.dna-qt-trigger {
  svg {
    opacity: 0.8;
    display: inline-block;
    transform: translate(0px, 3px);
  }
  margin-left: 45px;
}
span.req {
  color: $accent;
  font-weight: normal;
}

.dna-app-c-panel-adders {
  position: absolute;
  display: flex;
  right: 35px;
  align-items: flex-start;
  transform: translate(0, 3px);
  pointer-events: none;
  opacity: 1;

  svg {
    //transform: translateY(3px);
  }

  > span {
    font-size: 14px;
    transform: translate(-5px, 4px);
    opacity: 0.8;
  }

  .inner-knob {
    background-color: rgba($light, 0.08);
    margin-right: 4px;
    &:hover {
      svg {
        color: black !important;
      }
    }
  }

  .t-tip {
    display: flex !important;
    align-items: center;
    transition: all 0.2s;
  }
}

.dna-ai-knobs {
  padding: 2px 3px;
  transition: all 0.2s;
  border-radius: 30px;
  z-index: 998;
  left: 15px;

  .panel-menu {
    display: flex;
    align-items: flex-start;
    .cpanel-chev {
      display: block;
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }
  }

  .inner-knob {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;

    &:hover {
      background-color: $lime !important;
      svg {
        .fill-me {
          stroke: $dark;
        }
      }
      svg.dna-icon-path {
        path {
          fill: $dark !important;
        }
      }
      //@include bg20;
    }
    &.k-home {
      @include bgSolid;
      img {
        width: 22px;
      }
    }
    &.k-board {
      width: 32px;

      svg {
        transform: translateY(8px) scale(0.77);
      }
      transform: translateX(6px);
    }

    &.k-chevron {
      transform: translateX(2px);
      svg {
        transform: scale(2.1) rotate(90deg);
      }
    }
  }
}

.dna-inline {
  display: inline-block;
}

.dna-todo-panel {
  padding: 15px 30px 30px 30px;
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
  .dna-input.w-350 {
    display: block;
    clear: both;
  }
  .dna-button {
    margin-top: 20px;
  }

  label {
    transition: all 0.2s ease-in;
  }

  &.has-mission {
    .m-thing {
      margin-top: -10px;
      transform: scale(0.9);
      filter: blur(1px);
      label {
        transform: translateY(2px);
      }
    }
  }
  &.has-phase {
    .m-thing {
      transform: scale(0.75);
      filter: blur(1.5px);
      margin-top: -10px;
      label {
        transform: translateY(10px);
      }
    }

    .p-thing {
      transform: scale(0.85);
      filter: blur(1px);
      margin-top: -20px;
      label {
        transform: translateY(8px);
      }
    }
  }

  &.has-action {
    .m-thing {
      transform: scale(0.7);
      filter: blur(2px);
      margin-top: -15px;
    }

    .p-thing {
      transform: scale(0.8);
      filter: blur(1.5px);
      margin-top: -22px;
    }
    .a-thing {
      transform: scale(0.9);
      filter: blur(0.5px);
      margin-top: -14px;
    }
  }

  .m-thing,
  .p-thing,
  .a-thing {
    transition: all 0.2s ease-in;
    transform-origin: center;

    &:hover {
      filter: none !important;
      transform: none !important;
      label {
        transform: none !important;
      }
    }
  }

  .a-thing label .t-tip {
    margin-left: 20px;
  }
}

.dna-history-row {
  margin-top: 20px;
  p.person-time {
    margin-bottom: 4px;
    .dna-avatar {
      margin-left: 10px;
      margin-right: 10px;
    }
    font-weight: bold;
  }
  .dna-h-entry {
    //margin-top: -5px;
    margin-bottom: 10px;
    padding-left: 52px;
  }
}

.dna-chevron::before {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  display: inline-block;
  height: 0.35em;
  left: 0.15em;
  position: relative;
  //top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.35em;
}

.dna-chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.dna-chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.dna-chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.dna {
  min-height: 100vh;
  transition: all 0.5s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .burst {
    box-shadow: 0px 1px 3px rgba($dark, 0.2);
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include bgSolid;
    border-radius: 50%;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    //box-shadow: 1px 0px 4px rgba($dark,0.3);

    font-size: 18px;

    &:hover {
      transform: scale(1.5);
    }
  }
}

.dots-menu {
  position: fixed;
  z-index: 888;
  box-shadow: 0 0 10px rgba($dark, 0.3);
  padding: 10px 15px 10px 10px;
  border-radius: 6px;
  background-color: $dark;
}

.dna-dark {
  .dna-modal {
    &.white-modal {
      background-color: rgba(black, 0.8);
      backdrop-filter: blur(4px);
    }
  }
}

.dna-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.2s;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba($dark, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  //backdrop-filter: blur(4px);
  &.no-animation {
    backdrop-filter: none !important;
    transition: none !important;
    background-color: rgba($dark, 0.7);
  }

  &.white-modal {
    background-color: rgba(white, 0.8);
    backdrop-filter: blur(4px);
  }

  &.blur {
    backdrop-filter: blur(2px);
  }

  &.with-close {
    &:after {
      display: block;
      content: "CLOSE";
      letter-spacing: 2px;
      font-family: sans-serif;
      position: absolute;
      right: 30px;
      cursor: pointer;
      color: $light;
      top: 30px;
      font-size: 10px;
    }
  }
}

.dna-ai-talk.loading-chat {
  margin-top: -30px;
  transition: all 0.3s ease-in;
  pointer-events: none;
  white-space: nowrap;
}

.dna-mission-landing {
  .dna-ai-talk.loading-chat {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.dna-menu-list {
  background-color: $bgDark;
  display: block;
  z-index: 99999;

  li {
    cursor: pointer;
    display: inline-block;
    background: url(/img/noisy.png) rgba(white, 0.1);
    padding: 8px;
    padding-right: 20px;
    margin-bottom: 2px;
    transition: all 0.2s;

    display: block;
    width: 100%;
    text-align: left;
    color: white;
    border-radius: 3px;

    svg {
      transform: translateY(2px);
      font-size: 16px;
    }

    &.mint {
      background: $mint;
      color: $dark;
    }
    &.orange {
      background: $orange;
      color: white;
    }

    &:hover {
      background: url(/img/noisy.png) rgba(white, 0.2);
      &.red {
        background: red;
        color: white;
      }
    }
  }
}

.dna-dark {
  .dna-tootip-theme {
    ul.dna-menu-list {
      background: none !important;
      li {
        color: inherit;
        background: url(/img/noisy-light.png) rgba(white, 0.04);

        &:hover {
          background: url(/img/noisy-light.png) rgba(white, 0.1);
          &.red {
            background: red;
            color: white;
          }
        }
      }
    }
  }
}

.dna-link-list {
  display: block;
  li {
    cursor: pointer;
    display: inline-block;
    padding: 2px 20px;
    margin: 5px;
    transition: all 0.2s;
    border-radius: 50px;
    @include bg10;
    &:hover {
      background: $blueAccent;
      color: $dark;
    }

    &.active {
      background: $blueAccent;
      color: $dark;
    }
  }
}

.dna-link {
  cursor: pointer;
  //opacity: 0.6;
  display: inline-block;
  transition: all 0.2s ease-in;
  font-size: 12px;
  text-transform: uppercase;
  &:hover {
    //opacity: 1;
    transform: scale(1.1) !important;
  }
}
.dna-me-avatar {
  position: absolute;
  z-index: 999;
  right: 27px;
  top: 14px;
  cursor: pointer;
  width: 42px;
  height: 42px;

  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 6px rgba(gray, 0.3), 0 0 0 12px rgba(gray, 0.1);
}

.dna-not-base {
  .dna-me-avatar {
    opacity: 0;
    top: -68px;
    &:hover {
      opacity: 1;
      top: -12px;
    }
  }
}

.dna-no-me-avatar {
  .dna-me-avatar {
    opacity: 0;
    pointer-events: none;
  }
}

.dna-ai-text *:not(h2) {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  font-size: 14px;
  p {
    margin-top: 5px;
  }
  strong {
    display: block;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.strong {
  font-weight: bold;
}

.dna-ai-talk {
  position: fixed;
  top: 45px;
  left: 20px;
  max-width: 260px;

  .talk-p,
  > p {
    @include bg100;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba($dark, 0.2);
    font-size: 14px;
    max-width: none;
    display: inline;
    padding: 3px 5px 3px 5px;
    line-height: 27px;
    font-family: "Courier New", Courier, "Lucida Sans Typewriter";

    &.is-actionable {
      cursor: pointer;
      display: inline;
      transition: all 0.3s;
      padding-bottom: 4px;
      padding-top: 3px;

      &:hover {
        transform: scale(1.1);
      }
    }

    &.dna-ai-accent {
      color: $dark;
      background-color: $blueAccent;
    }
  }

  .dna-ai-red {
    background-color: $accent !important;
    color: $light !important;
  }
}

.x-round-avatars {
  display: flex;
  flex-wrap: wrap;
  li:not(:first-child) {
    transform: translateX(-15%);
  }
  li {
    position: relative;
    .dna-round-avatar {
      box-shadow: 0 0 0px 2px silver;
    }
  }
  @for $i from 1 through 10 {
    li:nth-child(#{$i}) {
      z-index: 10 - $i;
    }
  }
}

.dna-pointer,
.dna-cursor {
  cursor: pointer;
}
.dna-nav {
  position: fixed;
  top: 90px;
  right: 65px;

  z-index: 2;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;

  li {
    white-space: nowrap;
    width: 40px;
    overflow: hidden;

    display: block;
    transition: all 0.3s ease;
    padding: 0;
    margin: 0;
    padding: 5px;
    cursor: pointer;
    position: absolute;
  }

  .nav-home,
  .nav-board {
    opacity: 0;
  }

  /*.dna-show-home & {
    .nav-home {
      transform:translateY(-120%);
      opacity: 0;
      pointer-events: none;
    }
    .nav-board {
      transform:translateY(0);
      opacity: 1;
      pointer-events: all;
    }
  }
  .dna-show-board & {
    .nav-home {
      transform:translateY(0);
      opacity: 1;
      pointer-events: all;
    }
    .nav-board {
      transform:translateY(120%);
      opacity: 0;
      pointer-events: none;
    }
  }*/
}

.nav-board-trigger {
  height: 40px;

  width: 30px;

  display: block !important;
}

.dna-no-wrap,
.no-wrap {
  white-space: nowrap;
}

.dna-blur-app {
  > div:not(.no-blur) {
    transition: none !important;
    filter: blur(5px);
  }
}

.dna-tone-down {
  .dna-board .dna-scroll-body,
  .dna-home > div,
  .dna-home > ul,
  .dna-home > h2 {
    opacity: 0.2;
  }
}

.dna-mute-phase {
  .dna-phase-screen {
    visibility: hidden;
  }
}

//COMMON

.dna-padding {
  padding: 30px;
}
.dna-no-space {
  margin: 0;
  padding: 0;
}
.dna-space-top {
  margin-top: 50px;
}
.dna-space-right {
  margin-right: 20px;
}
.dna-space-left {
  margin-left: 20px;
}
.dna-space-list {
  margin-right: 10px;
}
.dna-icon {
  width: 50px;
  height: 50px;
}

.dna-center {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-grow: 0;
}

.x-grad {
  @include x-grad;
  border-radius: 8px;
  color: white;
  font-size: 14px;
}

.dna-center-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dna-middle-flex {
  display: flex;
  align-items: center;
}

.dna-main-model {
  background-color: rgba(white, 0.8);
  backdrop-filter: blur(6px) !important;
  color: #333;

  font-size: 12px;
  .dna-sticker-label {
    padding: 3px 7px;
  }
}

.dna-dark {
  .dna-main-model {
    background-color: rgba($dark, 0.9);
    color: white;
  }
}
.dna-center-col,
.dna-flex-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.dna-col-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.dna-center-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dna-center-text {
  text-align: center !important;
}
.dna-float-right {
  float: right !important;
}
.dna-right-text {
  text-align: right !important;
}
.dna-left-text {
  text-align: left !important;
}
.dna-col {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.dna-loading-col {
  height: 100%;
  min-height: 70vh;
  justify-content: center;
}

.dna-dark {
  color: $light;
}
.dna-light {
  color: $dark;
  label {
    color: $dark;
  }
}
.dna-dark-bg {
  background-color: $bgDark !important;
  color: $light;
}
.dna-light-bg {
  background-color: $light !important;
  color: $dark;
}
.dna-red-bg {
  background: linear-gradient(165deg, #af0031 0%, #fc008f 96%);
  color: $light !important;
}
.dna-ok-bg {
  background: linear-gradient(120deg, #16b5d1 20%, #85f789 100%);
  color: $light;
}
.dna-light-text {
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.x-blue-text {
  color: $blue;
}
.x-orange-text {
  color: $orange;
}
.x-mint-text {
  color: #6be3c7;
}
.dna-red-text {
  color: red;
}
.dna-pic-blocks {
  overflow: hidden;
  li {
    width: 60px;
    height: 40px;
    margin: 3px;
    border-radius: 3px;
    float: left;
    cursor: pointer;
    transition: all 0.2s ease-in;
    background-size: cover;
    box-shadow: 0px 2px 3px rgba($dark, 0.3);

    &:hover {
      transform: scale(1.1);
    }

    &.selected {
    }
  }

  .dna-dark & {
    li {
      box-shadow: 1px 1px 3px rgba($light, 0.4);
      border: 1px solid rgba($light, 0.5);
    }
  }
}

.mini-dna {
  .dna-card {
    overflow: auto;
  }
}

.dna-blur-divs {
  > div:not(.dna-my-space-card) {
    filter: blur(20px);
    transition-delay: 0.5s;
  }
}

.dna-translucent-bg {
  background: url(/img/noisy.png) rgba($light, 0.92);
  .dna-dark & {
    background: url(/img/noisy.png) rgba($dark, 0.92);
  }
}

.dna-ghost-image {
  width: 300px;
  height: 300px;

  background-size: cover;
  background-position: bottom left;
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.4;
  z-index: -1;
  pointer-events: none;
  mask-image: radial-gradient(ellipse at top right, black 5%, transparent 70%);
  &.bottom-right {
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
    mask-image: radial-gradient(ellipse at bottom right, black 20%, transparent 60%);
  }
  &.place-left {
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
    mask-image: radial-gradient(ellipse at bottom left, black 20%, transparent 60%);
  }
}

.dna-bar-chart-line {
  height: 10px;
  border-radius: 5px;
  @include bg10;

  .bar-so-far {
    height: 100%;
    max-width: 100%;
    border-radius: 5px;
    @include bg100;
  }

  &.blue {
    .bar-so-far {
      background-color: $blueAccent;
    }
  }
  &.orange {
    .bar-so-far {
      background-color: orange;
    }
  }
  &.red {
    .bar-so-far {
      background-color: $accent;
    }
  }
}

.dna-text-label-block {
  margin: 10px 25px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  h1,
  h2 {
    display: block;
    margin: 0;
    text-align: right;
  }
  label {
    display: block;
    text-align: right;
    opacity: 0.5;
    width: 100%;
    margin: 0;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
  }
}

.dna-action-border {
  padding: 0px 20px;
  border: 1px solid $dark;
  height: 36px;
  border-radius: 2px;
  .dna-svg-icon {
    transform: translateY(-3px);
  }
}

.dna-dark {
  .dna-action-border {
    mask-image: linear-gradient(to top, rgba(black, 0.3) 0%, black 76%);
    border: 1px solid $light;
  }
}

.dna-value-label {
  h3 {
    margin: 0;
  }
  label {
    font-weight: 100;
    text-transform: uppercase;
    margin: 0;
    font-size: 12px;
    opacity: 0.7;
  }
}

.dna-accent-text,
.dna-blue-accent {
  color: $blueAccent !important;
  * {
    color: $blueAccent !important;
  }
}

.dna-good-text {
  color: #76e400;
}

.dna-pill-box {
  padding: 4px 15px;
  border-radius: 20px;
  @include bg08;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  user-select: none;
  font-size: 14px;
  flex-grow: 0;
  //box-shadow: 2px 2px 0 rgba(black, 0.3);

  .dna-pill-box {
    box-shadow: none !important;
  }

  &.large {
    padding: 10px 20px;
  }

  .ca-ri-icon {
    transform: translateY(2px);
  }

  &:hover {
    @include bg15;
  }

  &.click-able {
    cursor: pointer;
    &:hover {
      background: $mint;
      color: black;
      text-shadow: none !important;
      box-shadow: 0 1px 4px rgba(gray, 0.3);
    }
  }

  &.black-pill {
    background: $dark;
    color: $light;
  }

  &.white-pill {
    border: 1px solid #e7e7e7;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 1%, rgba(247, 247, 247, 1) 100%);
    color: $dark;
  }

  &.x-pill {
    background: radial-gradient(circle at bottom right, orange, $accent);
    color: $light !important;
  }
  &.light-orange-pill {
    background: lighten($orange, 35%);
    color: $bgDark !important;
  }
  &.light-light-orange-pill {
    background: lighten($orange, 38%);
    color: $bgDark !important;
  }
  &.mint-pill {
    background: $mint;
    color: $dark !important;

    &:hover {
      background: darken($mint, 15%);
    }
  }

  &.p-pill {
    padding: 0;
    display: block;
    height: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    > div {
      display: block;
      height: 100%;
      padding: 0;
    }
  }

  &.progress-pill {
    display: flex;
    @include bg10;
    height: 9px;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    overflow: hidden;

    > div,
    .t-tip > div {
      padding: 0;
      margin: 0;
      height: 100%;
      min-height: 100%;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.orange-pill {
    background: linear-gradient(135deg, rgba(255, 195, 76, 1) 0%, rgba(255, 146, 10, 1) 100%);
    color: $light;
    box-shadow: 0 0 8px rgba(orange, 0.6);
  }
  &.blue-pill {
    background-color: #84cbfa;
    text-shadow: -1px -1px 1px darken(#84cbfa, 10%);
    color: white;
  }

  &.green-pill {
    background: #b3ff75;
    color: $dark !important;
  }
  &.lime-pill {
    background: $lime;
    color: $bgDark;
  }
  &.violet-pill {
    background: #96efff;
    color: $bgDark;
  }
  &.red-pill {
    background: linear-gradient(135deg, rgba(183, 3, 57, 1) 0%, rgba(239, 0, 195, 1) 100%);
    color: $light;
    box-shadow: 0 0 5px rgba(red, 0.6);
  }

  &.pill-shadow {
    box-shadow: 0px 1px 3px rgba($dark, 0.3);
  }
}
.dna-pill-box-bg {
  padding: 2px 10px;
  border-radius: 15px;
  @include bg95;
  cursor: pointer;
  display: inline-block;
}

.dna-dark {
  .dna-pill-box {
    &.progress-pill {
      background: rgba($light, 0.2);
    }

    &.white-pill {
      border: 0;
      background: linear-gradient(to bottom, rgba(50, 50, 50, 1) 1%, rgba(60, 60, 60, 1) 100%);
      color: white;
    }
  }
}

.dna-accent {
  color: $accent;
}
.dna-mint {
  color: $mint;
}
.dna-gold {
  color: gold;
}
.dna-issue-text {
  color: $issueAccent !important;
}
.dna-lime-text {
  color: $lime !important;
}
.dna-orange-text {
  color: #ffb300 !important;
}

.dna-scroll-trigger {
  position: fixed;
  top: auto;
  height: 100%;
  bottom: 0;
  width: 20px;
  transition: all 0.2s ease-in;
  z-index: 80;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bg05;

  &:hover {
    @include bg10;
  }
  &.on-right {
    right: 0;
    cursor: e-resize;
  }
  &.on-left {
    left: 0;
    cursor: w-resize;
  }
  &.on-bottom {
    left: 0;
    top: auto !important;
    bottom: 0;
    height: 20px;
    width: 100% !important;
    right: 0;
    cursor: s-resize;
  }
}

.dna-accent-tab {
  border-radius: 3px;
  background-color: $accent;
  color: white;
  padding: 0px 8px;
  width: auto;
  display: inline-block;
  font-weight: normal;
}

.dna-clear {
  clear: both;
  width: 100%;
}

.x-tabular {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  span:last-child {
    text-align: right;
  }
}

.dna-list {
  z-index: 0;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url("/img/noisy.png");
    @include bg05;
    padding: 10px;
    padding-right: 40px;
    margin-bottom: 1px;
    position: relative;
    .dna-round-avatar,
    .dna-avatar {
      margin-right: 15px;
    }
    h4 {
      margin-bottom: 0px;
      margin-top: 0;
    }
    &:hover {
      @include bg10;
    }

    .dna-light-text {
      font-size: 14px;
    }

    .t-tip:last-child {
      display: block;
      position: absolute;
      opacity: 0.2;
      right: 5px;
      top: 0px;
      .dna-button {
        padding: 0 8px;
      }
    }
    &:hover {
      .t-tip:last-child {
        opacity: 1;
      }
    }
    &.active {
      @include bg20;
    }
  }
}

.dna-sonar {
  display: flex;
  align-items: center;
  justify-content: center;

  .dna-sonar-wave {
    z-index: -1;
    position: absolute;
    border-radius: 50%;
    animation: sonarWave 2s linear infinite;
    margin-left: -3px;
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.5;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.w-20 {
  width: 20px;
}
.w-25 {
  width: 25px;
}
.w-40 {
  width: 40px;
}
.w-50 {
  width: 50px;
}
.w-60 {
  width: 60px;
}
.w-70 {
  width: 70px;
}
.w-80 {
  width: 80px;
}
.w-90 {
  width: 90px;
}

.w-100 {
  width: 100px;
}
.w-full,
.dna-button.w-full {
  width: 100%;
}
.w-110 {
  width: 110px;
}
.w-120 {
  width: 120px;
}
.w-130 {
  width: 130px;
}
.w-140 {
  width: 140px;
}
.w-150 {
  width: 150px;
}
.w-160 {
  width: 160px;
}

.w-170 {
  width: 170px;
}
.w-180 {
  width: 180px;
}
.w-190 {
  width: 190px;
}
.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}
.w-300 {
  width: 300px;
}
.w-350 {
  width: 350px;
}
.w-400 {
  width: 400px;
}
.w-450 {
  width: 450px;
}
.w-500 {
  width: 500px;
}
.w-550 {
  width: 550px;
}
.w-600 {
  width: 600px;
}
.w-700 {
  width: 700px;
}
.w-750 {
  width: 750px;
}
.w-800 {
  width: 800px;
}
.w-space-right {
  margin-right: 10px;
}

.dna-highlight-text {
  background: yellow;
  color: black !important;
}

.dna-hl {
  background: rgba(yellow, 0.7);
  color: black !important;
  display: inline-block;
  padding: 0 2px 0 3px;
  border-radius: 2px;
  &.active {
    background-color: lighten(#fc5644, 5%) !important;
    color: white !important;
  }
  &.flip {
    @include bg100;
  }
  &.pink {
    background: #ff6ec7;
    color: white !important;
  }
  &.red {
    background: red;
    color: white !important;
  }
  &.light-orange {
    background: lighten($orange, 30%);
  }
  &.light-mint {
    background: lighten($mint, 20%);
  }
  &.orange {
    background: $orange;
    color: white !important;
  }
  &.mint {
    background: $mint;
    color: $bgDark !important;
  }
  &.accent {
    background: $accent;
    color: white !important;
  }
}

.hl-hover:hover {
  background: yellow;
  color: black !important;

  &.red {
    background: $accent;
    color: white !important;
  }
}

.dna-close-x {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 4px 9px 1px 8px !important;
  z-index: 5;
}

.dna-right {
  float: right;
}
.dna-left {
  float: left;
}

.dna-ai-animal {
  width: 300px;

  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  overflow: hidden;
  position: relative;

  img {
    display: block;
    position: absolute;
    max-width: 300px;
    max-height: 300px;
  }
}

.dna-reminder-tile {
  padding: 10px 4px 0 4px;
  background: url(/img/noisy-light.png) rgba($light, 0.8);
  border-radius: 3px !important;

  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  position: relative;

  &:hover {
    z-index: 999;
    box-shadow: 0 1px 3px rgba($dark, 0.5);
  }

  div {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .shave-me-description {
    color: $dark;
  }
  label {
    margin: 5px 0 0 7px;
    font-weight: normal;
    opacity: 0.4;
    font-size: 14px;
    color: $dark;
  }
  .rem-wrap {
    padding-left: 40px;
    transform: translateY(12px);
  }
  .dna-avatar {
    float: left;
    position: relative;
    z-index: 8;
    margin: 12px 10px 0 5px;
  }

  &.wi-is-done {
    background: $blueAccent;
  }
}

@keyframes ai-v-bg {
  0% {
    background: $dark;
  }
  50% {
    background: $light;
  }
  100% {
    background: $dark;
  }
}

@import "./html.scss";

@import "../comp/ActionTile.scss";
@import "../comp/Avatar.scss";

@import "../comp/Card.scss";
@import "../comp/ChangePicture.scss";
@import "../comp/CheckBoxes.scss";
@import "../module/CrudMission.scss";
@import "../comp/charts/DonutChart.scss";
@import "../comp/charts/HillChart.scss";
@import "../comp/DropDown.scss";
@import "../comp/FullScreen.scss";
@import "../comp/HomeActions.scss";
@import "../comp/Icons.scss";
@import "../comp/MissionTile.scss";
@import "../comp/People.scss";
@import "../comp/ProgressBar.scss";
@import "../comp/MissionProgress.scss";
@import "../comp/Slider.scss";
@import "../comp/Tabs.scss";
@import "../comp/Tags.scss";
@import "../comp/Tip.scss";
@import "../comp/Toggle.scss";
@import "../comp/WelcomeToTheMission.scss";
@import "simplebar/dist/simplebar.min.css";

.Toastify {
  z-index: 9999999999999;
  position: fixed;

  .Toastify__toast-container {
    max-width: 360px !important;
    width: auto;
    min-width: 255px;
    overflow: hidden;
  }

  div {
    font-size: 14px;
    font-weight: normal;
    color: white;
    letter-spacing: 0.2px;
    line-height: 18px;
  }
  .Toastify__toast {
    overflow: auto;
    overflow: hidden;
    div {
      font-family: "MessinaSans" !important;
      font-size: 15px !important;
      letter-spacing: 0px;
    }
  }
  .Toastify__progress-bar {
    height: 1px;
    bottom: 3px;
    left: 3px;
    border-radius: 2px;
    opacity: 0.8 !important;
    left: 5px;
    right: 5px;
    overflow: hidden;
  }

  .Toastify__toast--default {
    background-image: url(/img/mAX.png), radial-gradient(circle at right, #fafafa, #f0f0f0);
    background-size: 55px, auto;
    background-repeat: no-repeat;

    background-position: 14px 17px, center;
    .Toastify__toast-icon {
      display: none;
    }

    span.cursor svg {
      fill: #4287f5 !important;
    }
    .Toastify__toast-body {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 75px;
      padding-right: 20px;
      padding-top: 8px;
      min-height: 70px;
      div:last-child {
        justify-content: flex-start;
        color: black !important;
        text-align: left;
      }
    }

    > div:last-child {
      min-height: 2px !important;
      background-color: gray;
      margin-bottom: 3px;
    }
  }
  .Toastify__toast--success {
    background-image: linear-gradient(to right, #edffbf, #bdffce);

    .Toastify__toast-icon {
      display: none;
    }

    span.cursor svg {
      fill: green !important;
    }
    .Toastify__toast-body {
      background-image: url(/img/excitedMax.png);
      background-position: 3px 9px, center;
      background-size: 62px;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 84px;
      padding-right: 20px;
      padding-top: 8px;
      padding-bottom: 20px;
      min-height: 70px;
      div:last-child {
        justify-content: flex-start;
        color: black !important;
        text-align: left;
      }
    }
    > div:last-child {
      min-height: 2px !important;
      background-color: green;
      margin-bottom: 3px;
    }
  }
  .Toastify__toast--error {
    background-image: url(/img/sadMax.png);
    background-size: 70px, auto;
    background-repeat: no-repeat;
    padding-bottom: 15px;

    background-position: 14px 10px, center;
    .Toastify__toast-icon {
      display: none;
    }

    span.cursor svg {
      fill: red !important;
    }
    .Toastify__toast-body {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 90px;
      padding-right: 20px;
      padding-top: 8px;
      min-height: 70px;
      div:last-child {
        justify-content: flex-start;
        color: black !important;
        text-align: left;
      }
    }

    > div:last-child {
      min-height: 2px !important;
      background-color: red;
      margin-bottom: 4px;
    }
  }
  .Toastify__toast--warning {
    background-color: white !important;
    color: black !important;

    box-shadow: 0 1px 2px rgba(black, 0.2), 0 2px 6px rgba(black, 0.7) !important;

    background: linear-gradient(to right, #ffffff, #ece9e6);

    span.cursor {
      svg {
        fill: gray !important;
      }
    }

    .Toastify__toast-body {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 72px;
      min-height: 75px;

      background-image: url(/img/mAX.png);
      background-size: 60px;
      background-repeat: no-repeat;

      background-position: 0px 11px;
      padding-top: 10px;
      padding-bottom: 15px;

      div:last-child {
        justify-content: flex-start;
        color: black !important;
        text-align: left;
      }
      .Toastify__toast-icon {
        margin-top: -1px;

        transform: translate(40px, -20px);
        display: none;
        padding: 5px;
        border-radius: 50%;
        background-color: rgba(gray, 0.2);

        svg {
          opacity: 1;
          fill: orange;
          max-width: 14px;
        }
      }
    }
    > div:last-child {
      min-height: 2px !important;
      background-color: orange;
      margin-bottom: 3px;
    }
  }

  .Toastify__toast-body {
    padding: 15px;
  }

  span.cursor {
    display: block;
    position: absolute;
    z-index: 99;
    right: 7px;
    opacity: 1;

    svg {
      fill: white;
    }
  }

  .Toastify__progress-bar--default,
  .Toastify__progress-bar--error {
    background: white;
  }
}

@media screen and (max-width: 600px) {
  .dna-me-avatar {
    right: 20px;
  }

  .dna-nav {
    right: 55px;
  }
}

.dna-rays {
  background: url(/img/website/rays.png) center center no-repeat;
  background-size: contain;
  position: absolute;

  max-width: 1000px;
  max-height: 1000px;

  /* microsoft ie */
  //animation-name: spin;
  animation-duration: 36000ms; /* 40 seconds */
  animation-iteration-count: 2;
  animation-timing-function: linear;

  opacity: 0.2;
  width: 100vw;
  height: 100vw;
}

@media only screen and (max-width: 1600px) and (min-width: 601px) {
  .dna-ai-talk {
    position: fixed;

    max-width: 260px;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale3d(0.5, 0.5, 0.5);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: silver;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dna-tip-notification {
  transition: background-color 200ms ease, border 200ms ease;
  overflow: visible !important;
  margin-left: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: translate(-1px, -1px);
  }

  &:hover {
    background-color: $lime;
    svg {
      .fill-me {
        stroke: $dark;
      }
      &.dna-icon-path {
        path {
          fill: $dark !important;
        }
      }
    }
  }

  .dna-sonar {
    position: absolute;
    right: 2px;
    top: 7px;
    pointer-events: none;
  }

  svg {
    transition: fill 200ms ease;
  }

  @keyframes countPop {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  &-count {
    font-size: 11px;
    position: absolute;
    top: 4px;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #e64342;
    transform: scale(0);
    box-shadow: 0 0 0 4px rgba($accent, 0.2);
  }

  &-unread {
    svg {
      fill: #ffffff $accent;
    }

    //background-color: $blueAccent !important;
  }

  &-has-unread {
    .dna-tip-notification-count {
      animation: 100ms linear 180ms forwards countPop;
    }
  }
}

.dna-border {
  border-width: 1px;
  border-style: solid;
  border-color: rgba($dark, 0.1);
}

.dna-dark {
  .dna-border {
    border-color: rgba($light, 0.3);
  }
}

.dna-error {
  color: $error;
}

.imported-key-link {
  color: #94d5fb;
  font-weight: normal;
  margin-top: 5px;
  background-color: #2e5064;
  padding: 0 15px;
  border-radius: 12px;
  text-shadow: none;
  font-size: 14px;
}

.mo-js-shape {
  z-index: 99999999999;
  pointer-events: none;
}

.dna-cu-small {
  letter-spacing: -15px;
}

.dna-collapser {
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
  label {
    display: block;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0;
  }
  &.open {
    label {
      box-shadow: 0 3px 6px rgba(black, 0.2);
    }
  }
  .dna-under-con {
    position: absolute;
    right: 0;
    transform: rotate(90deg);
    @include bg02;

    &:hover {
      background: rgba($lime, 0.4);
    }
    svg {
      transform: translateX(-2px);
    }
  }
}

.dna-under-con.gm-exit {
  position: fixed;
  right: 12px;
  top: 13px;
  min-width: 40px;
  min-height: 40px;
  @include bg05;

  &:hover {
    background-color: $lime;
  }
}

.dna-chat {
}

.dna-dark {
  .dna-pj-controls {
    li:first-child {
      > span {
        background-color: rgba($mint, 0.9);
        color: black;
      }
    }
  }
  .stimple-more-menu {
    background: $bgDark;
    div {
      color: white;
    }
  }
}

.dna-project-bar {
  .pj-save-template {
    position: absolute;
    right: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  .dna-project-bar {
    .project-search-trigger {
      display: none;
    }
  }
}

.dna-project-bar {
  padding-left: 250px;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 11;
  left: 0;
  height: 60px;
  background: white;
  box-shadow: 0 0 3px rgba(black, 0.2);

  .project-search-trigger {
    transition: all 0.2s ease-in;
    position: absolute;
    right: 60px;
    top: 14px;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.dna-dark {
  .dna-project-bar {
    background: $bgDark;
    li {
      color: rgba(white, 0.9);
    }
  }
  .dna-pj-controls {
    li.pj-setup {
      border: 2px dashed #999 !important;
    }
  }
}

.dna-pj-controls {
  user-select: none;

  li {
    float: left;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    @include bg10;
    transition: all 0.3s;

    &.px-wb {
      svg {
        transform: translate(2px, 3px);
        color: $mint;
      }
    }

    &.pj-setup.active {
      overflow: visible;

      border: 0 !important;
      svg {
        transform: translate(-1px, 1px) scale(1.3);
      }
      &:after {
        content: "";
        background: red;
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translate(12px, -10px);
      }
    }

    &:hover,
    &.active {
      //background-color: $lime;
      //color: black !important;

      @include bg100;

      .dna-icon-path {
        path {
          fill: black;
        }
      }
      .dna-icon-stroke {
        path {
          stroke: black;
        }
      }
    }

    &.board-cls {
      cursor: default;

      padding: 0;
      padding-right: 0px;
      background: none;

      &:hover {
        background: none;
      }

      svg {
        opacity: 0;
      }

      svg:first-child {
        opacity: 0.3;
        margin-left: 7px;
      }
      svg:nth-child(2) {
        opacity: 0.5;
        margin-left: -4px;
      }
      svg:nth-child(3) {
        opacity: 0.7;
        margin-left: -4px;
        margin-right: 6px;
      }
    }

    &.pj-icon {
      padding: 0px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      .dna-svg-icon:not(.dna-icon-dots) {
        transform: translateY(3px) scale(0.9);
      }

      .MuiBadge-badge {
        font-family: inherit;
        //font-weight: 100;
        font-size: 12px;
        color: inherit;
      }

      &.pj-setup {
        border: 2px dashed #333;

        svg {
          font-size: 14px;
        }
      }
      &.pj-org-people {
        font-size: 16px;
        margin-right: 12px;
        svg {
          transform: translateY(1px);
        }
      }

      &.pj-docs {
        svg.dna-svg-icon {
          transform: translate(0px, 3px) scale(1);
        }
      }
    }

    &.pj-menu {
      padding: 10px;
      &:hover {
        background-color: $orange;
      }
      margin-right: 20px;
      &:hover {
        .stimple-more-menu {
          transform: translate(49%, 10px);
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &.pj-p-settings {
    }
  }
}
.x-dna-filters-menu {
  position: fixed;
  transition: all 0.3s ease;

  margin-top: -10px;
  z-index: 99999;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transform: none;
  box-shadow: 0 2px 6px rgba(black, 0.3);
  pointer-events: none;
  opacity: 0;
  padding: 8px;
  border-radius: 8px;
  @include bgSolid;
  > div {
    margin-bottom: 3px;
    margin-top: 3px;
  }
  &.open {
    opacity: 1;
    pointer-events: all;
  }

  .dna-button {
    padding: 0 30px !important;
  }
}
.x-no-padding {
  padding: 0 !important;
}
.stimple-more-menu {
  position: absolute;
  width: 250px;
  top: 0px;
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(black, 0.3);
  transform: translate(55%, 10px);
  z-index: 9999999;
  opacity: 0;
  pointer-events: none;
  margin: 0 20px 20px 20px;
  transition: all 0.2s;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
  > div {
    padding: 8px;
    border-radius: 6px;
    color: $dark;
    cursor: pointer;

    svg {
      transform: translateY(2px);
      margin-right: 2px;
    }
    &:hover {
      @include bg10;
    }
  }
}

//MUI overrides

.MuiAccordion-root.MuiPaper-elevation1 {
  box-shadow: none;

  &:before {
    background-color: transparent !important;
    border-bottom: 1px dashed rgba(silver, 0.3) !important;
  }
}

.MuiPopover-paper.MuiPaper-rounded {
  box-shadow: 0 2px 6px rgba(#666, 0.3) !important;
  border-radius: 6px;
  padding-top: 0px;
  padding-bottom: 5px;
  p {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 400px;
    outline: none;
  }
}

.MuiTypography-root,
.MuiTypography-root,
.MuiTypography-body1 {
  font-weight: 100 !important;
  font-family: "MessinaSans", sans-serif !important;
}

.MuiBadge-colorSecondary {
  background-color: $accent !important;
}
.MuiBadge-colorPrimary {
  background-color: #ff9800 !important;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background: none;

  &:hover {
    @include bg05;
  }
}

li.MuiListItem-button {
  padding: 10px 20px;
  font-weight: 100;
  font-family: "MessinaSans", sans-serif;
}

.dna-emoji-icon-button {
  .MuiIconButton-label {
    font-size: 16px;
    line-height: 16px;
    display: block;
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
}

.MuiButtonBase-root.MuiIconButton-root {
  @include bg05;
}

.MuiIconButton-root.dna-lime-icon-button {
  background-color: rgba($lime, 0.4);

  &:hover {
    background-color: rgba($lime, 1);
  }
}
.MuiBadge-badge {
  @include bg05;
}
.DateRangePicker_picker {
  background: none !important;
  z-index: 999999 !important;
  * {
    user-select: none;
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
}

.vsc-icon {
  font-size: 16px;
}

.dna-dark {
  .vsc-icon {
    path {
      fill: white;
    }
  }

  .DateInput_fang.DateInput_fang_1 {
    path {
      fill: $bgDark;
    }
  }

  .CalendarMonthGrid {
    background-color: $bgDark;

    strong,
    .DayPicker_weekHeader_li {
      color: #999;
    }
  }

  .DateInput {
    background: none;
  }
  .CalendarMonth {
    background-color: $bgDark;
  }
}

.MuiBackdrop-root {
  z-index: 999999999 !important;

  .MuiCircularProgress-svg circle {
    stroke: white;
  }
}

.MuiBadge-root .MuiBadge-badge {
  font-size: 11px;
  height: 17px;
  min-width: 17px;
}

.MuiMenuItem-root {
  overflow: visible !important;
}

.MuiDrawer-root {
  .MuiBackdrop-root {
    z-index: 2 !important;
  }
}
.simplebar-scrollbar:before {
  background: rgba(black, 0.4) !important;
}

.dna-dark {
  .simplebar-scrollbar:before {
    background: rgba(white, 0.4) !important;
  }
}
.DateInput_screenReaderMessage {
  display: none !important;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #f09, 0 0 82px #f09, 0 0 92px #f09, 0 0 102px #f09,
    0 0 151px #f09;
}

.ai-speach-bubble {
  $color-shadow: #222222;
  $color-front: #ffffff;

  //animation: ai-speach-float 5s ease-in-out infinite;
  mix-blend-mode: multiply;
  text-align: left;

  font-weight: bold;
  letter-spacing: 1px;
  font-size: 12px;
  color: #222222;
  border: 1px solid #222222;
  background-color: #222222;
  background: white;
  padding: 15px;
  border-radius: 11px;
  position: relative;
  box-shadow: 5px 5px $color-shadow;

  text-shadow: 1px 1px white;

  &:after {
    transform: translatey(-1px);
    //  animation: ai-speach-floater 5s ease-in-out infinite;
    content: "";
    font-weight: bold;
    border: 1px solid #444444;
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: $color-front;
    position: absolute;
    display: block;
    bottom: -20px;
    left: 0;
    box-shadow: 5px 5px $color-shadow;
    z-index: -1;
  }
  &:before {
    transform: translatey(-5px);
    //animation: ai-speach-floater2 5s ease-in-out infinite;
    border: 1px solid #222222;
    content: "";
    font-weight: bold;
    text-shadow: 5px 5px $color-shadow;
    text-align: left;
    font-size: 25px;
    width: 25px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: $color-front;
    position: absolute;
    display: block;
    bottom: -40px;
    left: 0;
    box-shadow: 5px 5px $color-shadow;
    z-index: 1;
  }
}

@keyframes ai-speach-float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes ai-speach-floater {
  0% {
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-10px);
  }
  60% {
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes ai-speach-floater2 {
  0% {
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-20px);
  }
  60% {
  }
  100% {
    transform: translatey(0px);
  }
}

.dna-sticker-label {
  border-radius: 2px;
  font-size: 14px;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  background: black;
  padding: 1px 6px 1px 4px;
  color: white;
  display: inline-block;
  box-shadow: 1px 1px 3px rgba(black, 0.3);
}

.simple-bar-wrapper,
.simplebar-content-wrapper {
  outline: none !important;
  border: 0 !important;
}

.MuiBadge-dot {
  min-width: 8px !important;
  max-height: 8px !important;
  max-width: 8px !important;
  min-height: 8px !important;
  width: 8px !important;
}

.dna-dark {
  .x-dna-filters {
    .dna-button {
      .filter-button {
        background-color: rgba(white, 0.05);
      }
    }
  }
}

.x-dna-filters {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 1;

  &.shadow {
    box-shadow: 0 1px 2px rgba(black, 0.2);
  }
  .dna-button {
    max-height: 38px !important;
    min-height: 38px !important;

    .filter-button {
      box-shadow: none;
      padding-left: 12px;
      padding-right: 12px;
      background-color: rgba($dark, 0.05);
      font-size: 12px;
    }
  }
  input {
    border-radius: 3px !important;
    border-bottom: 0 !important;
    padding-left: 3px;
  }

  .x-f-green {
    input {
      background-color: $mint !important;
      color: $dark !important;
      &::placeholder {
        color: $dark;
        opacity: 0.5;
      }
    }
  }

  .x-f-mint {
    input {
      background-color: rgba($mint, 0.2) !important;
    }
  }
  .x-f-orange {
    input {
      background-color: #ffb300 !important;
      color: white !important;
      &::placeholder {
        color: white;
        opacity: 0.5;
      }
    }
  }
  .x-f-100 {
    input {
      @include bg100;
      color: white !important;
      &::placeholder {
        color: white;
        opacity: 0.5;
      }
    }
  }
  .x-f-red {
    input {
      background-color: $accent !important;
      color: white !important;
      &::placeholder {
        color: white;
        opacity: 0.5;
      }
    }
  }
}

.x-div-orange {
  background-color: #ffb300 !important;
  color: white !important;
  border-radius: 3px;
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
}

.x-field-orange[value],
.x-field-orange input,
.x-field-orange i {
  background-color: #ffb300 !important;
  color: white !important;
  border-radius: 3px;
  &::placeholder {
    color: white;
    opacity: 0.5;
  }
  &:valid {
    background: #ffb300 !important;
  }
  &:focus {
    background: #ffb300 !important;
  }
}

.x-field-mint input {
  background-color: $mint !important;
  color: $dark !important;
  border-radius: 3px;
  &::placeholder {
    color: $dark !important;
    opacity: 0.7;
  }
}

.x-field-gold,
.x-field-gold input {
  background-color: gold !important;
  color: $dark !important;
  border-radius: 3px;
  &::placeholder {
    color: $dark !important;
    opacity: 0.7;
  }
}

.x-field-mint-light,
.x-field-mint-light input {
  background-color: lighten($mint, 10%) !important;
  color: $dark !important;
  border-radius: 3px;
  &::placeholder {
    color: $dark !important;
    opacity: 0.7;
  }
}
.x-field-orange.react-date-picker {
  .react-date-picker__wrapper {
    background-color: #ffb300;
    border: 0;
    color: white;
    &::placeholder {
      color: white;
      opacity: 0.5;
    }
    * {
      &::placeholder {
        color: white;
        opacity: 0.5;
      }
      color: white !important;
    }
  }
}
.x-field-red.react-date-picker {
  .react-date-picker__wrapper {
    background-color: $accent;
    border: 0;
    color: white;
    &::placeholder {
      color: white;
      opacity: 0.5;
    }
    * {
      &::placeholder {
        color: white;
        opacity: 0.5;
      }
      color: white !important;
    }
  }
}

.dna-dark {
  .x-dna-filters {
    .x-f-orange {
      input {
        background-color: darken(#ffb300, 10%) !important;
      }
    }
  }
  .x-field-orange[value],
  .x-field-orange input {
    input {
      background-color: darken(#ffb300, 10%) !important;
    }
  }
  .x-field-orange.react-date-picker {
    .react-date-picker__wrapper {
      background-color: darken(#ffb300, 10%);
    }
  }
}

.x-field-red[value],
.x-field-red input {
  background-color: $accent !important;
  color: white !important;
  border-radius: 3px;
  &::placeholder {
    color: white;
    opacity: 0.5;
  }
}

.x-field-mint[value],
.x-field-mint input {
  background-color: $mint !important;
  color: black !important;
  border-radius: 3px;
  &::placeholder {
    color: black;
    opacity: 0.5;
  }
}
.x-field-light-mint[value],
.x-field-light-mint input {
  background-color: rgba($mint, 0.5) !important;
}
.x-light-mint {
  background-color: rgba($mint, 0.5) !important;
}
.x-light-red {
  background-color: rgba(red, 0.05) !important;
}
.x-light-orange {
  background-color: rgba(orange, 0.3) !important;
}
.x-light-light-orange {
  background-color: rgba(orange, 0.1) !important;
}

.dna-dark {
  .x-light-mint {
    background-color: rgba($mint, 0.7) !important;
  }
  .x-light-red {
    background-color: rgba($accent, 0.6) !important;
  }
  .x-light-orange {
    background-color: rgba(orange, 0.8) !important;
  }
}

.x-pulse-orange {
  animation: pulse-orange 2s infinite;
}
.x-pulse-mint {
  animation: pulse-mint 1.5s infinite;
}
.x-pulse-red {
  animation: pulse-red 1.5s infinite;
}

@keyframes pulse-orange {
  0% {
    box-shadow: 0 0 0 0px rgba(orange, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(orange, 0);
  }
}
@keyframes pulse-mint {
  0% {
    box-shadow: 0 0 0 0px rgba($mint, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba($mint, 0);
  }
}
@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0px rgba($accent, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba($accent, 0);
  }
}
.x-cm {
  font-size: 11px;
  min-width: 16px;
  height: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.quill {
  color: inherit;

  .ql-tooltip-editor {
    input {
      &::placeholder {
        color: rgba(gray, 0.5);
      }
    }
  }
  .ql-editor {
    padding-left: 0;
    font-family: "MessinaSans", sans-serif;
    font-size: 14px;
    overflow-y: visible;
    color: inherit;
    word-wrap: break-word;

    a {
      cursor: pointer;
      max-width: 100%;
      display: inline-block !important;
      word-wrap: break-word !important;
      word-break: break-all !important; /* Break long words */
      white-space: pre-wrap !important; /* Preserve white spaces and allow wrapping */
      overflow-wrap: break-word !important; /* Ensure long words break */
      max-width: 700px;
    }

    .ql-container {
      font-family: inherit;
    }

    &:before {
      left: 0px !important;
      color: inherit !important;
      opacity: 0.5;
      font-style: normal !important;
    }

    p {
      line-height: 21px;
    }
  }
}

.x-cm-1 {
  background-color: #e4ffb5;
  color: #596348;
}
.x-cm-2 {
  background-color: #faaf5f;
  color: white;
}
.x-cm-3 {
  background-color: #a60f46;
  color: white;
}

.x-stimple-time {
  margin-top: 60px;
}

.x-board-filters {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  ul {
    display: flex;
    li {
      display: flex;
      align-items: center;
      margin-right: 4px;
      padding: 4px 10px;
      @include bg05;
      border-radius: 3px;
      font-size: 14px;
      cursor: pointer;

      &:hover,
      &.active {
        @include bg100;
      }
    }

    &.x-board-view-mode {
      margin-right: 40px;
      li {
        text-transform: uppercase;
        font-size: 12px;
        @include bg10;
        &:hover,
        &.active {
          @include bg100;
        }
      }
    }
  }
}

.x-template-msg {
  padding: 10px;
  border-radius: 6px;
  width: 380px;
  font-family: "MessinaSans";
  box-shadow: 8px 8px 0 rgba(gray, 0.3);
  margin-bottom: 50px;
  margin-top: -80px;
  background-color: $bgDark;

  span {
    font-weight: 800;
    font-size: 30px;
    display: block;
  }
}

.dna-dark .x-bubbly {
  box-shadow: 0px 3px 15px rgba(white, 0.5);
}

.x-bubbly {
  background-color: hsla(212, 43%, 14%, 1);
  background-image: radial-gradient(circle at 1% 5%, hsla(212, 43%, 26%, 1) 0%, transparent 60%),
    radial-gradient(circle at 131% 60%, hsla(212, 43%, 26%, 1) 0%, transparent 60%),
    radial-gradient(circle at 1% 105%, hsla(212, 43%, 26%, 1) 0%, transparent 30%);
  background-blend-mode: normal, normal, normal;
  border-radius: 5px;
  z-index: 10;
  padding: 10px;
  text-align: left;
  box-shadow: 3px 3px 6px rgba(black, 0.5), 1px 1px 3px 0px rgba(white, 0.4);
  color: white;
  z-index: 9999 !important;
  align-self: flex-start;
  p {
    margin-bottom: 10px !important;
  }
  .dna-button {
    width: 100%;
  }
  &.top {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-bottom-color: #223854;
      border-top: 0px;

      margin-left: -70px;
      margin-top: -12px;
    }
  }
  &.left {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 20%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-right-color: #203851;
      border-left: 0;

      margin-top: 0px;
      margin-left: -12px;
    }
  }
  &.left-top {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-right-color: #203851;
      border-left: 0;

      margin-top: 0px;
      margin-left: -12px;
    }
  }
  &.right {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-left-color: #1e334b;
      border-right: 0;

      margin-top: -47px;
      margin-right: -11px;
    }
  }
  &.right-top {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 30px;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-left-color: #1e334b;
      border-right: 0;
      margin-right: -11px;
    }
  }
  &.down {
    &:after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 50%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-top-color: darken(#203851, 10%);
      border-bottom: 0px;

      margin-left: 0px;
    }
  }

  &.right-arrow {
    &:after {
      margin-left: 40px;
    }
  }
}
.fake-button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 4px;
  @include bg05;
  &.orange {
    background-color: rgba(orange, 0.1);
  }
}

.x-moon-shot-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  pointer-events: none;
  z-index: 0;
  position: relative;
  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, 570px);
  width: 1100px;
  height: 700px;

  .x-moon-face {
    border-radius: 50%;
    position: absolute;
    background-color: $dark;
    z-index: 1;
    opacity: 1;
    width: inherit;
    height: inherit;
  }

  img {
    position: absolute;
    z-index: 0;
    transition: opacity 0.45s cubic-bezier(0.6, 0.6, 0, 1);
    animation: orbit 15s forwards infinite;
    animation-timing-function: linear;

    width: 1110px;
    height: 1220px;
  }
}

.x-app-org-settings-trigger {
  position: fixed;
  z-index: 9;

  border-radius: 10px;
  transition: all 0.3s;
  left: 215px;
  top: 22px;
  display: block !important;
  cursor: pointer;
  svg {
    opacity: 0.6;
    color: silver;
    font-size: 17px;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
      transform: scale(1.6) rotate(90deg);
    }
  }
}
.dna-dark {
  .x-app-org-settings-trigger {
    svg {
      color: #999;
      transition: all 0.3s;
    }
  }
}
.dna-org-setter {
  position: fixed;
  z-index: 9;
  padding: 10px;
  border-radius: 10px;
  background-color: $bgDark;
  width: 300px;
  left: 200px;

  overflow: auto;
  top: 50px;
  padding-bottom: 40px;
  box-shadow: 4px 4px 8px rgba(black, 0.8);
  max-height: 1px;

  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: all 0.4s;
  &.open {
    opacity: 1;
    pointer-events: all;
    max-height: 80vh;
    z-index: 99;
  }
  ul {
    li {
      padding: 4px;
      display: flex;
      align-items: center;
      .dna-checkbox {
        margin-bottom: 0;
      }
      .dna-round-avatar {
        margin-right: 10px;
      }
    }
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.x-notice-cell {
  border-radius: 3px;

  height: inherit;

  display: flex;
  align-items: center;
  @include bg10;

  &.right {
    text-align: right;
    justify-content: flex-end;
  }

  &.green {
    background-color: lighten($mint, 10%);
    color: $dark !important;
  }
  &.gold {
    background-color: lighten(gold, 20%);
    color: $dark !important;
  }
}

.x-grid-pill {
  border-radius: 20px;
  max-height: 30px;
  margin: auto auto;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  @include bg05;
  &.gold {
    background-color: lighten(gold, 20%);
    color: $dark !important;
  }
  &.orange {
    background-color: lighten(orange, 25%);
    color: $dark !important;
  }
  &.red {
    background-color: $accent;
    color: white !important;
  }
  &.light-accent {
    background-color: lighten($accent, 30%);
    color: $dark !important;
  }
  &.mint {
    background-color: lighten($mint, 10%);
    color: $dark !important;
  }
  &.green {
    background: #b3ff75;
    color: $dark !important;
  }
}

.x-dz-admin {
  width: 100% !important;
  height: 50px !important;
  border: 0 !important;
  @include bg10;
  white-space: nowrap;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    @include bg20;
  }
  input {
    position: absolute;
  }
}

.bg-solid {
  input {
    @include bgSolid;
  }
  @include bgSolid;
}
