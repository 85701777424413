.dna-check-boxes {
  .dna-checkbox {
    margin-bottom: 10px;
  }
}

.dna-light .dna-checkbox,
.dna-checkbox {
  min-height: 24px;
  vertical-align: middle;
  margin-bottom: 0px;

  display: flex;

  &.disabled {
    opacity: 0.4;
  }

  .cb-content {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    margin-top: -1px;
  }

  span.dna-emoji {
    font-size: 16px;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    line-height: 15px;
  }

  > svg {
    vertical-align: middle;
    margin-right: 4px;
    cursor: pointer;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    path {
      transition: all 0.3s;
      stroke-width: 0;
    }
    path:first-child {
      stroke: $dark;
      opacity: 1;
      stroke-width: 1px;
      fill: none;
    }
    path:last-child {
      opacity: 0;
      stroke-width: 1px;
      stroke: $dark;
      fill: $dark;
    }
  }
  &.active {
    svg {
      path:first-child {
        opacity: 0;
      }
      path:last-child {
        opacity: 1;
      }
    }
  }
}

.dna-list-left {
  .dna-checkbox {
    float: left;
    margin-right: 10px;
  }
}

.dna-dark {
  .dna-checkbox {
    svg {
      cursor: pointer;
      path:first-child {
        stroke: $light;
      }
      path:last-child {
        stroke-width: 1px;
        stroke: $light;
        fill: $light;
      }
    }
  }
}
.dna-light {
  .dna-checkbox {
    svg {
      path:first-child {
        stroke: $dark;
      }
      path:last-child {
        stroke: $dark;
        fill: $dark;
      }
    }
  }
}

.dna-check-boxes.dna-horizontal-checkboxes {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .dna-checkbox {
    margin-right: 10px;
  }
}
