.dna-person-adder {
  width: 400px;
  z-index: 9;
  position: relative;
  label {
    margin-top: 0;
  }
}

.dna-people-list-item {
  align-items: flex-start;
  border-bottom: 1px dotted rgba(gray, 0.3);
  .dna-round-avatar {
    margin-right: 10px;
    margin-top: 5px;
  }
}
