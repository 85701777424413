.dna-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 0;
  @include bgSolid;
}
.x-report-screen {
  background-color: white !important;
  color: black;
  position: fixed;
  z-index: 30 !important;

  .dna-fullscreen-close {
    path {
      fill: black !important;
    }
  }
}

.x-fs-close {
  position: fixed;
  top: 14px;
  right: 15px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  svg {
    transform: translateY(0px);
    font-size: 18px;
  }
  &:hover {
    background-color: pink;
    svg {
      color: black !important;
    }
  }
  .t-tip {
    display: block !important;
    transform: translateY(3px);
  }
}

.dna-dark {
  .dna-fullscreen-close {
  }
}

@media print {
  .dna-fullscreen-close {
    display: none;
  }
}
