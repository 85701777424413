@import '../scss/vars';

.dna-dark {
  .dna-dots-menu {
    background-color: $light;
    color: $dark;
  }
}

.dna-dots-menu {
  position: absolute;
  min-width: 120px;
  padding: 8px 0;
  color: $light;
  background-color: $dark;
  box-shadow: 0 0 10px rgba($dark, 0.3);
  border-radius: 6px;
  overflow: hidden;
  z-index: 2000;
  @include bg100;

  &-list {
    &-item {
      display: block;
      text-align: left;
      cursor: pointer;
      transition: background-color 0.2s, color 0.2s;

      &:hover,
      &.active {
        background: $blueAccent;
        color: $dark;
      }

      & > * {
        padding: 8px 16px;
      }

    }
  }

  &-separator {
    height: 1px;
    padding: 0;
    margin: 8px 0;
    @include bg10;
  }

  &.dna-dots-menu-theme {
    &--light {
      background-color: $light;
      color: $dark;

      .dna-dots-menu-list {
        &-item {

          &:hover,
          &.active {
            background: none;
            color: inherit;
          }
        }
      }

    }
  }


}