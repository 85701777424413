.dna-flex {
  display: flex;

  .dna-flex-1 {
    flex: 1;
  }

  &-grow-1 {
    flex-grow: 1;
  }

  &-grow-0 {
    flex-grow: 0;
  }

  &-shrink-1 {
    flex-shrink: 1;
  }

  &-shrink-0 {
    flex-shrink: 0;
  }

  &-direction-row {
    flex-direction: row;
  }

  &-direction-column {
    flex-direction: column;
  }
}

.dna-align-items {
  &-center {
    align-items: center;
  }
  &-start {
    align-items: start;
  }
  &-end {
    align-items: end;
  }
  &-stretch {
    align-items: stretch;
  }
}

.dna-justify-content {
  &-center {
    justify-content: center;
  }
  &-start {
    justify-content: start;
  }
  &-end {
    justify-content: end;
  }
  &-space-between {
    justify-content: space-between;
  }
}