.dna-change-pic {
  max-width: 460px;
  width: 100%;
  padding: 0px;
  height: 250px;
  transition: all 0.2s ease-in;
  position: absolute;
  background-image: url(/img/noisy.png);

  .dna-scroll-body {
    padding: 30px 0 0 0;
  }

  .the-buttons {
    opacity: 0;
    transition: all 0.2s ease-out;
    position: absolute;
    bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &.expanded {
    height: 560px;

    .the-buttons {
      opacity: 1;
    }
  }
  &.images {
    height: 520px;

    .changer-select-images {
      margin-top: 25px;
      padding: 20px 0;
      .dna-avatar {
        margin: 10px 20px;
      }
    }

    .the-cropper {
      opacity: 0;
      pointer-events: none;
      z-index: -1;
    }
  }
  &.cropping {
    .the-cropper {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    }
  }

  .showcase {
    height: 130px;
    z-index: 10;

    position: relative;
    > div {
      position: absolute;
    }
    .dna-tri {
      position: absolute;
      transform: rotate(45deg);
      border-radius: 5px;

      top: 15px;
      width: 80px;
      height: 80px;
      overflow: hidden;

      .div-webcam {
        position: absolute;
        transform: translate(10px, -43px) rotate(-45deg) scale(-1, 1);
      }
    }
    .a-huge {
      top: 15px;
    }
    .a-large {
      right: 3px;
      top: 60px;
    }
    .a-med {
      right: 43px;
      top: 22px;
    }
    .a-small {
      right: 21px;
      top: -6px;
    }
  }

  .cp-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    position: relative;
    z-index: 10;

    .dna-round-avatar {
      max-width: 30px;
      max-height: 30px;
    }

    .cp-button {
      height: 50px;
      width: 80px;
      @include bg05;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin: 0 3px;

      &:hover {
        background-color: rgba($mint, 0.6);
      }

      font-size: 26px;
    }
  }

  .the-cropper {
    clear: both;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.4s ease-in;
  }

  .the-bg-tools {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
}

:root {
  --blue: rgba(0, 0, 0, 0.8);

  .dna-dark {
    --blue: #fff;
  }
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none;

  & img {
    /* Avoid margin top issue (Occur only when margin-top <= -height) */
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}

.cropper-modal {
  //background-color: #000;
  //opacity: .5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline-color: rgba($light, 0.8);
  outline: 1px solid var(--blue);
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;

  &.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
  }

  &.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
  }
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;

  &:before,
  &:after {
    background-color: #eee;
    content: " ";
    display: block;
    position: absolute;
  }

  &:before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }

  &:after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: var(--blue);

  &.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }

  &.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }

  &.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }

  &.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }
}

.cropper-point {
  background-color: var(--blue);
  height: 5px;
  opacity: 0.75;
  width: 5px;

  &.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }

  &.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }

  &.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }

  &.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }

  &.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }

  &.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }

  &.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }

  &.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;

    @media (min-width: 768px) {
      height: 15px;
      width: 15px;
    }

    @media (min-width: 992px) {
      height: 10px;
      width: 10px;
    }

    @media (min-width: 1200px) {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }

  &.point-se:before {
    background-color: var(--blue);
    bottom: -50%;
    content: " ";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  //background-image: url('../images/bg.png');
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
