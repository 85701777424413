.dna-drive-view--unsupported {
  text-align: center;

  &-text {
    padding: 8px 16px;
    background-color: black;
    border-radius: 3px;
  }

  &-btn {
    margin-top: 16px;
    transform: scale(1.2);

    &:hover {
      transform: scale(1.3) !important;
    }
  }
}