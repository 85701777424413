@import "../scss/vars.scss";

.x-card {
  box-shadow: $cardShadow;

  border-radius: 15px;
  min-height: 50px;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;

    z-index: -1;
    pointer-events: none;
    content: "";
    width: 60%;
    height: 60%;

    max-width: 600px;
    min-width: 200px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }

  z-index: 10;
  max-height: 99vh;
  background-color: white;

  .x-close-card {
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    transform: translate(-5px, 5px);
    @include bg10;
    cursor: pointer;
    &:hover {
      background: rgba(pink, 0.3);
    }
    svg {
      font-size: 17px;
    }
  }

  &.dna-center-card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 98vh;
    min-height: 400px;
  }
  &.dna-right-card {
    position: absolute;
    top: 1vh;
    right: 10px;
    max-height: 98vh;
    min-height: 500px;
  }
  &.dna-center-card-fixed {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 98vh;
    min-height: 300px;
  }

  .dna-card-modal {
  }
}

.dna-dark .x-card,
.x-card.dna-dark {
  border: 1px solid #333;

  background-color: $bgDark;
  background-size: auto;
  box-shadow: $cardShadowWhite;

  .x-close-card {
    background-color: rgba(white, 0.1);
    &:hover {
      background-color: rgba(pink, 0.4);
    }
  }

  &:after {
    display: none;
  }
}
