@import "../scss/vars";

.x-task-master {
  max-width: 950px;
  width: 100%;
  height: calc(100vh - 80px);
  margin: 0 auto;
  position: relative;
  display: flex;

  .x-tm-intro {
    padding: 30px;
    margin-left: 100px;
  }

  .x-tm-header {
    height: 60px;

    h2 {
      margin: 0;
      font-size: 30px;
      font-weight: 100;
      margin-bottom: 6px;
      span {
        font-size: 12px;
        font-weight: bold;
      }
    }

    ul {
      display: flex;
      li {
        text-transform: uppercase;
        font-size: 10px;
        margin-right: 20px;
        font-weight: normal;
        cursor: pointer;
        svg {
          transition: all 0.3s;
          opacity: 0;
          color: red;
        }

        &.active {
          font-weight: bold;
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  .x-t-grid {
    height: calc(100vh - 160px);
    background-color: rgba(white, 0.8);
    backdrop-filter: blur(3px);
    width: 100%;
    margin-top: 10px;
    .ag-row-odd {
      @include bg05;
    }
    .ag-floating-top {
      border-radius: 5px;
      border: 1px solid rgba($dark, 0.5);
      box-shadow: 0 2px 3px 0px rgba(black, 0.2);
    }

    .ag-row {
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 10px;

      &:not(:last-child) {
        border-bottom: 0px;
      }

      &:before {
        background: url(/img/noisy-light.png);
      }
    }

    .ag-body-horizontal-scroll {
      display: none;
    }

    .ag-header-cell {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        left: 0 !important;
      }
    }
    .ag-header-cell-label {
      text-transform: capitalize;
      font-size: 15px;
    }
    .ag-icon-desc {
      transform: scale(0.8) translateY(1px);
      color: red;
    }
    .ag-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: normal;
      white-space: normal;
      text-overflow: unset;
      padding-top: 13px;
      &.ag-cell-focus {
        border-color: transparent !important;
      }

      .x-tm-ai-title {
        margin-bottom: 6px;
      }

      svg {
        transform: translateY(3px);
        font-size: 16px;
      }

      .dna-progress-bar-x {
        min-height: 9px;
        max-height: 9px;
        margin-top: 10px;
        margin-bottom: 12px;
      }

      .dna-pill-box {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 18px;
        line-height: 28px;
        font-size: 12px;
        letter-spacing: 0.3;
        align-self: flex-start;
        display: flex !important;
        align-items: center;
        margin-bottom: 6px;
        svg {
          margin-right: 3px;
          transform: translateY(0px) !important;
          font-size: 15px;
        }
      }
    }
  }

  .x-action-bg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
    border-radius: 12px;
    height: 200px;
    width: 100%;
    background-size: cover;
    pointer-events: none;
    background-position: right;
    //mask-image: radial-gradient(ellipse at bottom left, black 0%, transparent 70%);

    background-image: url(/themes/missions/bg-1-tile.jpg);
    z-index: 99999999;
  }

  .x-tm-ai-details {
    p {
      font-size: 12px;
      opacity: 0.7;

      &.x-tm-dd {
        opacity: 1;
      }
    }

    .dna-pill-box {
      max-width: 140px;
    }
  }

  .x-tm-filters {
    margin-top: 0px;
    width: 350px;
    flex-direction: column;
    margin-right: 30px;
    box-shadow: 1px 2px 1px 0px rgba(gray, 0.2);
    border-radius: 13px;
    @include bg05;
    padding-top: 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.3s;
    &:hover {
      box-shadow: 1px 2px 6px 2px rgba(gray, 0.2);
    }
    label {
      letter-spacing: 0.3px;

      &.top {
        margin-top: 0;
      }
    }

    .dna-dd-menu {
      position: fixed;
    }

    > div:first-child > div:first-child {
      padding: 8px;
    }

    > div:first-child > div:last-child {
      margin-right: -1px;
      div {
        background: rgba(white, 0.2) !important;
      }
    }

    input.x-qfs-tm {
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc !important;
      border-radius: 0 !important;
      @include bgSolid;
    }

    .x-inset-light {
      margin-bottom: 15px;
      padding-top: 20px;
      @include bg10;
    }
  }
}
.dna-dark {
  .x-task-master {
    .x-t-grid {
      background-color: rgba($bgDark, 0.8);
    }
    .x-tm-filters {
      background: $bgDark !important;
      input.x-qfs-tm {
        border-bottom: 1px solid #555 !important;
      }

      .x-inset-light {
        background: rgba(gray, 0.1);
      }
    }
  }
}
