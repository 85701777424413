@import "../scss/vars.scss";

.dna-dark {
  .x-action-item {
    box-shadow: 0 0 40px rgba(black, 0.8);
    background-color: $bgDark;
  }
}

.x-task-loading {
  @include bg03;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 120px;
  margin-bottom: 10px;
}

.x-action-item {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 1.5vh;
  width: 800px;
  height: 96vh;
  max-height: 97vh;
  border-radius: 17px;
  box-shadow: 0 0 40px rgba(black, 0.2);
  @include bgSolid;
  overflow: hidden;
  padding-top: 0px;
  display: flex;
  transition: width 0.2s;

  label {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .ai-r-tags {
    .react-tags__suggestions {
      width: 350px;
    }
  }

  .mAX {
    width: 50px;
    display: block;
    height: 50px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: all 0.3s;
    transition-delay: 0.3s;
    transform: translate(-34px, -13px);
    &:hover {
      transform: translate(-34px, -13px) scaleX(-1);
    }
  }

  .x-aix-raci-dates {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .x-ai-people {
      transform: translateY(-10px);
    }
  }

  .x-aix-scroll-area {
    padding-bottom: 200px;
  }

  &.with-comments {
    width: 1150px;
  }

  .ai-nuke {
    width: 30px;
    height: 30px;
  }

  .x-aix-main-scrollbar {
    flex: 1;
  }
  .x-aix-some-text {
    ol,
    li {
      li {
        margin-bottom: 10px;
      }
    }
    p {
      max-width: none;
    }
  }

  .x-aix-close {
    right: 5px;
    position: fixed;
    font-size: 22px;
    top: 5px;
    cursor: pointer;
    z-index: 99;
    min-width: 38px;
    min-height: 38px;
    transform: translate(-2px, 2px);
  }

  .x-aix-title {
    background: none;
    font-size: 28px;
    font-weight: bold;
    max-width: 550px;
    margin-bottom: 0px;
    padding-left: 0;
  }

  .x-aix-process-duration {
    margin-bottom: 20px;

    .x-raci-dur-field {
      width: 280px;
      @include bg10;
      padding: 5px;
      border-radius: 3px;

      svg {
        font-size: 18px;
        margin-left: 10px;
      }
    }

    .dna-currency-field {
      width: 100px;
      margin-left: 8px;
    }
    p {
      margin-bottom: 0;
      margin-left: 20px;
      font-size: 14px;
      max-width: 360px;
      opacity: 0.5;
    }
  }

  .x-ai-process-control {
    .dna-button {
      margin: 0px;
    }
    #x-process-flow-previous-button,
    #x-process-flow-next-button {
      width: 50px;
      text-align: center;
    }
    #x-process-flow-previous-button {
      padding: 0px;
      margin-right: 2px;
      padding-left: 0px;
    }
    #x-process-flow-next-button {
      padding: 0px;
      margin-left: 2px;
      padding-right: 0px;
    }

    .x-ai-flow {
      display: flex;

      width: 300px;

      &.prev {
        li {
          text-align: left;
          padding: 3px 10px 3px 10px;
        }
      }

      &.next {
        justify-content: flex-end;
      }

      li {
        padding: 3px 10px 3px 10px;
        cursor: pointer;
        @include bg05;
        border-radius: 3px;
        max-width: 200px;
        text-align: right;

        &.x-ai-flow-ac {
          color: $dark;
          background-color: $mint;
          &:hover {
            background-color: aqua;
          }
        }

        &:hover {
          @include bg10;
        }
      }
    }
  }

  textarea.x-aix-title {
    letter-spacing: -1px !important;
  }

  .x-aix-code {
    font-weight: bold;
    font-size: 16px;
  }
  .x-aix-meta {
    margin-bottom: 10px;
    svg {
      font-size: 20px;
      line-height: 0;
      margin: 0;
      padding: 0;

      margin-bottom: -6px;
    }
  }

  .x-center-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .x-aix-date-wrapper {
      border: 1px solid orange;
    }

    .x-aix-due-date {
      position: relative;
      transition: all 0.2s;
      z-index: 8;
      height: 60px;
      min-width: 190px;
      transform: translateY(-5px);
      cursor: pointer;

      &.open {
      }

      div {
        font-size: 14px;
      }
      h3 {
        font-size: 22px;
      }

      .react-date-picker__calendar--open {
        right: 0 !important;
        left: unset !important;
      }

      .x-aix-date-picker {
        .react-calendar {
          position: absolute;
          right: 0;
          transform: translate(-20px, 0px);
        }
      }
    }
  }

  .x-ai-people {
    margin-top: 0px;
    min-height: 73px;
    margin-left: 10px;
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
    display: flex;
    align-items: flex-end;

    .x-aix-seen-msg {
      font-size: 12px;
      opacity: 0.5l;
      margin-left: 20px;
    }
    li.dna-people-person {
      opacity: 0.7;
      filter: blur(1px);

      &:hover,
      &.dna-action-item-seen,
      &.dna-person-pending,
      &.dna-people-add-trigger {
        opacity: 1;
        filter: blur(0);
      }
    }
  }

  .x-aix-progress {
    $transUp: -16px;
    $ballWidth: 8px;
    //padding-top: 10px;
    width: 100%;
    position: relative;
    margin-left: 4px;
    margin-top: 30px;

    &:before,
    .x-aix-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      transform: translateY(3px);
      content: "";
      height: 2px;
      @include x-grad;
      border-radius: 1px;
      z-index: -1;
    }

    .x-aix-progress-bar {
      @include blue-grad;
    }

    .x-aix-steps {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .x-aix-col {
        position: relative !important;
        display: block !important;
        min-width: $ballWidth;
        min-height: $ballWidth;
        transform: translateY(-12px);
        cursor: pointer;

        span {
          width: 18px;
          height: 30px;
          display: block;
        }
        &:last-child {
          &:after {
            margin-left: 7px;
          }
        }

        &.disabled {
          cursor: default !important;
          * {
            cursor: default !important;
          }
        }

        &.done {
          &:after {
            @include blue-grad;
            box-shadow: 0 0 0 8px rgba(aqua, 0.2);
          }
        }

        &:after {
          display: block;
          content: "";
          width: $ballWidth;
          height: $ballWidth;
          border-radius: 50%;
          @include x-grad;
          transform: translateY(-17px);
          pointer-events: none;
          transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          box-shadow: 0 0 0 6px rgba($orange, 0.3);
          pointer-events: none;
        }

        &:hover:not(.disabled) {
          &:after {
            transform: translateY(-17px) scale(2.4);
          }
        }
        &:hover.disabled {
          &:after {
            pointer-events: none;
          }
        }

        p {
          position: absolute;
        }

        .x-aix-progress-label {
          cursor: pointer;
          font-size: 13px;
          display: inline-block;
          position: relative;
          transform: translateY(-11px);
          min-width: 40px;
          white-space: nowrap;
          max-width: 115px;
          text-overflow: ellipsis;
          overflow: hidden;

          &.x-aix-step-on {
            font-weight: bold;
            @include blue-grad;
            border-radius: 3px;
            padding: 2px 3px;
          }
        }

        &:nth-child(even) {
          .x-aix-progress-label {
            transform: translateY(28px);
            //  padding-top: 37px;

            //  transform: translateY(10px);
          }
        }

        &:last-child {
          &:nth-child(even) {
            .x-aix-progress-label {
            }
          }
          &:nth-child(odd) {
            .x-aix-progress-label {
              transform: translate(-30px, -12px);
            }
          }
        }
      }
    }
  }

  .x-aix-complexity {
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 320px;

    &.x50 {
      .input-range__track--active {
        background: orange;
      }
    }
    &.x30 {
      .input-range__track--active {
        background: #7fff9e;
      }
    }
    //Hello
    &.x80 {
      .input-range__track--active {
        background-color: #ff0051;
      }
    }

    .input-range__label--max {
      right: 5px;
    }
    .input-range__label--min {
      left: 5px;
    }

    .input-range__track--background {
      background: linear-gradient(
        90deg,
        rgba(127, 255, 158, 1) 0%,
        rgba(255, 214, 127, 1) 58%,
        rgba(255, 0, 81, 1) 100%
      );
    }
  }

  .x-aix-controls {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;

      .dna-under-con {
        svg {
          transition: all 0.3s;
        }

        &:hover {
          svg {
            transform: scaleX(-1);
          }
          box-shadow: 0 0 0 5px rgba($blue, 0.2);
        }
      }
      &:first-child {
        .dna-under-con {
          margin-right: 10px;
        }
      }
      &:last-child {
        .dna-under-con {
          margin-left: 10px;
        }
      }

      .MuiBadge-root {
        margin-right: 10px;
      }

      .x-aix-comment-trigger {
        margin-right: 3px;
        margin-left: 10px;
      }
    }

    .fas {
      font-size: 18px;
      margin-right: 0 !important;
    }

    .pin {
      &.active {
        box-shadow: none;
        background: $orange;
        color: white;
      }
    }

    .dps {
      font-size: 17px;
      margin-right: 0 !important;

      svg {
        //transform: translateY(3px);
      }
    }
  }

  .x-aix-blocked {
    margin-bottom: 10px;
    label {
      margin-top: 0px;
    }
    .ql-editor {
      margin-bottom: 10px;

      &:before {
        left: 0;
      }
    }
  }

  .x-ai-deps,
  .x-ai-audit {
    position: fixed;
    border: 2px;
    top: 0;
    left: 0;
    z-index: 99;
    @include bgSolid;
    width: 100%;
    height: 100%;
    padding: 30px;
    transition: all 0.3s;
    pointer-events: none;
    transform: translateX(100%);
    opacity: 0;

    &.active {
      pointer-events: all;
      transform: translateX(0%);
      opacity: 1;
    }
    .dna-left-text {
      > svg {
        transform: translateY(2px);
      }
    }
  }

  .x-ai-audit {
    .x-aix-history-row {
      margin-bottom: 30px;
    }
    .x-aix-audit-person {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .x-aix-person-time {
      font-size: 14px;
      margin-left: 38px;
      opacity: 0.5;
      margin-top: -5px;
    }

    .x-aix-audit-changes {
      margin-left: 38px;
    }
  }

  .x-ai-deps {
    .dna-left-text {
      > svg {
        transform: translateY(2px);
      }
    }
    ul {
      margin-top: 20px;
      border-top: 1px dotted gray;
    }
    li {
      width: 100%;
      display: block;
      border-radius: 6px;
      padding: 8px;
      padding-left: 10px;
      position: relative;
      background: rgba(silver, 0.1);

      margin-bottom: 1px;
      text-align: left;
      padding-right: 20px;
      cursor: default;
      strong {
        display: block;
        margin-bottom: 8px;
      }
      p {
        text-align: left;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 0;
      }

      &.done {
        background-color: rgba($mint, 0.2);
        strong {
          text-decoration: line-through;
        }
      }

      .dna-under-con {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  .x-aix-approval {
    margin-left: 10px;
  }

  .x-aix-attachments {
    border-radius: 8px;
    position: relative;
    @include bg05;
    padding: 0px;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    height: 250px;
    &.active {
      max-height: 250px;
    }

    .x-aix-att-controls {
      position: absolute;
      padding: 3px;
      display: flex;
      z-index: 2;
      > div {
        margin-right: 7px;
      }
    }

    .x-aix-att-wrapper {
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 5px;
      height: 100%;
      position: relative;
      padding-top: 35px;
    }

    .dna-attachment {
      padding-bottom: 0;
      margin-top: 10px;
      overflow: visible;
    }
    .x-aix-uoloading-file {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      @include bg50same;
      backdrop-filter: blur(8px);
      z-index: 20;
      padding: 30px;
    }

    .dna-attachment-item {
      @include bgSolid;
      border-radius: 8px;
      min-height: 170px;
    }

    .dna-attachment-file-preview,
    .dna-attachment-file-preview-thumbnail {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
    }
  }

  .x-cl-prg {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .dna-progress-bar-x {
      width: 145px;
      margin-right: 10px;
      min-height: unset;
      height: 2px;

      div {
        height: inherit;
        background: linear-gradient(to left, #c6ffdd, #fbd786, #f7797d);
      }
    }
    * {
      font-size: 12px;
    }
    .dna-pill-box {
      display: flex;
      padding: 2px;
      align-items: center;
      padding-left: 12px;
      margin-left: 8px;
      cursor: pointer;
      div {
        min-width: 23px;
        height: 23px;
        @include bgSolid;
        border-radius: 20px;
        margin-left: 6px;
        line-height: 25px;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .cl-head {
    //padding: 5px 10px;
    //@include bg10;
    border-radius: 7px;
    display: block;
    margin-bottom: 10px;
    margin-left: 5px;
    font-weight: normal;
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
  }

  .x-aix-checklist {
    position: relative;
    margin-top: 50px;

    .ql-tooltip {
      width: unset !important;
    }

    .x-cl-list-wrapper {
      display: flex;

      .x-aix-cl-list {
        width: 360px;

        min-height: 300px;
        .x-cl-wrapper {
          display: block;
        }
      }
    }

    .x-ai-add-todo {
      position: relative;
      height: 105px;
      .x-aix-todo-input {
        padding: 0px;
        border-radius: 0px;
        border-bottom: 1px solid;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        position: relative;
        display: flex;
        flex-direction: column;

        padding: 0px 10px 0px 0px;

        .ql-editor {
          width: 100%;
          transform: translateX(0);
          &:before {
            //left: 20px !important;
          }
        }
      }
      .x-cl-add-it {
        position: absolute;
        right: 0;
        bottom: 20px;
        max-width: 30px;
        max-height: 30px;

        svg {
          transform: translateX(0px);
        }
      }
    }
  }

  .x-aix-text {
    position: relative;
    z-index: 2;
    .ql-editor {
      transform: translate(0, -10px);
    }
  }
}

.x-ai-cl {
  position: relative;
  backdrop-filter: blur(5px);
  width: 360px;
  @include bg05;
  border-radius: 18px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 2px rgba(black, 0.1);
  padding: 15px;
  transition: box-shadow 0.3s ease;
  user-select: none;

  &.has-color {
    color: $dark !important;
    input,
    textarea {
      color: $dark !important;
    }
  }

  .x-cl-comments {
    position: absolute;
    right: 8px;
    top: 50px;
  }

  table {
    display: none;
  }

  img {
    max-width: 300px;
  }

  &:hover {
    box-shadow: 2px 2px 2px rgba(black, 0.2);
  }

  .x-mx-length {
    position: absolute;
    right: 4px;
    transform: translateY(-16px);
  }

  &:hover,
  &:active {
    z-index: 2;
  }

  .x-cl-done-box {
    width: 31px;
    background: #f0f0f0;
    mix-blend-mode: multiply;
    height: 31px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: all 0.3s;
      opacity: 0.05;
      pointer-events: none;
    }
    &:hover {
      background-color: $mint;
      color: $dark;
      mix-blend-mode: normal;
      svg {
        opacity: 0.4;
      }
    }

    &.done {
      background-color: $mint;
      color: $dark;
      mix-blend-mode: normal;
      svg {
        opacity: 1;
      }
    }
  }

  .x-cl-title-edit,
  .x-cl-title-read {
    font-size: 19px;
    font-weight: normal;
    max-width: 300px;
  }

  .x-cl-title-edit {
    width: 100%;
    max-width: 300px;
    background-color: rgba(black, 0);
    transition: all 0.3s;
    padding: 0;
    border-radius: 10px;
    padding: 8px;
    transform: translate(-8px, -8px);
    transition-delay: 0.3s;
    &:hover,
    &:focus {
      @include bg08;
    }
  }

  .x-hive {
    margin-top: 5px;
  }

  .quill {
    position: relative;
    z-index: 2;
  }

  .ql-editor {
    border-radius: 10px;
    padding: 8px;
    position: relative;
    z-index: 2;
    transform: translate(-8px, -8px);
    transition: all 0.4s;
    &:hover,
    &:focus {
      @include bg08;
    }

    &:before {
      padding-left: 10px;
    }
  }

  .x-cl-footer {
    display: flex;
    position: relative;
    justify-content: space-between !important;

    align-items: center;
    margin-top: 18px;

    .x-cl-cal-tip {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      font-size: 11px;
      white-space: nowrap;
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }
      .cal {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        @include bg10;

        margin-right: 4px;

        svg {
        }
      }

      &.red .cal {
        cursor: pointer;
        background-color: rgba(pink, 0.3);
        svg {
          transform: translate(-1px, 0);
        }

        &:hover {
          background: red;
          color: white;
        }
      }
    }
    .circle-picker {
      transform: translate(-5px, -5px);

      width: unset !important;
      height: inherit !important;
      min-height: 0 !important;
      max-height: 18px;

      div {
        margin-right: 3px !important;
      }
    }
  }
}

.dna-dark {
  .x-action-item {
    .x-ai-cl {
      .x-cl-done-box {
        transition: all 0.3s;
        background-color: #444;
        color: #333;
        svg {
          opacity: 0.5;
        }

        &:hover {
          background: #666;
          svg {
            opacity: 1;
          }
        }

        &.done {
          background: $mint;
        }
      }
    }
  }

  .x-aix-messages {
    .x-aix-message-header {
      &.chat {
        background: linear-gradient(to left, #f27121, #e94057, #8a2387);
        color: white;
        svg {
          color: pink;
        }
      }
    }
  }
}

.x-aix-messages {
  width: 360px;
  position: relative;
  max-width: 0;
  transition: max-width 0.2s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .x-aix-message-header {
    max-width: 300px;
    margin-top: 6px;
    align-self: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    min-height: 23px;
    margin-left: 8px;
    line-height: 23px;
    padding-left: 9px;

    &.no-chat {
      @include blue-grad;
    }
    &.chat {
      //  background: linear-gradient(to right, #fdfc47, #24fe41);

      background: linear-gradient(
        to right,
        #a8ff78,
        #67f0c6
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      font-weight: bold;
      color: white;
      text-shadow: -1px -1px 1px rgba(green, 0.3);
      svg {
        margin-right: 2px;
        color: green;
      }
    }
  }

  &.open {
    max-width: 400px;
  }

  .x-aix-message-bars {
    flex: 1;
    min-width: 350px;
    //mask-image: linear-gradient(to top, rgba(black, 0) 0%, black 8px);
    .simplebar-placeholder {
      height: auto !important;
    }
    .simplebar-content {
      padding-bottom: 30px !important;
    }
  }

  .x-aix-comment {
    padding-left: 10px;

    &.mine {
      //  padding-left: 30px;
      .x-aim-msg {
        background: rgb(0, 132, 255);
        color: white;
        //  margin-right: 20px;
      }
    }

    &.img {
      .x-aim-msg {
        background: none;
      }
    }

    .x-aim-user {
      font-size: 12px;
      display: flex;
      transform: translateY(15px);
      align-items: center;
      span {
        margin-left: 5px;
        opacity: 0.5;
        display: inline-block;
        transform: translateY(-7px);
      }
    }

    .x-aim-msg {
      padding: 5px;
      @include bg10;
      margin-bottom: 5px;
      border-radius: 18px;

      margin-right: 25px;
      margin-left: 14px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 20px;
      font-size: 14px;
      word-wrap: break-word;

      img {
        max-width: 270px;
        max-height: 70vh;
        border-radius: 8px;
      }
    }

    .x-aim-actions {
      padding-top: 0px;
      padding-left: 26px;
      font-size: 12px;

      div {
        margin-right: 20px;
      }
    }
  }

  .x-aix-emojis {
    position: absolute;
    left: 10px;
    bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    z-index: 9;
    max-width: 300px;
    justify-content: space-between;
    align-items: center;
    > div {
      cursor: pointer;
      margin-right: 3px;
      margin-bottom: 4px;
      svg {
        display: block;
        transform: translateX(2px);
      }
      position: relative;
      transition: all 0.2s;
      &:hover {
        transform: scale(2.3);
      }
    }
  }

  .x-aix-input-wrapper {
    transition: all 0.3s;
    padding-bottom: 55px;
    padding-top: 8px;
    padding-left: 7px;
    padding-right: 15px;
    margin: 5px;
    border-radius: 10px;
    border: 1px dashed rgba(gray, 0.3);
    @include bg02;
    //box-shadow: inset 0 -1px 2px rgba(gray, 0.2);

    .x-aix-comment-textarea__highlighter {
      display: none;
    }

    .x-aix-comment-textarea__input {
      min-height: 60px;
      transition: all 0.5s;
      line-height: 22px !important;
      background: none !important;
      position: relative !important;
    }
    .x-aix-comment-textarea__suggestions {
      background-color: $bgDark !important;

      color: white !important;
      padding: 10px;
      left: 10px;
      transform: translateY(-100%);
      overflow: auto;
      min-width: 300px;
      padding-bottom: 20px;
      max-height: 400px;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba($dark, 0.3);
      background: url(/img/noisy.png) $light;
    }
    .x-aix-comment-textarea__suggestions__list {
      min-width: 250px;

      list-style: none;
      .x-aix-comment-textarea__suggestions__item {
        transition: 0.3s;
        display: flex;
        padding: 7px;
        border-radius: 4px;
        font-size: 14px;

        &--focused {
          background-color: rgba(white, 0.1);
        }
      }
    }
  }
}
.x-ai-send {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  background-color: #4287f5;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: $mint;

    color: black;
  }
}
.ai-nuke {
  border-radius: 50%;
  @include bg10;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  .dna-sonar {
    transform: translate(2px, 0);
    position: absolute;

    svg {
      color: black !important;
      transform: rotate(178deg) translateX(1px);
    }
  }

  span {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  svg {
    transition: all 0.3s;
    transform: rotate(178deg) translateX(-1px);
  }
  &:hover,
  &.active {
    background: yellow;
    color: black;
    svg {
      opacity: 1;
    }
  }
}

.x-ai-raci {
  display: block;
  clear: both;
  width: 100%;
  transform: translate(0px, -8px);
  position: relative;
  z-index: 2;

  .x-aix-raci-person {
    position: relative;
    padding-right: 20px;
    .dna-under-con.red {
      position: absolute;
      right: 7px;
    }
  }

  .x-raci-add-roles {
    padding: 8px 6px 1px 10px;
    border-radius: 4px;
    @include bg05;
    margin-top: 40px;
    font-size: 14px;
    .dna-label {
      margin-bottom: 5px;
    }
  }

  .x-ai-raci-units {
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
    @include bg05;
    .dna-under-con {
      margin-left: 10px;
    }

    .x-inset-light {
      margin-bottom: 10px;
    }

    label {
      font-size: 13px;
      margin-bottom: 4px;
    }

    p {
      max-width: none;
    }
  }

  table {
    margin-top: -20px;
    width: 100%;
    td {
      width: 50%;
      vertical-align: top;

      .x-ai-raci-trigger {
        max-width: 150px;
        ul {
          align-self: flex-start;
          display: block;

          li {
            line-height: 20px;
            margin-right: 5px;
            font-size: 13px;
          }
        }
      }
    }
  }

  ul {
    display: flex;
    cursor: pointer;
    li {
      display: flex;
      width: 17px;
      height: 17px;
      justify-content: center;
      align-items: center;
      marging-right: 5px;
      border-radius: 50%;
      margin-right: 3px;
      font-size: 8px;
      padding-top: 1px;
      text-transform: capitalize;
      &:nth-child(1),
      &.x-ai-r-r {
        background-color: #b53f59;
        color: white;
      }
      &:nth-child(2),
      &.x-ai-r-a {
        background-color: #e6b940;
        color: white;
      }
      &:nth-child(3),
      &.x-ai-r-c {
        background-color: #548ed6;
        color: white;
      }
      &:nth-child(4),
      &.x-ai-r-i {
        background-color: #7dd65c;
        color: white;
      }
    }
  }
}

.x-a-i-r-cont {
  width: 17px;
  height: 17px;
  min-width: 17px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  marging-right: 5px;
  border-radius: 50%;
  margin-right: 3px;
  font-size: 10px !important;
  padding-top: 1px;
  text-transform: capitalize;
  text-align: center;
}
.x-ai-r-r {
  background-color: #b53f59;
  color: white;
}

.x-ai-r-a {
  background-color: #e6b940;
  color: white;
}
.x-ai-r-c {
  background-color: #548ed6;
  color: white;
}
.x-ai-r-i {
  background-color: #7dd65c;
  color: white;
}

.x-ai-raci-trigger {
  @include bg10;
  display: flex;
  padding: 4px;
  border-radius: 20px;
  transform: translateX(0px);
  margin-bottom: 8px;

  transition: all 0.3s;

  &:hover {
    @include bg20;
  }

  div {
    margin-left: 3px;

    border-radius: 50%;
    width: 17px;
    height: 17px;
    @include bg10;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: 0.7;
      font-size: 13px;
    }
  }
}

.x-aix-img-msg-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999;
  img {
    max-width: 100vw;
  }
}
