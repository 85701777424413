@import "../scss/vars.scss";

.dna-dark {
  .dna-crud-mission {
    .col-launch {
      background: url(/img/noisy.png) #242629;
    }
  }
}

$cWidth: 780px;
.dna-crud-mission {
  .crud-main-col {
    margin: 0 auto;
    max-width: $cWidth;
    padding: 30px 50px;
  }

  h2 {
    font-size: 28px;
    font-weight: 800;
    span {
      font-weight: 100;
    }
  }

  .beta-mx {
    position: absolute;
    font-size: 12px;
    color: white;
    font-weight: bold;
    background-color: $accent;
    transform: translate(74px, -25px);
    border-radius: 2px;
    padding: 2px 8px;
  }

  .crud-mx-logo {
    width: 110px;
    transform: translateY(-10px);
  }

  input {
    max-width: $cWidth;
    &::placeholder {
    }
  }

  .dna-drop-down {
    max-width: $cWidth;
  }

  p {
    svg {
      transform: translateY(2px);
      opacity: 0.6;
      font-size: 16px;
    }
  }

  .crud-template-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 20px;
      font-size: 30px;
      margin-top: 30px;
      text-align: center;
    }

    p {
      max-width: none;

      margin-bottom: 5px;
    }
    .dna-check-boxes p {
      max-width: 550px;
    }
  }

  label {
    font-weight: bold;
  }

  .t-tip.crud-helper {
    z-index: 88;
    display: block !important;
    right: 0;
    position: absolute;
    transform: translate(10px, -30px);

    flex-grow: 0 !important;
    max-width: 80px !important;
    min-width: 80px !important;

    svg {
      transform: scale(0.5) translateY(-40px);
    }
    &:after {
      transition: all 0.4s;
      display: block;
      content: "";
      background-color: black;
      width: 30px;
      height: 10px;
      margin-left: 25px;
      border-radius: 50%;
      filter: blur(2px);
      margin-top: -45px;
    }
  }

  #mc-xa-rocket-2 {
    padding-top: 0px;
  }

  transition: all 0.3s ease-in;

  .dna-pic-blocks {
    li.selected {
      border: 5px solid aqua;
    }
  }

  .dna-scroll-body {
    padding: 30px;
    padding-bottom: 150px;
  }

  .dna-modal {
    z-index: 999;
    position: fixed;
  }

  p {
    max-width: 610px;
  }

  p.dna-label {
    margin-bottom: 3px;
    margin-top: 15px;
  }

  .dna-check-boxes {
    margin-top: 20px;
  }

  .crud-client-dropdown {
    margin-bottom: 10px;
  }

  .dept-tags,
  .goal-tags,
  .mission-tags {
    display: flex;

    justify-content: flex-start;
    //border-bottom: 1px dashed rgba(gray, 0.2);
    margin-bottom: 5px;
  }

  &.all-depts-selected {
    .dept-tags .react-tags__search-input {
      display: none !important;
    }
  }

  &.all-goals-selected {
    .goal-tags .react-tags__search-input {
      display: none !important;
    }
  }

  .mc-org-title {
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  .add-ons {
    h2.bord {
      margin-top: 10px !important;
      padding-top: 20px;
      border-top: 1px dashed silver;
    }
  }

  .power-users {
    margin-bottom: 50px;

    label {
      margin-top: 40px;
    }

    p {
      text-align: left;
    }
  }
  .dom-tip {
    margin-top: 15px;
    border-top: 1px dashed silver;
    text-align: left;
    font-size: 14px;
    padding-top: 10px;
  }
  div.qs-f-t {
    max-width: 650px !important;
    border: 1px gray dashed;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    padding-top: 20px;
    h2 {
      font-size: 24px;
    }
    .dna-drop-down {
      max-width: 520px !important;
      margin: 0 auto;
    }
  }
  .col-launch {
    max-width: 720 !important;
    margin-top: 0px;
    border: 1px rgba(gray, 0.4) dashed;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 40px;
    margin-top: 15px;
    background: linear-gradient(-45deg, #ece9e6, #ffffff);

    h2 {
      margin-top: 0px !important;
      margin-bottom: 10px !important;
      font-size: 24px;
    }

    .select-buttons {
      position: relative;
      margin-top: 10px;
      display: flex;
      max-width: none !important;
      align-items: center;

      max-width: 560px !important;
      justify-content: center;

      .dna-button {
        min-width: 200px;
        height: 120px;
        display: block;
        position: relative;
        margin: 0px;

        white-space: nowrap;

        span {
          transition: all 0.3s ease-in;
          font-size: 20px;
          display: inline-block;
          margin-right: 7px;
          text-shadow: 0 0 2px white;
          transform: translateX(-10px) scale(4);
        }
        &:hover {
          span {
            transform: scale(2) !important;
            text-shadow: 0px 1px 2px white;
          }
        }

        text-transform: none;
        font-size: 15px;
        border-radius: 6px;
        color: white;

        &.crd-s-butt {
        }
        &.crd-k-butt {
        }
        &.crd-g-butt {
        }
        &.crd-cg-butt {
        }
        &.crd-sa-butt {
        }
      }
    }

    h1 {
      margin-top: 40px;

      .dna-emoji {
        display: inline-block;
        margin: 0 10px;
        &:first-child {
          font-size: 36px;
          margin-right: 5px;
          margin-top: -3px;
        }
        &:nth-child(2) {
          margin-right: 20px;
          font-size: 65px;
          transform: translateY(30px);
        }
      }
      &:last-child {
      }
    }
  }

  .add-on-div {
    margin-top: 50px;
    margin-bottom: 50px;
    > div {
      font-size: 16px;
      min-width: 200px;
      white-space: nowrap;
      cursor: pointer;
    }
    > p {
      margin: 0 10px;
    }
  }
  .add-on {
    margin-right: 10px;
    cursor: pointer;
  }

  .add-ons {
    max-width: 800px !important;
    .add-on-item {
      border-top: 1px dashed #cccccc;
      margin-top: 30px;
      padding-top: 25px;
      p {
        text-align: left;
      }
      .dna-checkbox {
        span {
          font-weight: bold;
        }
      }

      h2 {
        margin: 0;
        text-align: left;
        font-size: 18px;
        span {
          opacity: 0.4 !important;

          font-size: 14px;
        }
      }
    }
  }

  .for-others-join {
    transform: translateX(80px);
    width: 400px;

    .dna-drop-down {
    }

    &.dd {
      position: relative;
      z-index: 99;
    }
  }

  .crud-org-select {
    width: 100%;
    max-width: $cWidth;
  }

  .dna-col {
    .crud-title {
      margin-top: 30px;
      max-width: $cWidth !important;
      font-size: 50px;
    }
    .crud-desc {
      max-width: $cWidth !important;
      width: 100%;

      transition: all 0.3s ease-in;
    }
    > div:not(.dna-button) {
      width: 100%;
      max-width: $cWidth;
    }

    .dna-modal {
      max-width: none;
    }

    h2.cd-h2 {
      margin-top: 40px;

      font-size: 30px;
    }

    .power-users {
      h2 {
        margin-top: 30px;
      }
    }

    .beauty-box {
      display: block;
      margin-top: 90px;
      margin-bottom: 50px;
    }

    .dna-action-tile {
      float: left;
      margin-left: -40px;
      margin-top: -50px;
    }
    .mission-tile-wrapper {
      float: right;
      transform: translate(50px, 0px);
    }

    > ul {
      margin-left: 60px;
      max-width: 780px;
    }

    .dna-action-tile,
    .dna-mission-tile {
      .dna-tile-bg {
        opacity: 0.9 !important;
      }
    }
  }

  .crud-jira {
    margin-top: 30px;

    label {
      line-height: 80px;
      margin-bottom: 0;
      img {
        margin-right: 10px;
        width: 40px;
        margin-top: 5px;
        vertical-align: middle;
      }
    }

    .jd {
      cursor: pointer;
      display: flex;
      margin-top: 10px;
      line-height: 40px;
      > div:first-child {
        margin-right: 20px;
      }
    }
  }

  .dna-tags .react-tags__search input {
    background-color: $accent !important;
    color: $light;
    text-align: left !important;
    border-radius: 3px;
    padding-left: 5px;
    min-width: 200px;
    &::placeholder {
      color: rgba($light, 0.7);
      text-align: left !important;
    }
  }

  .mission-tags {
    margin-top: 10px;
  }

  .dna-toggle-switch {
    margin: 0 0 30px 20px;
  }

  .budget-stuff {
    max-width: 500px !important;
    position: relative;
    margin-bottom: 0px;
    .dna-title-field {
      width: 330px;
      text-align: left;
    }
    > div {
      float: left;
      margin-right: 10px;
    }
    h1 {
      float: left;
      margin-top: 0px;
      font-size: 60px;
    }
    .funds-available {
      top: 0px;
      position: absolute;
      right: 0;

      input {
        width: 280px;
        margin-right: 50px;
        text-align: right;
      }

      label {
        margin-top: 0;
      }
    }
  }

  iframe {
  }
  p.down-d {
    margin-bottom: 50px;
  }

  .mission-budget {
    h2 {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    p {
      width: 100%;

      display: block;
      clear: both;
      max-width: none;
    }
  }

  .mail-msg {
    max-width: 550px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
