@import "../scss/vars.scss";

.dna-invite-to-mission {
  min-height: 550px;
  //background-image: url(/img/noisy.png);
  @include bg10;
  padding: 30px;
  padding-bottom: 20px;
  border-radius: 10px;
  min-width: 560px;

  .x-inv-show-add-dom,
  .x-inv-org-warning {
    transition: max-height 0.4s, opacity 0.4s;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    max-height: 0px;
    padding: 5px;

    &.open {
      padding: 10px;
      pointer-events: all;
      opacity: 1;
      max-height: 120px;
    }
  }

  .x-inv-org-warning {
    height: 130px;
    &.open {
      max-height: 130px;
    }
  }

  .x-inv-domain-warn {
    p {
      margin-bottom: 3px;
      font-size: 14px;

      .x-link {
        color: $blue;
        margin-left: 10px;
      }
      svg {
        transform: translateY(1px);
        font-size: 14px;
      }
    }
  }

  .x-action-bg {
    position: fixed;
    bottom: 0px;
    right: 0;
    left: unset;
    opacity: 0.4;
    height: 300;
    width: 500px;
    background-size: cover;
    pointer-events: none;
    background-position: center;
    mask-image: radial-gradient(ellipse at bottom right, black 0%, transparent 70%);
    //mix-blend-mode: luminosity;
    z-index: 20;
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 5px;
    width: 400px;
  }

  .x-inv-avatar {
    position: absolute;
    top: 33px;
    right: 42px;
    max-width: 46px;
    max-height: 46px;

    box-shadow: 0 0 0 10px gold;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.filled {
      box-shadow: 0 0 0 10px $mint;
      transform: scale(1.2);
    }
  }

  > h4 {
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .x-inv-search {
    margin-top: 0px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  }

  .dna-label {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .inv-text {
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .dna-pill-box.black-pill {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .dna-dotted-line {
    font-size: 12px;
    opacity: 0.8;
    clear: both;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 10px;
    border: 0;
  }

  .invite-avail {
    width: 330;

    margin-top: -10px;
    > span {
      margin-left: 0px;
    }
  }

  .x-pill {
    min-width: 180px;
    padding: 5px 10px;
  }

  .dna-dd-item {
    .dna-avatar {
      margin-left: 10px;
      margin-top: 10px;
    }
    h4 {
      font-weight: normal;
    }

    .abc-email {
      margin-left: 50px;
      opacity: 0.6;
      font-size: 14px;
      margin-top: -5px;
    }
  }

  .x-mission-invite-button {
    &.has-svg {
      svg {
        margin-top: -1px;
      }
      padding-left: 45px !important;
    }
  }

  .dna-check-boxes {
    width: 400px;
    margin-top: 10px;
    user-select: none;

    p {
      margin-bottom: 0;
    }
  }

  > label {
    margin-top: 25px;
  }
}

.dna-dark {
  .dna-invite-to-mission {
    background-color: $bgDark;
  }
}
