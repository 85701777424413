$aitem-width: 220px;
$aitem-height: 357px;

@mixin actionTileStyle {
  width: $aitem-width;
  height: $aitem-height;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0px 10px 2px rgba(255, 255, 255, 0.3), 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in;
  background-size: cover;
  opacity: 1;

  &:hover {
    opacity: 1 !important;
    .dna-tile-bg,
    h3 {
      opacity: 1 !important;
    }
    transform: scale3d(1.1, 1.1, 1);

    .dna-tile-content {
      .dna-action-tile-msg {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &.dna-always-msg {
    .dna-tile-content {
      .dna-action-tile-msg {
        transition: all 0.3s ease-in;
        background: rgba($dark, 0.9);
        padding: 3px;
        border-radius: 5px;
        color: $light;
        opacity: 1;
        left: 20px;
        right: 20px;

        transform: none;
      }
    }
  }

  @include dna-tile-mask;

  &.full-bg {
    .dna-tile-bg {
      mask-image: none;
    }
  }

  .dna-tile-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    label {
      font-family: $magdaClean;
    }

    > label {
      margin: 0;
      font-weight: normal;
      margin-bottom: 4px;
      font-size: 14px;
      opacity: 0.5;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease-in;
      &:hover {
        transform: scale3d(1.1, 1.1, 1);
      }
      //color: $light;

      &.dna-action-title-label {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 4px;
      }

      &.dna-action-item-action-label {
        margin-bottom: 7px;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    h3 {
      text-align: center;
      font-size: 19px !important;
      font-weight: 800 !important;
      letter-spacing: 0.4px !important;
      max-width: 100%;
      line-height: 23px;
      max-height: 90px;
      overflow: hidden;
      //text-shadow: 1px 1px 2px rgba(black,0.3);
      display: inline-block;
      word-wrap: break-word;
      margin-bottom: 10px;
    }

    .action-item-finish-this {
      &:hover {
        opacity: 1;
      }
      opacity: 0.8;
      position: absolute;
      bottom: 70px;
      transform: translate(55px, 0px);
    }

    .dna-action-tile-msg {
      text-align: center;

      background: rgba($dark, 0.9);
      color: $light;
      opacity: 0;
      border-radius: 5px;
      line-height: normal;
      font-size: 15px;
      padding: 3px 7px;
      margin: 0 10px;
      transform: translateY(30px);
      transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .invite-msg {
      opacity: 1;
      transform: translateY(-10px);
    }

    .dna-people {
      position: absolute;
      bottom: 40px;
    }
    .dna-progress-bar {
      width: $aitem-width - 20;
    }
  }

  .dna-progress-bar {
    max-width: 400px;
    margin-top: 30px;
    position: absolute;
    bottom: 10px;

    h4 {
      left: 0;
      width: 100%;
      transform: translateY(-16px) !important;
    }
  }
}

.dna-action-tile {
  @include actionTileStyle;
  &.dna-action-done {
    .dna-icon-check {
      margin-top: 20px;
    }
  }

  &.no-progress {
    .dna-people {
      bottom: 20px;
    }
  }
}

.action-item-external-wrapper {
  position: relative !important;
  height: 400px;
  transition: all 0.2s ease-in;

  &.action-item-fade-away {
    opacity: 0 !important;
    transform: translateY(100px) !important;
    pointer-events: none;
  }

  .dna-action-item-tile {
    @include actionTileStyle;
    user-select: none;

    transition: all 0.3s ease-in;

    z-index: 2;

    .action-item-duration-bar {
      max-width: 400px;
      margin-top: 30px;
      position: absolute;
      margin-left: 10px;
      bottom: 10px;
    }

    .action-item-people {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .dna-people {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &:hover {
    .dna-action-item-tile {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  .dna-tile-content {
    position: absolute;
    z-index: 999;

    .interactable {
      display: none;
      position: fixed;
      z-index: 20;
      height: 30px;
    }

    > div {
      width: 100%;

      label.action-item-mission-label {
        font-weight: 100 !important;
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.5;
        position: fixed;
        letter-spacing: 1px;
        z-index: 88;

        top: -2px;
        margin: 0;

        @include zoom-link;

        &:hover {
          opacity: 1;
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      h3 {
        position: absolute;
        top: 45px;
        display: block;
        padding: 0 15px;
        letter-spacing: 0;
        width: 100%;
      }
    }
  }

  .ai-last-comment {
    position: absolute;
    bottom: 115px;
    left: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: linear-gradient(to right, rgba($dark, 0.7) 0%, rgba($dark, 0.3) 100%);

    .t-tip {
      position: absolute;
      right: -4px;
      top: 7px;
    }
    h4 {
      margin: 0;
      font-weight: normal;
      font-size: 15px;
      opacity: 0.8;
      color: white;
    }
    .dna-light-text {
      font-size: 13px;
      margin-top: 1px;
      color: white;
    }
  }

  .action-item-start-this {
    position: absolute;
    top: 160px;
    width: 160px;
    height: 60px;
    opacity: 0;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: 50%;
    transform: translateX(-50%) scale(0.5);
    text-align: center;
    z-index: 99;
    cursor: pointer;
    overflow: hidden;
    pointer-events: all;
    line-height: 60px;

    font-weight: bold;
  }

  &:hover {
    .action-item-start-this {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }
    .bb {
      opacity: 0.5;
    }

    .dna-action-item-list {
      opacity: 1;
      top: $aitem-height + 20;
      pointer-events: all;
    }
  }

  .dna-action-item-meta {
    position: absolute;
    top: 6px;
    right: 4px;
    display: inline-block !important;
    width: 30px !important;
  }

  .dna-action-item-list {
    position: absolute;
    top: $aitem-height - 100;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease-in;
    pointer-events: none;
    z-index: 0;

    li {
      cursor: pointer;
      opacity: 1;
      color: $light;
      transition: all 0.2s ease-in;
      background: $dark;
      border-radius: 3px;
      margin-bottom: 3px;
      font-size: 14px;
      padding: 4px 10px;
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      mix-blend-mode: difference;

      &:hover {
        box-shadow: 0 0 10px rgba($light, 0.5);
        transform: scale3d(1.1, 1.1, 1);
      }

      div.t-tip {
        min-width: 200px !important;
        display: block !important;
      }

      &.ai-list-plan {
        background: rgba($dark, 1);
      }
    }
  }
}
