.dna-drive-view--image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 30ms ease;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &-is-hidden {
    opacity: 0;
  }

}