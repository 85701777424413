$fontSize: 14px;

html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overflow: hidden;
  overscroll-behavior: contain;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "MessinaSans", sans-serif;
  letter-spacing: 0.1px;
  font-weight: 100;
  background: white;
  font-size: 14px;
}

h1 {
  letter-spacing: -1px;
  font-size: 62px;
  margin-top: 0;
  padding-top: 0;
  font-weight: 100;
  margin-bottom: 20px;
  user-select: none;
  span,
  p,
  div {
    letter-spacing: 0.3px;
  }
}
h2 {
  font-size: 35px;
  user-select: none;
}
h3 {
  font-size: 24px;
  margin-bottom: 18px;
  font-weight: bold;
  margin-top: 0;
  letter-spacing: -0.8px !important;
}

.light-span {
  font-weight: 100;
}

.x-h3-field {
  font-size: 26px;
  font-weight: 800;
}

h2,
h4 {
  margin-top: 0;
  font-weight: 100;
  letter-spacing: 0px;
}
h4 {
  font-size: 16px;
}
p {
  font-size: $fontSize;
  max-width: 500px;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-top: 0;
  font-family: inherit;

  &.no-max {
    max-width: none;
  }

  svg {
    transform: translateY(2px);
  }

  &.nice-text {
    font-size: 16px;
    line-height: 26px;
  }
}
.no-max-width {
  max-width: none;
}
div {
  //font-size: $fontSize;
}
b {
  letter-spacing: -0.3px;
}
a,
.fake-a,
.fake-click,
.fake-trigger,
.plain-a {
  color: #1b82ce;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: normal;
  padding-left: 2px;
  padding-right: 3px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: rgba($blue, 0.1);
  }

  &.simple-a {
    text-transform: none;
    text-shadow: none;
    font-size: inherit;
  }

  &.clear-a {
    text-decoration: none;
    color: inherit !important;
  }
}

.dna-dark {
  a {
    &:hover {
      background-color: rgba(lighten($blue, 10%), 0.2);
    }
  }
}

thead {
  th {
    text-align: left;
    font-weight: normal;
    font-family: $magdaClean;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
}

.dna-text-right {
  text-align: right !important;
}
.dna-text-left {
  text-align: left !important;
}
.dna-text-center {
  text-align: center;
}

.ck a {
  text-transform: none;
  color: #c30b5d;
  display: inline;
  font-size: inherit;
  line-height: inherit;
  text-shadow: none;
  background: #fffee5;

  &:hover {
    text-decoration: underline;
  }
}

.cursor {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
ul.dna-bullet-list {
  li {
    margin-left: 20px;
    list-style: square;
    margin-bottom: 10px;
    list-style-position: outside;
  }
}

ol {
  li {
    margin-bottom: 10px;
  }
}
.ck {
  > ul {
    li {
      margin-left: 20px;
      list-style: disc !important;
    }
  }
}

hr {
  max-width: 200px;
  margin: 0px;
  height: 1px;
  border: 0;
  @include bg100;
  margin-top: 15px;
  margin-bottom: 10px;
}
label {
  font-weight: bold;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: $fontSize;
  letter-spacing: -0.5px;

  svg {
    transform: translateY(2px);
  }

  &.more {
    margin-bottom: 20px;
  }
  small {
    font-weight: normal;
  }
}
input,
textarea,
.dna-field-style {
  font-size: $fontSize;
  border-radius: 3px;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  padding: 8px 10px 8px 5px;
  background-color: rgba($dark, 0.02);
  transition: background-color 0.1s ease-out, border 0.2s;
  resize: none;
  width: 100%;

  outline: none;
  color: $dark;
  height: 40px;
  min-height: 40px;
  &.money-field {
    text-align: right;
  }

  &.x-check-box {
    width: 20px;
    height: 20px;
  }

  &::placeholder {
    color: rgba($dark, 0.5);
  }

  &.space-right {
    margin-right: 15px;
  }

  &:focus {
    //box-shadow: 0px 0px 4px 1px $blueAccent;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:valid {
    background-color: rgba($dark, 0.04);
  }
  &:hover {
    background-color: rgba($dark, 0.08);
  }

  &.dna-slim-field {
    background: none;

    border-radius: 0;
  }

  &.dna-data-entry-field {
    padding-left: 10px;
    border: 1px solid rgba($dark, 0.2);

    &:focus {
      border: 1px solid rgba($blueAccent, 0.2);
    }
  }

  &.dna-title-field {
    display: block;
    width: 100%;
    max-width: 1200px;
    font-size: 55px;
    text-align: center;
    padding: 3px 5px 5px 5px;
    margin: 0;
  }

  .dna-dark & {
    color: $light;

    background-color: rgba($light, 0.25);

    &::placeholder {
      color: rgba($light, 0.4);
    }

    &:focus {
      border-bottom: 0px solid #111;
      background-color: rgba($light, 0.15);
    }

    &:valid {
      background-color: rgba($light, 0.05);
    }

    &:hover {
      background-color: rgba($light, 0.2);
    }

    &.dna-data-entry-field {
      border-bottom: 1px solid rgba($light, 0.5);
    }
  }
  .dna-light & {
    color: $dark;
    background-color: rgba($dark, 0.025);

    &::placeholder {
      color: rgba($dark, 0.4);
    }

    &:focus {
      border-bottom: 1px solid #999;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:valid {
      background-color: rgba($dark, 0.006);
    }
    &:hover {
      background-color: rgba($dark, 0.04);
    }

    &.dna-data-entry-field {
      border-bottom: 1px solid rgba($dark, 0.5);
    }
  }
  &.invisi-field {
    background: none !important;
    border: 0 !important;
  }
}

input {
  max-height: 39px !important;
  min-height: 39px !important;
}

textarea {
  overflow: hidden;
}

strong {
  font-weight: bold;
}

.dna-flip-text {
  color: $light;
}

.dna-tootip-theme {
  .dna-fancy-field {
    &.fancy-focused {
      textarea {
        border: 0px;
        border-radius: 0;
        background: rgba($light, 0.2);
      }
    }
  }
}
.dna-dark {
  .dna-flip-text {
    color: $dark;
  }

  .dna-fancy-field {
    &.fancy-focused {
      textarea {
        background: rgba($dark, 0.1) !important;
      }
    }
  }
  .dna-tootip-theme {
    .dna-fancy-field {
      &.fancy-focused {
        textarea {
          border: 0px;
          border-radius: 0;
          background: rgba($dark, 0.01);
        }
      }
    }
  }
}

input,
.dna-field-style,
.dna-drop-down input,
.react-tags input,
.DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
  min-height: 40px;
  line-height: 19px;
  border-radius: 3px;
  outline: none;
  font-size: $fontSize;
}
.DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
  //pointer-events: none;
}

.dna-clear-field {
  background: none !important;
  border: 0 !important;
}
.dna-hl-field {
  background-color: rgba($lime, 0.2);
}

.dna-fancy-field {
  position: relative;
  margin: 0;
  padding: 0;

  textarea {
    padding-left: 12px;
    padding-right: 15px;
    margin: 0 !important;
    overflow: hidden;
  }

  .dna-fancy-field-extras {
    transition: all 0.2s ease-in;
    max-height: 0px;
    height: 50px;
    overflow: hidden;
    padding: 4px;
    opacity: 0;
    margin-top: -6px;
  }

  .input-emoji {
    position: absolute;
    right: 5px;
    top: 4;
    display: block;
    z-index: 2;
    font-size: 18px;
    cursor: pointer;
    opacity: 0;
  }

  &.fancy-focused {
    border: 1px solid rgba($dark, 0.1);
    background: rgba($light, 0.6);

    textarea {
      //background: none !important
    }
    .dna-fancy-field-extras {
      max-height: 80px;
      opacity: 1;
    }

    .input-emoji {
      opacity: 1;
      pointer-events: all;
    }
  }
}

@media only screen and (max-width: 1400px) and (min-width: 601px) {
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 30px;
  }

  input {
    font-size: $fontSize;
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow: auto;
  }

  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
}

.squigly-red {
  background-image: linear-gradient(45deg, transparent 65%, red 80%, transparent 90%),
    linear-gradient(135deg, transparent 5%, red 15%, transparent 25%),
    linear-gradient(135deg, transparent 45%, red 55%, transparent 65%),
    linear-gradient(45deg, transparent 25%, red 35%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 8px 2px;
  background-position: 0 100%;
}
