.x-dd-orange {
  input {
    background-color: orange;
    color: white;
  }
  ::placeholder {
    color: rgba(white, 0.6) !important;
  }
}

.dna-drop-down {
  position: relative;

  &.dna-drop-down-invert .dna-dd-menu {
    position: absolute;
    top: -3px;
    bottom: unset;
    transform: translateY(-100%);
  }

  .x-dd-dd-dd-icon {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    font-size: 12px !important;
    right: 7px !important;
    top: 50% !important;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    @include bg05;
    transform: translateY(-50%) !important;

    svg {
      opacity: 0.5;
    }
  }

  input {
    padding-right: 25px;
  }

  &.active {
    z-index: 9999999;
  }

  > div:first-child {
    width: 100%;

    > input:first-child {
      width: 100%;
      min-height: 39px;
      max-height: 39px;
    }
  }

  &.dna-center-text {
    > div:first-child {
      > input:first-child {
        text-align: center;
      }
    }
    .dna-dd-item {
      text-align: left;
    }
  }

  .x-dd-menu-header {
    padding: 5px;
    padding-left: 8px;
    padding-top: 8px;
    background-image: url("/img/noisy.png");
    font-size: 14px;
    font-weight: bold;
    @include bg05;
  }

  .dna-dd-menu {
    background: url("/img/noisy.png") white;
    box-shadow: 0 3px 10px rgba($dark, 0.2);
    border-radius: 3px;
    position: absolute;
    z-index: 9999999;
    padding: 0px;
    margin-top: 2px;
    width: 100%;
    max-height: 360px;
    overflow: auto;
  }
}
.dd-no-results {
  padding: 5px;
  font-size: 14px;
  font-weight: 100;
}

.dna-dd-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  border-radius: 3px;
  transition: all 0.5s;
  color: inherit;
  font-family: inherit;
  &:hover {
    @include bg10;
  }

  &.active {
    background-color: rgba(#fcf760, 0.5);
  }

  &.is-after {
    opacity: 0.4;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0;
  }
  .dna-light-text {
    font-size: 14px;
  }

  &.dna-people-list-item {
    display: flex;
    align-items: center;

    &:hover {
      .dna-round-avatar {
        transform: scale(1.2) translateY(-5px);
      }
    }

    .dna-round-avatar {
      transition: transform 0.4s ease;
      transform: translateY(-5px);
    }

    .dna-avatar {
      margin-right: 25px;
      min-width: 35px;
    }
  }
}

.dna-dark {
  .dna-drop-down {
    .dna-dd-menu {
      //box-shadow: 0 3px 10px rgba($light,0.7);
      background: $bgDark;
      box-shadow: 0 0 5px rgba(white, 0.3);
      .dna-dd-item {
        h4,
        span,
        p {
          color: $light;
        }
        background: rgba($light, 0.05);
        &:hover {
          background: rgba($light, 0.2);
        }
      }
    }
  }

  .dna-light {
    .dna-drop-down {
      .dna-dd-menu {
        background: url("/img/noisy.png") white;
        box-shadow: 0 3px 10px rgba($dark, 0.2);
        .dna-dd-item {
          h4,
          span,
          p {
            color: $dark !important;
          }
        }
      }
    }
  }
}

.dna-action-finder {
  .dna-dd-item {
    display: flex;
    align-items: center;
    .dna-round-avatar {
      margin-right: 15px;
    }
  }
}
