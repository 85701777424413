@import "../scss/vars.scss";

.ag-rich-select {
  box-shadow: 0 0 3px rgba(black, 0.5);
}

.dna-dark {
  .ag-sticky-top {
    background: $bgDark;
  }
  .ag-cell.ag-cell-inline-editing {
    padding: 0;
    input {
      background-color: $bgDark !important;
      color: white !important;
    }
  }
  .mx-ag-number-col {
    background-color: $bgDark !important;
    color: white !important;
  }

  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left: 1px solid #444;
  }
}
.x-grid-faded {
  .ag-cell {
    filter: grayscale(1);
    color: rgba(gray, 0.5);
  }
}

.x-grid-warning {
  .ag-cell {
    background-color: rgba(orange, 0.1);
  }
}
.x-grid-strike {
  .ag-cell {
    text-decoration: line-through;
    filter: grayscale(1);
    pointer-events: none;
    color: rgba(gray, 0.5);
  }

  &.active-strike {
    .ag-cell {
      pointer-events: all;
    }
  }

  &:hover {
    .ag-cell {
      text-decoration: none;
      color: inherit;
    }
  }
}

[class*="ag-theme-"] {
  font-family: "MessinaSans", sans-serif !important;
  font-weight: normal;
}

.x-grid-number-editor {
  min-height: unset !important;
  height: inherit !important;
  font-size: 12px;
  max-height: inherit !important;
  line-height: inherit !important;
  display: block !important;
  align-self: flex-start;
  padding: 0 !important;
  padding-right: 10px !important;
  text-align: right;
}

.x-basic-grid-field {
  height: inherit !important;
  padding: 0 !important;
  line-height: inherit !important;
  padding-right: 6px !important;
  text-align: right !important;
  min-height: unset !important;
  max-height: unset !important;
  font-size: 12px !important;
  color: #222 !important;

  &.left {
    text-align: left !important;
    padding-left: 10px !important;
  }
}

.ag-popup.ag-theme-alpine {
  color: inherit !important;
  background-color: inherit !important;

  .ag-rich-select {
    background: none;
  }
}
.ag-floating-top,
.ag-body-vertical-scroll,
.ag-body-horizontal-scroll {
  scrollbar-color: #ccc #fafafa;
}

.dna-dark {
  .ag-body-vertical-scroll,
  .ag-body-horizontal-scroll {
    scrollbar-color: #999 $bgDark;
    scrollbar-width: 5px;
  }
}

.ag-theme-alpine.dna-ag-grid {
  .ag-root-wrapper {
    border: 0;
    background: none;
  }

  &.no-focus-cell {
    .ag-cell {
      border-color: transparent !important;
    }
  }

  .ag-right-text {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    * {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }

  .x-grid-wrap-cell {
    white-space: normal;
    text-overflow: none;
    line-height: normal;
    word-break: break-word;
    padding-top: 6px;
  }

  .x-normal-line {
    * {
      line-height: normal;
    }
    line-height: normal;
  }

  &.magda {
    .ag-row {
      font-family: $magdaClean;
    }

    .ag-header-cell {
      font-family: $magdaClean;
    }
    .ag-cell {
      font-family: $magdaClean;
    }
  }

  .ag-center-cell {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .x-grid-red-cell {
    background-color: red;
    color: white;
    border-radius: 4px;
    padding-right: 3px;
    height: inherit;
    display: block !important;
  }

  .ag-center-header {
    * {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .dna-pill-box:not(.progress-pill) {
    line-height: normal;
    align-self: flex-start;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 11px;
  }
  .dna-pill-box.progress-pill {
    div {
      padding: 0;
      min-width: 2px;
    }
  }

  .dna-button.x-ag-button {
    padding: 4px 6px;
    height: auto !important;
    min-height: none;
    line-height: normal;
    border-radius: 4px;
    font-size: 12px;
    text-transform: none;

    &:hover {
      box-shadow: 4px 4px 0 #222;
    }

    &.x-ag-accent {
      color: white !important;
      background-color: $accent;
    }
  }

  .row-move-here {
    border-bottom: 1px dashed #6fb2ed !important;
    &:after {
      display: block;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      pointer-events: none;
      transform: translateY(34px);
      position: fixed;
      z-index: 999;
      content: "";
      border-left: 6px solid #6fb2ed;
    }
  }

  .ag-header-cell-moving {
    background-color: #333;
    color: white;
  }

  .row-no-here {
    background: pink !important;
  }
  .row-drop-here {
    border: 1px dashed #6fb2ed !important;
    &:after {
      display: block;
      width: 0;
      height: 0;
      pointer-events: none;
      transform: translate(0, 0px);
      left: 50%;
      position: absolute;
      z-index: 999;
      content: "";
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid #6fb2ed;
    }
  }

  &.shadow {
    .ag-header {
      border-top-left-radius: 3px;
      order-top-right-radius: 3px;
      z-index: 1;
      background-image: url(/img/noisy.png);
      box-shadow: 0 1px 1px rgba(black, 0.2);
    }
    border: 1px solid rgba(gray, 0.2);
    border-radius: 6px;
    div.ag-row-group {
      @include bg02;
      font-weight: bold;

      &:before {
        display: none;
      }
    }
    .ag-row-group-expanded {
      border-bottom: 1px rgba(#333, 0.2) solid;
    }
  }

  .ag-header {
    background: none;
    border-bottom: 1px solid #f5f5f5;

    .ag-header-cell {
      text-transform: uppercase;
      font-weight: normal;
      font-family: "MessinaSans";
      font-size: 11px;
      letter-spacing: 0px;
    }
    .x-ag-center {
      * {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .ag-sort-indicator-icon {
    &.ag-sort-order {
      display: none;
    }
  }

  .ag-column-drop-wrapper,
  .ag-column-drop {
    border: 0;
    background: none;
    * {
      color: inherit;
    }
    .ag-column-drop {
      font-family: inherit;
      font-weight: normal;
    }
  }

  .ag-row {
    font-size: 13px;
    font-family: "MessinaSans", sans-serif;
    letter-spacing: 0px;
    border-bottom: 1px solid #f5f5f5;
  }

  .ag-row-even {
    background: none;
  }
  .ag-row-odd {
    background: none;
  }

  .ag-row-selected::before {
    background-color: rgba(yellow, 0.04);
  }

  .ag-cell-data-changed {
    background-color: yellow !important;
    color: $dark !important;
  }

  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
    display: flex !important;
  }

  .ag-cell.ag-cell-focus:noth(.ag-cell-range-selected) {
    border-color: transparent;
  }

  .ag-cell {
    padding-left: 8px;
    padding-right: 8px;
    letter-spacing: 0px;

    .ag-selection-checkbox {
      margin-right: 8px;
      .ag-checkbox-input-wrapper {
        background: none;

        &.ag-checked {
          &:after {
            color: $mint;
          }
        }

        &:after {
          font-size: 14px;
        }
      }
    }

    &.x-cell-clickable-link {
      color: $blue;
      cursor: pointer;
    }
    &.x-cell-clickable {
      cursor: pointer;
      &:hover {
        &:before {
          @include bg10;
        }
      }
      &:before {
        content: "";
        width: inherit;
        height: inherit;
        @include bg03;
        position: absolute;

        top: 0;
        left: 0;
        z-index: -1;
      }
    }
    &.x-cell-hash {
      background-image: linear-gradient(
        -45deg,
        #e7e7e7 10%,
        rgba($mint, 0) 10%,
        rgba(white, 0) 50%,
        #e7e7e7 50%,
        #e7e7e7 60%,
        rgba(white, 0) 60%,
        rgba(white, 0) 100%
      );
      background-size: 7.07px 7.07px;
      background-size: 5.66pxrg 5.66px;
    }
    .x-cell-badge,
    &.x-cell-badge {
      &:after {
        @include bg10;
        transform: translate(-6px, 7px);
        display: block;
        content: "";
        position: absolute;
        right: 0;
        border-radius: 50%;
        top: 0;
        width: 6px;
        height: 6px;
      }
      &.orange {
        &:after {
          background-color: orange;
        }
      }
      &.red {
        &:after {
          background-color: $accent;
        }
      }
    }

    &.bold {
      font-weight: bold;
    }

    &.x-grid-link,
    .x-grid-link {
      text-decoration: underline;
      color: #4778a6;
      cursor: pointer;
    }

    &:first-child:not(.ag-cell-range-selected) {
      //border: 0 !important;
    }

    .ag-group-expanded {
      margin-right: 10px;
    }
  }
  .ag-cell-inline-editing {
    padding: 0;
    input {
      padding: 0 4px;
      border-radius: 4px;
      border: 0;
      @include bg05;
      min-height: 0;
      max-height: 100px;
      height: inherit;
    }
    &.ag-right-aligned-cell {
      input {
        text-align: right;
      }
    }
    .ag-cell-edit-wrapper {
      //border: 1px solid rgba(33, 150, 243, 0.4) !important;
    }
  }

  .ag-row-selected {
    color: $dark;
  }

  .grid-money-editor {
    height: 100%;
    min-height: none;
    background: none;
    border: 0;
    text-align: right;
  }
}

.ag-popup-child {
  @include bgSolid;
}
.ag-popup-editor {
  border: 0px solid rgba(gray, 0.3) !important;
  transform: translateY(1px) !important;
  border-radius: 3px;
  @include bgSolid;
  box-shadow: none !important;

  padding: 0;

  input {
    font-family: "MessinaSans";
    font-weight: normal;
    border: 0 !important;
    padding-left: 4px;

    background-color: rgba(yellow, 0.1);
    max-height: unset !important;
    min-height: unset !important;
  }

  .DateRangePicker {
    height: inherit;
    input {
      max-height: 36px !important;
      overflow: hidden;
      font-size: 14px !important;
      @include bg100;
    }
  }
}

.dna-dark {
  .ag-theme-alpine.dna-ag-grid {
    border-right: 1px solid rgba(white, 0.2);

    color: white;
    div:not(.dna-button) {
      color: inherit;
    }

    .ag-header-cell {
      border-top: 1px solid #333 !important;
    }

    .ag-pinned-left-header {
      border-right-color: lighten(#333, 10%) !important;
    }

    .ag-header {
      background: none;
      border-bottom: 1px solid #333;
      border-top: 1px solid darken(#333, 10%);
    }

    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border-right-color: lighten(#333, 10%) !important;
    }
    .ag-cell,
    .ag-header-cell,
    .ag-icon {
      color: white;

      &.x-grid-link,
      .x-grid-link {
        text-decoration: underline;
        color: #d6fa89;
        cursor: pointer;
      }
    }
    .ag-row-selected {
      //color: black !important;
      background: none !important;

      &::before {
        background-color: rgba(gray, 0.6) !important;
      }
    }
    .ag-row {
      border-bottom-color: rgba(black, 0.1);
    }
    .ag-row-even {
      background: rgba(silver, 0.09);
    }
    .ag-row-odd {
      background: rgba(silver, 0.05);
    }
    .ag-cell {
      &.x-cell-hash {
        background-image: linear-gradient(
          -45deg,
          #111 10%,
          rgba(white, 0) 10%,
          rgba(white, 0) 50%,
          #111 50%,
          #111 60%,
          rgba(white, 0) 60%,
          rgba(white, 0) 100%
        );
        background-size: 7.07px 7.07px;
        background-size: 5.66pxrg 5.66px;
      }
    }

    .ag-menu {
      display: none !important;
      background-color: $bgDark;
    }
  }
}

.ag-popup {
  transition: none !important;
  transition-delay: none !important;
}

.dna-ag-grid-tip {
  background-color: $bgDark;
  color: white;
  position: fixed;
  z-index: 99999999999;
  transition: none !important;
  transition-delay: none !important;

  &.red {
    background-color: #e30943;
  }
  max-width: 350px;
  transition: all 0.2s !important;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(black, 0.3);
  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    font-family: "MessinaSans", sans-serif;
  }
}
