//bouncem= cubic-bezier(0.68, -0.55, 0.265, 1.55);

//'cubic-bezier(0.175, 0.885, 0.32, 1.275)' big

$magda: "Magda", "Courier New", Courier;
$magdaClean: "Magda Clean", "Courier New", Courier;

$cardShadow: 0px 0px 2px rgba(#111, 0.6), 1px 1px 3px rgba(#111, 0.6), 5px 6px 0px rgba(#111, 0.5);

$cardShadowWhite: 10px 12px 0 rgba(black, 0.5);

$brandOrange: #ed7d32;
$brandTeal: #009288;
$orange: #ffb300;

@mixin x-grad {
  background: radial-gradient(circle at bottom right, gold, $accent);
}
@mixin red-grad {
  background: radial-gradient(circle at bottom right, red, $accent);
}
@mixin blue-grad {
  background: linear-gradient(to right, rgba(122, 188, 255, 1) 0%, rgba(68, 255, 161, 1) 100%);
  color: $light;
}
@mixin purple-grad {
  background: linear-gradient(to left, #ff00cc, #333399);
  color: $light;
}

@mixin orange-grad {
  background: linear-gradient(135deg, rgba(255, 195, 76, 1) 0%, rgba(255, 146, 10, 1) 100%);
  color: $light;
}
@mixin green-grad {
  background: linear-gradient(45deg, darken(#a8ff78, 15%), darken(#78ffd6, 15%));
  color: $light;
}
@mixin neg-grad {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
}
@mixin dna-section($direction) {
  $trans: -80%;

  @if ($direction == "up") {
    $trans: 80%;
  }
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
  transform: translateY($trans);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.5s ease-in-out, backgroundColor 0.3s ease-in, opacity 0.3s;
}
@mixin dna-show-section {
  transform: translateY(0);
  opacity: 1;
  top: 0;
  pointer-events: all;
}

@mixin dna-center-col {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

@mixin dna-tile-mask {
  &:before,
  .dna-tile-bg {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  &:before {
    background: url(/static/img/noisy.png);
    opacity: 1;
    border: 1px solid rgba($light, 8);
    mask-image: linear-gradient(to top, rgba(black, 0.3) 0%, black 76%);
    z-index: 0;
  }

  .dna-tile-bg {
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: opacity 0.2s ease-in, background 0.3s ease-in;
    mask-image: linear-gradient(to bottom, transparent 0%, black 90%);
    z-index: 1;
  }

  .dna-tile-content {
    position: absolute;
    z-index: 2;
  }
}

@mixin bg10same {
  background-color: rgba(white, 0.08);
  .dna-dark & {
    background-color: rgba($dark, 0.2);
  }
}
@mixin bg50same {
  background-color: rgba(white, 0.5);
  .dna-dark & {
    background-color: rgba($dark, 0.5);
  }
}

@mixin bg02 {
  background-color: #fafafa;
  .dna-dark & {
    background-color: rgba($light, 0.1);
  }
}
@mixin bg03 {
  background-color: rgba($dark, 0.02);
  .dna-dark & {
    background-color: rgba($light, 0.05);
  }
}
@mixin bg05 {
  background-color: rgba($dark, 0.035);
  .dna-dark & {
    background-color: rgba($light, 0.12);
  }
}
@mixin bg08 {
  background-color: rgba($dark, 0.07);
  .dna-dark & {
    background-color: rgba($light, 0.3);
  }
}

@mixin bg10 {
  background-color: rgba($dark, 0.08);
  .dna-dark & {
    background-color: rgba($light, 0.2);
  }
}
@mixin bg10Important {
  background-color: rgba($dark, 0.08) !important;
  .dna-dark & {
    background-color: rgba($light, 0.2) !important;
  }
}
@mixin bg15 {
  background-color: rgba($dark, 0.15);
  .dna-dark & {
    background-color: rgba($light, 0.25);
  }
}
@mixin bg20 {
  background-color: rgba($dark, 0.2);
  .dna-dark & {
    background-color: rgba($light, 0.3);
  }
}
@mixin bg30 {
  background-color: rgba($dark, 0.3);
  .dna-dark & {
    background-color: rgba($light, 0.3);
  }
}
@mixin bg40 {
  background-color: rgba($dark, 0.4);
  .dna-dark & {
    background-color: rgba($light, 0.4);
  }
}
@mixin bg50 {
  background-color: rgba($dark, 0.5);
  .dna-dark & {
    background-color: rgba($light, 0.6);
  }
}
@mixin bg60 {
  background-color: rgba($dark, 0.5);
  .dna-dark & {
    background-color: rgba($light, 0.6);
  }
}
@mixin bg70 {
  background-color: rgba($dark, 0.6);
  .dna-dark & {
    background-color: rgba($light, 0.7);
  }
}
@mixin bg80 {
  background-color: rgba($dark, 0.7);
  .dna-dark & {
    background-color: rgba($light, 0.8);
  }
}
@mixin bg90 {
  background-color: rgba($dark, 0.9);
  .dna-dark & {
    background-color: rgba($light, 0.9);
  }
}
@mixin bg95 {
  background-color: rgba($dark, 0.8);
  color: $light;

  .dna-dark & {
    background-color: rgba($light, 0.8);
    color: $dark;
  }
}
@mixin bg100() {
  background-color: rgba($dark, 1);
  color: $light !important;

  svg {
    .fill-me {
      stroke: white;
    }
  }
  svg.dna-icon-path {
    path {
      fill: white;
    }
  }

  .dna-dark & {
    background-color: rgba($light, 1);
    color: $dark !important;

    svg {
      .fill-me {
        stroke: $dark;
      }
    }
    svg.dna-icon-path {
      path {
        fill: $dark;
      }
    }
  }
  .dna-light & {
    background-color: rgba($dark, 1);
    color: $light;
  }
}
@mixin bgSolid {
  background-color: white;
  .dna-dark & {
    background-color: $bgDark;
  }
}
@mixin bgBlur {
  backdrop-filter: blur(2px);
  background-color: rgba(white, 0.85);
  .dna-dark & {
    background-color: rgba($dark, 0.85);
  }
}

@mixin border-dance {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: dna-border-dance 0.4s infinite linear;
}

@keyframes dna-border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

@mixin shadow-tile {
  box-shadow: 1px 1px 2px rgba($dark, 0.2);
  .dna-dark & {
    box-shadow: 2px 2px 4px rgba($light, 0.2);
  }
}

@mixin shadow {
  box-shadow: 1px 1px 10px rgba($dark, 0.2);
  .dna-dark & {
    box-shadow: 1px 1px 10px rgba($light, 0.2);
  }
}

@mixin zoom-link($opacity: 0.5, $scale: 1.06, $display: block) {
  cursor: pointer;
  opacity: $opacity;
  display: $display;
  transition: all 0.2s ease-in;
  &:hover {
    opacity: 1;
    transform: scale($scale);
  }
}

@mixin spin-it {
  border-width: 1px;
  border-style: dashed;
  border-radius: 50%;
  border-color: $dark;
  animation: spin-360 25s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: block;
    width: 130%;
    height: 130%;
    border: 10px solid rgba($dark, 0.1);
    z-index: -1;
    position: absolute;
    border-radius: 50%;
  }

  .dna-dark & {
    border-color: rgba($light, 0.5);
    &:before {
      border: 15px solid rgba($light, 0.15);
    }
  }
}

@mixin shake-it {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes spin-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(12px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-14px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(14px, 0, 0);
  }
}

@mixin week-item {
  padding: 5px;

  padding-left: 10px;
  color: $dark;
  background: rgba($light, 0.9);
  border-radius: 4px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  opacity: 1;
  height: 80px;
  box-shadow: 0 0 3px rgba($dark, 0.15);

  &:hover {
    opacity: 1;
    box-shadow: 1px 1px 6px rgba($dark, 0.2) !important;

    .react-resizable-handle {
      @include bg08;
    }

    .item-done-ask,
    .item-done-replan {
      transform: scale(1);
      opacity: 1;
      pointer-events: all;
    }
  }

  &.react-grid-item > .react-resizable-handle {
    position: absolute;
    bottom: auto;
    top: 0;
    height: 100%;
    width: 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-image: none !important;
    cursor: ew-resize;

    &:after {
      display: none !important;
    }
  }

  .act-title {
    text-align: left;
    font-size: 12px;
    user-select: none;
    display: inline-block;
    margin-bottom: 3px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 0.5;
    max-width: 200px;

    &:hover {
      opacity: 1;
    }

    &.is-reminder {
      font-family: "Courier New", Courier, "Lucida Sans Typewriter";
      margin-bottom: 3px;
      color: $dark;
      &:hover {
      }
    }
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    width: 100%;

    opacity: 0.8;
    line-height: 20px;
    user-select: none;
    transform: translate(0px, -5px);
    color: rgba($dark, 0.8);
    color: $dark;
  }
  .ai-item-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    height: 80%;
    max-width: 400px;
    width: 100%;
    z-index: 0;
    background-size: cover;
    border-bottom-left-radius: 5px;
    pointer-events: none;
    background-position: center;
    mask-image: radial-gradient(ellipse at bottom left, black 0%, transparent 50%);
  }

  &.dna-reminder-tile {
    .item-done-ask {
      top: 60px;
    }
    .item-done-replan {
      display: none;
    }
  }

  &.is-reminder {
    height: 90px;
    border-radius: 0;
    &.is-reminder {
      background: #f9f3c8 url(/img/noisy.png);
      color: $dark;
      box-shadow: 0 2px 3px rgba($dark, 0.2);
    }

    .ai-item-bg {
      display: none;
    }
  }

  .item-done-ask,
  .item-done-replan {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;

    opacity: 0;
    transform: scale(0.6);
    pointer-events: none;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.75) !important;
    svg {
      margin-top: 2px;
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .item-done-ask {
    width: 40px;
    height: 40px;
    z-index: 3;
    right: 12px;
    bottom: 2px;
  }

  .item-done-replan {
    z-index: 2;
    width: 30px;
    height: 30px;
    right: -4px;
    bottom: 38px;
    transition-delay: 0.3s !important;
    border: 2px solid silver;

    svg {
      margin-top: 6px;
      margin-left: 2px;
      opacity: 0.6;
    }
  }
}

//VARS
$steelBlue: #3c6ec7;
$blueGray: #3c4957;
$accent: #fd2a7d;
$blueAccent: #00f6ff;
$issueAccent: #fd2a7d;
$mint: #82ffb0;
$lime: #dfff00;
$error: red;
$dark: #181818;
$bgDark: #202021;
$issueColor: red;
$light: #ffffff;
$bgLight: #fafafa;
$blue: #3da8ff;
$lightOrange: #fff6e6;
$space: 50px;
$green: #b3ff75;

$gridCellBorder: rgba(33, 150, 243, 0.2);
$gridCellFocus: rgba(33, 150, 243, 0.1);
