.dna-progress-bar {
  width: 100%;
  position: relative;
  min-height: 1px;
  min-width: 2px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .dna-progress-count {
    height: inherit;

    background-color: $dark;
    border-radius: 2px;
    min-height: 1px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 6px 0px rgba($dark, 0.1);

    &:after {
      display: block;
      content: "";
      position: absolute;
      max-height: 1px;
      overflow: hidden;
      opacity: 0.3;
      transform: translateY(0px);
      height: 100%;
      width: 100%;

      min-height: 1px;
      background-color: inherit;
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      height: 1px;
      background-color: inherit;
      width: inherit;
      filter: blur(1px);
      opacity: 0.2;
      transform: translateY(0px);
      z-index: -1;
    }
  }

  .dna-progress-advance {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-1px);
    z-index: 9;
    min-height: 1px;
    position: relative;
    box-shadow: 0 0 3px 1px $blueAccent;
    background-color: $blueAccent;
    //filter:blur(2px);

    &.issue {
      box-shadow: 0 0 3px 2px rgba($accent, 0.5);
      background-color: $accent !important;
    }
  }

  &.issue {
    div {
      background-color: $issueColor !important;
      box-shadow: 0 0 5px 1px rgba($accent, 0.5) !important;
    }
  }

  h4 {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -22px);
    margin: 0;
    padding: 0;
    font-weight: 100;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    white-space: nowrap;
  }
}
.dna-light {
  .dna-progress-bar {
    .dna-progress-count {
      background-color: $dark;
      box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:after {
      background-color: $dark !important;
    }
  }
}
.dna-dark {
  .dna-progress-bar {
    .dna-progress-count {
      background-color: $light;
      box-shadow: 0 0 4px 0px rgba($light, 0.8);

      &:before {
        display: block;

        opacity: 0.7;
      }
    }
  }
}
