@import "../scss/vars";
$buttonHeight: 40px;
//
.dna-button {
  height: $buttonHeight;
  width: auto;
  display: inline-flex;
  border-radius: 3px;
  font-size: 13px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: rgba($dark, 0.1);
  padding: 0 18px;
  padding-top: 2px;
  box-shadow: 1px 1px 2px 0px rgba(black, 0.2);
  text-align: center;
  align-items: center;
  flex-grow: 0;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  flex-shrink: 0;
  user-select: none;
  font-weight: normal;
  &:hover:not(.dna-button-block):not(.fancy) {
    background: radial-gradient(circle at top left, rgba(205, 255, 169, 1) 0%, rgba(76, 255, 158, 1) 100%);
    box-shadow: 2px 2px 2px 0px rgba(darken(#4cff9e, 30%), 0.4);
    color: darken(#4cff9e, 40%);
    font-weight: normal;
    svg {
      color: green !important;
    }
  }

  &.fancy {
    border-radius: 40px;
    background: #833ab4; /* fallback for old browsers */

    background: radial-gradient(
      circle at left top,
      #851d81,
      #fd1d1d,
      #ff963b
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: white !important;
    box-shadow: 0 0 0 10px rgba(gray, 0.2);

    &:hover {
      box-shadow: 0 0 0 3px rgba(gold, 0.4);
      svg {
        color: white !important;
      }
    }

    &.blue {
      background: linear-gradient(
        to right,
        #ec6ead,
        #3494e6
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
  }

  &.has-svg {
    svg {
      transition: all 0.3s;
      margin-right: 6px;
    }

    &:hover:not(.has-animation):not(.no-flipping) {
      svg {
        transform: scaleX(-1);
      }
    }

    &.launch-me {
      svg {
        animation: x-button-icon-launch 4s infinite;
      }
    }
    &.flip-me {
      svg {
        animation: x-button-icon-flip 6s infinite;
      }
    }
  }

  svg {
    font-size: 18px;

    margin-right: 0px;
  }

  &.dna-danger-button {
    @include bg20;
    opacity: 0.5;
    box-shadow: unset;
    &:hover {
      background: radial-gradient(circle at bottom right, red, $accent) !important;
      color: white !important;
      box-shadow: 0 0 0 3px rgba(red, 0.2) !important;
      opacity: 1;
      box-shadow: none;
      svg {
        color: gold;
      }
    }
  }

  &.solid {
    @include bgSolid;
  }

  &.no-shadow {
    box-shadow: none !important;

    &:hover {
      box-shadow: 0 0 0 3px rgba($mint, 0.2) !important;
    }
  }
}

.dna-dark {
  .dna-button {
    background-color: rgba(white, 0.1);
    color: white;

    &.fancy {
      box-shadow: 0 0 0 10px rgba(white, 0.1);

      &:hover {
        box-shadow: 0 0 0 1px rgba(white, 0.1);
      }
    }
  }
}

.dna-button-disabled {
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
}

.dna-button-block {
  text-shadow: none;
  position: relative;
  buttonkid {
    position: relative;
    z-index: 1;
  }
  &:hover {
    color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    &::after {
      left: 0px;
      transform: skewX(-45deg) translateX(50%) translateY(0);
    }
    &::before {
      left: 1%;
      transform: skewX(-45deg) translateX(-49%) translateY(0);
    }
  }
  &::after {
    content: "";
    transition: all 0.4s;
    display: block;
    width: 100%;
    z-index: 0;
    height: 100%;
    transform: skewX(-45deg) translateX(100%) translateY(-$buttonHeight);
    background-color: $dark;
    position: absolute;
    top: 0;
    left: 0px;
  }
  &::before {
    content: "";
    display: block;
    transition: all 0.4s;
    width: 100%;
    z-index: 0;
    height: 100%;
    transform: skewX(-45deg) translateX(-100%) translateY($buttonHeight);
    background-color: $dark;
    position: absolute;
    top: 0;
    left: 0px;
  }
}

.dna-dark {
  .dna-button-block {
    &:hover {
      color: black;
      box-shadow: 0 0 20px rgba(white, 0.5);
    }
    &::after {
      background-color: white;
    }
    &::before {
      background-color: white;
    }
  }
}

.dna-button.dna-red-button,
.dna-dark .dna-button.dna-red-button {
  background-color: #ff0044;
  color: white;
  font-weight: normal;
  box-shadow: 1px 1px 1px 0px rgba(black, 0.4);
}

.dna-orange-button {
  background: $orange;
  color: white;
  &:hover {
    background: lighten($orange, 0.5);
  }
}

.dna-button.dna-button-clear {
  background-color: transparent;
  box-shadow: none;
}

.dna-dark-button {
  background-color: black;
  color: white;
  box-shadow: 2px 2px 0 0px rgba(black, 0.2);
}
.dna-dark {
  .dna-dark-button {
    background-color: white;
    color: $dark;
  }
}

.dna-button.dna-lime-button {
  background-color: $lime;
  color: $dark !important;
  &:hover {
    color: $dark !important;
  }
}

.dna-confirm-button {
  min-width: 120px;

  &.confirm-it {
    box-shadow: 3px 3px 0 0px rgba(black, 1) !important;

    .dna-button {
      transform: translateY(0);

      &:hover {
        background: red !important;
        color: white !important;
      }
    }
  }

  > .dna-button {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: $accent !important;
    text-shadow: none !important;
    color: white !important;
    height: inherit !important;
    transform: translateY(102%);
  }
}

.dna-button.dna-mint-button:not(.dna-button-block):not(.fancy),
.dna-dark .dna-button.dna-mint-button:not(.dna-button-block):not(.fancy) {
  background: radial-gradient(circle at top left, rgba(205, 255, 169, 1) 0%, rgba(76, 255, 158, 1) 100%);
  box-shadow: 2px 2px 2px 0px rgba(darken(#4cff9e, 30%), 0.4);
  color: darken(#4cff9e, 40%);
  font-weight: normal;
  svg {
    color: green;
  }
  &:hover {
    background: radial-gradient(circle at top left, rgba(122, 188, 255, 1) 0%, rgba(68, 255, 161, 1) 100%) !important;
    box-shadow: 2px 2px 2px 0px rgba(aqua, 0.4) !important;
    color: white !important;

    svg {
      color: #d9f7fc !important;
    }
  }

  &.no-shadow {
    box-shadow: none !important;

    &:hover {
      box-shadow: 0 0 0 3px rgba($mint, 0.4) !important;
    }
  }
}

@keyframes x-button-icon-launch {
  0% {
    transform: translateY(30px);
  }

  10% {
    transform: translateY(30px);
  }
  45% {
    transform: translateY(-3px);
  }

  65% {
    transform: translateY(5px);
  }

  95% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes x-button-icon-flip {
  0% {
    transform: scaleX(1);
  }
  70% {
    transform: scaleX(1);
  }
  71% {
    transform: scaleX(-1);
  }
  81% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(-1);
  }
}

.x-blue-round-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $blue;
  transition: all 0.3s;
  color: white;
  box-shadow: 0 0 0 4px rgba($blue, 0.2);
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: $orange;
    box-shadow: 0 0 0 6px rgba($orange, 0.3);
  }

  &.add {
    width: auto;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
