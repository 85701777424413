@import "../scss/vars.scss";

.x-hive {
  display: flex;
  flex-wrap: nowrap;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(black, 0.3);
    transform: rotate(45deg);
    transition: all 0.3s;
    align-self: flex-start;
    flex-grow: 0;
    flex-shrink: 0;

    &:hover {
      transform: scale(1.2) rotate(45deg) !important;
      box-shadow: 2px 2px 3px rgba(black, 0.4);
      opacity: 1 !important;
      filter: blur(0px) !important;
    }
    div {
      background-size: cover;
      overflow: hidden;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: inherit;
        background-color: gray;
        height: inherit;
        background-image: inherit;
        background-size: cover;
        transform: rotate(-45deg);
        margin-left: -12%;
        margin-top: 1%;
      }

      span {
        position: absolute;
        z-index: 1;
        display: block;
        width: inherit;
        height: inherit;

        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-45deg) translate(-15%, -10%);
        color: white;
        opacity: 0.9;
        font-weight: normal;

        text-shadow: 1px 1px 1px #333;
      }
    }

    &.pending {
      div {
        background-color: $accent;
        mix-blend-mode: luminosity;
        span {
          background-color: $accent;
        }
      }
    }
  }
}

.x-hive-details {
  min-width: 380px;
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  text-align: left;

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 3px;
    font-size: 13px;
  }
}

.x-hive-plus {
  max-height: 70vh;
  overflow: auto;
}
