.x-ra-new {
  font-size: 12px;
  h3 {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
  }
}

.dna-round-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;

  &.x-avatar-mint-border {
    box-shadow: 0 0 0 5px rgba($mint, 0.8) !important;
  }
  &.x-avatar-orange-border {
    box-shadow: 0 0 0 5px rgba(orange, 0.6) !important;
  }

  &.smaller {
    width: 30px;
    height: 30px;
  }
  &.small {
    width: 20px;
    height: 20px;
  }
  &.large {
    width: 48px;
    height: 48px;
  }
  &.huge {
    width: 80px;
    height: 80px;
  }
}

.inset-avatar {
  border: 2px solid #f5f5f5;
  transform: translate(-18px, 16px);
  box-shadow: 0 2px 3px rgba(black, 0.2);

  max-width: 25px;
  max-height: 25px;
}

.dna-avatar {
  transform: rotate(-45deg);
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 35px;
  height: 35px;
  cursor: pointer;
  opacity: 1;
  border-radius: 5px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);

  &:hover {
    transform: rotate(-45deg) scale(1.1);
  }

  &:hover {
    &:hover {
      .dna-svg-icon {
        filter: none;
      }
    }
  }

  .dna-avatar-inner {
    position: absolute;
    overflow: hidden;
    @include bg100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    div.dna-avatar-img {
      transform: rotate(45deg);
      width: 140%;
      height: 140%;
      background-size: cover;
      display: block;
      position: absolute;
    }
  }

  &.active {
    opacity: 1;
  }

  &.color {
    .dna-avatar-inner {
      div.dna-avatar-img {
        filter: none;
      }
    }
  }
  &.smallest {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
  &.tiny {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  &.small {
    width: 30px;
    height: 30px;
  }
  &.large {
    width: 48px;
    height: 48px;
  }
  &.huge {
    width: 80px;
    height: 80px;
  }
}

.dna-icon-x {
  margin-left: 3px;
}

//TOOLTIP *****************************************

.tippy-tooltip.dna-avatar-tip-theme {
  min-height: 150px;
  border-radius: 3px;
  width: 390px;

  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  z-index: 99999;
  background: rgba($dark, 0.97);

  .av-remove-trigger {
    clear: both;
    width: 100%;
    white-space: nowrap;
    overflow: visible;
    padding: 3px;
  }

  a {
    cursor: pointer;
    color: $light !important;
    text-shadow: 0 0 12px #ffffff;
  }
  .dna-avatar-remove-trigger {
    color: $accent;
    display: block;
    font-weight: bold;
    padding: 2px 4px 2px 4px;
    text-shadow: 0 0 3px rgba(pink, 0.5), 0 0 5px rgba(white, 0.3);
    margin: 20px 0 20px 0;

    font-size: 12px !important;
    font-weight: normal;
    transform: translateX(-5px) !important;
    clear: both;
    float: left;
    transition: none;
    cursor: pointer;

    &:hover {
      background-color: yellow;
      color: black;
      text-shadow: none;
    }
  }
  .dna-icon-x {
    float: left;
    transform: translate(-7px, 4px);
    path {
      fill: $light;
    }
  }
}

.dna-avatar-details {
  position: relative;

  h3 {
    margin-bottom: 5px;
    min-width: 180px;
    margin-top: 5px;
    text-transform: capitalize;
  }

  .dna-clear {
    overflow: hidden;
  }

  .dna-avatar {
    position: absolute;
    left: 20px;
    top: 20px;
    opacity: 1;
  }
  .dna-round-avatar {
    left: 10px;
    top: 10px;
    position: absolute;
  }

  .x-av-remove-link {
    display: block;
    margin-top: 20px;
    color: gold;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;

    &:hover {
      color: $accent;
    }
  }

  .dna-avatar-detail-contents {
    position: relative;
    margin-left: 85px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding-bottom: 10px;
    padding-right: 21px;
    padding-right: 20px;

    div {
      float: left;
      display: inline-block;

      h3 {
        margin-top: 5px;
        font-weight: normal;
        white-space: nowrap;
      }
      label {
        margin: 5px 0 10px 0;
        font-weight: 100;
        font-size: 14px;
      }
      a {
        display: block;
        text-decoration: none;
      }
    }
  }
  .dna-avatar-msg {
    background-color: rgba(silver, 0.2);
    border-radius: 6px;

    font-size: 14px;
    clear: both;
    margin-top: 10px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 8px;
    padding-bottom: 5px;
    transform: translateX(-5px);
  }
  .dna-left:last-child {
  }
  overflow: hidden;

  label,
  h3,
  span,
  p,
  div {
    color: $light;
  }
}

.dna-light {
  .dna-avatar {
    box-shadow: 0px 0px 6px rgba($dark, 0.4) !important;
  }
}

.dna-dark {
  .tippy-tooltip.dna-avatar-tip-theme {
    box-shadow: 1px 1px 20px rgba($light, 0.4);
  }
  .dna-avatar {
    box-shadow: 0px 0px 10px rgba($light, 0.6);

    .dna-avatar-inner {
      .dna-svg-icon {
        fill: $dark;
      }
    }
  }
}

.dna-now-theme {
  .dna-avatar {
    transform: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    filter: none;
    .dna-avatar-inner {
      width: 45px;
      height: 45px;
      transform: none;
      border-radius: 50%;
    }
    &.large {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      .dna-avatar-inner {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
}

.dna-avatar-round {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  filter: none;
  background-size: cover;
  background-position: center center;
}

.x-avatar-issue {
  box-shadow: 0 0 5px 1px rgba($accent, 0.3);

  .dna-avatar-img {
    background-color: $accent;
    background-blend-mode: luminosity;
  }
  &.dna-round-avatar {
    background-color: $accent;
    background-blend-mode: luminosity;
  }
}
.x-avatar-warning {
  box-shadow: 0 0 5px 1px rgba(orange, 0.3);

  .dna-avatar-img {
    background-color: orange;
    background-blend-mode: luminosity;
  }
  &.dna-round-avatar {
    background-color: orange;
    background-blend-mode: luminosity;
  }
}
