$fontSize: 14px;
.DayPicker {
  &.DayPicker__withBorder {
    box-shadow: 0 -1px 1px rgba($dark, 0.2), 0 1px 2px rgba($dark, 0.4), 0 3px 6px rgba($dark, 0.3);
    border-radius: 5px;
  }
  .CalendarDay {
    &:hover {
      background: $lime !important;
      color: black !important;
    }

    &.CalendarDay__blocked_calendar {
      pointer-events: none !important;
      border-color: rgba(gray, 0.2) !important;
      background-color: rgba(gray, 0.1) !important;
      color: rgba(gray, 0.3);
    }
    &.CalendarDay__blocked_out_of_range {
      filter: blur(1px);
      pointer-events: none !important;
      @include bg03;
    }
    &.CalendarDay__today {
      background-color: $lime;
      color: black;
    }
  }
}

.dna-dark {
  .DayPicker {
    background: $bgDark;
  }
  .DayPicker_transitionContainer {
    background: $bgDark !important;
  }
  .DateInput_fang {
    path : {
      fill: $bgDark !important;
    }
  }
  .DateRangePickerInput {
    background: $bgDark;
    border-color: #333;
    input {
      //background: $bgDark;
      &::placeholder {
        color: #fafafa;
      }
    }
  }
  .DateRangePickerInput_arrow_svg {
    path {
      color: silver;
      fill: silver;
    }
  }
}
$drIw: 160px;
$drAw: 17px;
.DateRangePicker {
  width: ($drIw * 2) + $drAw + 3px;

  //  transform: translateY(-1px) !important;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;

  .DateRangePickerInput {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap !important;
  }

  .DateInput {
    width: $drIw;
    border-bottom: 1px solid rgba(black, 0);
  }
  .DateInput_input {
    font-size: inherit;
    width: $drIw;
    padding: 0;

    //border-bottom: 1px solid rgba(black, 0);
    padding: 8px 10px 8px 5px;

    &:focus {
      background-color: rgba($lime, 0.3);
    }

    &::placeholder {
      //color: black;
    }
  }
  .DateRangePickerInput_arrow {
    width: $drAw;

    svg {
      display: block;
      margin-left: 0px;

      transform: translate(-1px, 0px) scale(0.5);
    }
  }
  .DateRangePickerInput {
    width: 100%;
    background: inherit;
  }
  .DateInput_input__focused {
    border-bottom: inherit;
  }
}

#x-ps-start:focus,
#x-ps-end:focus {
  background: $orange;

  color: white !important;
  &::placeholder {
    color: rgba(white, 0.5) !important;
  }
}

.dna-dark {
  .DayPicker {
    &.DayPicker__withBorder {
      box-shadow: 0 3px 7px rgba(white, 0.3);
      border-radius: 5px;
    }
    .CalendarDay {
      background-color: #444;
      border-color: #222;
      color: #ccc;

      &.CalendarDay__blocked_out_of_range {
        opacity: 0.4;
        filter: blur(1px);
      }

      &.CalendarDay__hovered_span {
        background-color: rgba(black, 1) !important;
        border-color: rgba(gray, 0.5) !important;
        color: silver;
      }
      &.CalendarDay__selected_span {
        background-color: rgba(white, 0.4);
        border-color: rgba(white, 0.1);
      }
      &.CalendarDay__blocked_calendar {
        pointer-events: none !important;
        border-color: rgba(gray, 0.2) !important;
        background-color: rgba(white, 0.1) !important;
        color: rgba(#ccc, 0.3);
      }
      &.CalendarDay__blocked_out_of_range {
        pointer-events: none !important;
        filter: blur(1px);
      }
      &.CalendarDay__today {
        background-color: $mint;
        color: #222;
      }

      &:hover {
        background: $lime !important;
        color: black !important;
        border-color: $lime !important;
      }
    }

    .DayPickerNavigation_button {
      background: $lime;
    }

    .CalendarDay__selected {
      background-color: $lime;
      border-color: $lime;
      color: black;
    }
  }
}

.react-date-picker {
  height: 40px;
  border-radius: 3px;

  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }

  .react-date-picker__inputGroup__divider {
    transform: translateY(1px);
  }

  .react-date-picker__wrapper {
    width: 100%;
  }

  .react-calendar__tile--now {
    @include bg20;
  }

  &.x-mini-picker {
    //max-width: 120px;

    select {
      max-width: 25px;
    }
    .react-date-picker__inputGroup__divider {
      display: none;
    }
    input {
      padding: 0;
      max-width: 16px;

      &:last-child {
        max-width: 37px;
        padding-right: 3px;
      }
    }
  }

  .react-date-picker__inputGroup__day,
  .react-date-picker__inputGroup__year {
    pointer-events: none;
  }

  select {
    pointer-events: none;
    outline: none;
    border-radius: 0;
    border: 0 !important;
    -webkit-appearance: none;
    font-size: $fontSize;
    font-family: "MessinaSans", sans-serif;
    color: inherit;
    text-align: center;
    option {
      color: inherit;
      font-size: 10px !important;
      font-family: "MessinaSans", sans-serif;
    }
  }
}

.dna-dark .react-calendar {
  box-shadow: 0px 2px 8px 3px rgba(white, 0.3), 0px 0px 2px 2px rgba(white, 0.1);
}
.react-calendar {
  background-image: url(/img/noisy.png);
  border-radius: 8px;
  border: 0 !important;
  box-shadow: 0 2px 5px rgba(#666, 0.3);
  font-family: "MessinaSans", sans-serif !important;

  .react-calendar__tile--now {
    background: $blue;
    color: white;
  }

  .react-calendar__navigation {
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .react-calendar__tile {
    border-radius: 5px;

    &:disabled {
      @include bg05;
      border-radius: 0;
      filter: blur(1px);
      color: #999 !important;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: #999 !important;
  }
  .react-calendar__tile--active {
    position: relative;
    background-color: $mint;

    abbr {
      position: relative;
      z-index: 9;
      color: #333;
    }
  }

  .react-calendar__tile--now {
    position: relative;
    @include bg20;
  }

  * {
    font-family: "MessinaSans", sans-serif !important;
  }
}

.react-date-picker__wrapper {
  border-radius: 3px;
  padding-left: 0px;
  padding-right: 0px;
  border-color: rgba(silver, 0.6) !important;

  svg {
    transform: scale(0.9);
  }
  .react-date-picker__calendar-button {
    transform: scale(0.8);
  }

  input {
    background: none !important;
    padding: 0px;
    padding-right: 1px;
  }

  .react-date-picker__clear-button {
    padding-right: 0;
  }
  .react-date-picker__button {
    padding: 4px 2px 4px 0px;
  }
  button {
    outline: none;
  }
}

.react-calendar__viewContainer {
  .react-calendar__month-view__weekdays__weekday abbr {
    color: $dark !important;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
  }
}

.react-date-picker__calendar,
.react-calendar {
  width: 310px !important;
  min-width: 310px !important;
  padding: 0;
  min-height: 320px;
  margin: 0;
}

.dna-dark {
  .react-date-picker__wrapper {
    svg {
      rect,
      line {
        stroke: $light;
      }
    }
  }
}

.react-date-picker__inputGroup {
  input.react-date-picker__inputGroup__input {
    width: 22px !important;
    text-align: right;
    font-size: $fontSize;
  }
  .react-date-picker__inputGroup__month {
    margin-left: 3px;
  }
  input.react-date-picker__inputGroup__year {
    width: 45px !important;
  }
}
.react-date-picker__calendar--open {
  z-index: 99909 !important;
}
