.recharts-pie-sector path {
  stroke-width: 0 !important;
}

.recharts-wrapper {
  text,
  tspan,
  text tspan {
    font-size: 9px;
    text-transform: uppercase;
  }
}

.recharts-default-tooltip {
  box-shadow: 0 2px 3px rgba(black, 0.7);

  * {
    color: white;
  }
  border: 0 !important;
  border-radius: 10px !important;
  background: #333 !important;
}

.x-mx-length {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
