@import "../scss/vars.scss";

.x-search-drop-down {
  position: relative;
  z-index: 2;
  display: flex;
  &.x-sd-multi {
    .dna-dd-item {
      padding-left: 30px;
      position: relative;
    }
  }

  input[type="checkbox"] {
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(3px, -3px);
  }

  .dna-middle-flex {
    input[type="checkbox"] {
      position: absolute;
      top: 50%;

      transform: translateY(-50%);
    }
  }

  &.has-clear-icon {
    z-index: 0;

    input:not([type="checkbox"]) {
      padding-right: 50px;
      position: relative;
      z-index: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dna-search-dd-clear {
  position: absolute;
  z-index: 999999999999;
  width: 20px;
  height: 20px;
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  @include bg05;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  pointer-events: all;

  svg {
    pointer-events: none;
    opacity: 0.4;
    transform: translateY(2px);
  }
  &:hover {
    background-color: red;
    color: white;
    opacity: 1;
    transform: translateY(-50%) scale(1.2);
    svg {
      opacity: 1;
    }
  }
}
