.dna-drive-view--document {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 30ms ease;
  background-color: white;
  border-radius: 3px;
  overflow: hidden;

  object {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-is-hidden {
    opacity: 0;
  }

}