@import "../scss/vars.scss";

.dna-dark .dna-home,
.dna-home {
  .x-home-nav.dna-dark {
    li {
      background-color: rgba(white, 0.15);
    }
  }
}

.dna-home {
  min-height: 100vh;

  @include dna-section("up");
  .dna-show-home & {
    @include dna-show-section;
  }

  .x-home-scroll {
    height: calc(100vh - 150px);
    margin-top: 80px;

    .simplebar-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      min-height: 400px;
    }
  }

  .x-home-nav {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 13px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;

    li {
      background-color: rgba($dark, 0.1);
      backdrop-filter: 4px;
      padding: 8px 18px 7px 16px;
      border-radius: 20px;
      margin: 0 5px;
      margin-top: 1px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      svg {
        transform: translateY(2px);
        margin-right: 2px;
      }
      &.active {
        @include x-grad;
        color: white;
      }
      &:hover:not(.active) {
        @include bg100;
      }
    }
  }

  .dna-ai-talk {
    transform: translateY(30px);
  }

  .x-work-ai {
    display: block;
    position: relative;

    //transform: translate(-50%, -50%);

    > li {
      position: absolute;
    }
  }

  .x-home-intro {
    position: relative;
    background-color: $bgDark;
    border-radius: 5px;
    position: fixed;
    z-index: 10;
    padding: 10px;
    width: 205px;
    box-shadow: 2px 2px 5px rgba(black, 0.4);
    color: white;

    .dna-button {
      width: 100%;
    }
    &.nav {
      left: 50px;
      top: 80px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-bottom-color: $bgDark;
        border-top: 0px;

        margin-left: -70px;
        margin-top: -10px;
      }
    }
    &.admin {
      right: 120px;
      top: 80px;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-left-color: $bgDark;
        border-right: 0;

        margin-top: -47px;
        margin-right: -12px;
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .dna-home {
    .x-home-scroll {
      height: calc(100vh - 80px);
    }
  }
}

.dna-home-xx {
  min-height: 100vh;

  .dna-modal {
    opacity: 0.6;
  }

  @include dna-section("up");
  > .dna-scroll-body {
    min-height: 700px;
  }

  .x-home-nav {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 13px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      @include bg05;

      padding: 5px 18px 4px 18px;
      border-radius: 20px;
      margin: 0 3px;
      margin-top: 1px;
      text-align: center;
      cursor: pointer;
      svg {
        transform: translateY(2px);
      }
      &.active {
        @include x-grad;
        color: white;
      }
      &:hover:not(.active) {
        @include bg100;
      }
    }
  }

  .dna-quick-task-trigger {
    position: fixed;
    left: 25px;
    top: 100px;
    z-index: 5;
    display: flex;
    justify-content: center;

    transition: all 0.3s;

    input {
      transition: all 0.3s ease-out;
      width: 80px;
      flex-grow: 0;
      margin-right: 10px;
      display: inline-block;
      align-self: flex-start;
    }
    .day-balls {
      margin-right: 10px;
      margin-left: 5px;
      margin-top: 5px;
      li {
        cursor: pointer;
        margin-bottom: 7px;
        display: block;
        opacity: 0;
        width: 25px;
        height: 25px;
        font-size: 12px;
        line-height: 25px;
        text-align: center;
        transform: translateY(-50px);
        border-radius: 50%;
        @include bg05;

        &:hover {
          @include bg10;
        }
        &.selected {
          background-color: $lime;
        }
        @for $i from 1 through 10 {
          &:nth-child(#{$i + 1}) {
            transition: opacity 0.15s ease ($i * 0.1s), transform 0.15s ease-out ($i * 0.1s), background 0.1s;
          }
        }
      }
    }
    .mission-balls {
      display: flex;
      flex-direction: column;
      margin-top: 0px;

      .in-act-ball {
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;

        display: flex;
        cursor: pointer;
        padding-right: 20px;
        padding-left: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 30px;
        margin-bottom: 4px;
        &:hover {
          @include bg05;
        }
        .dna-round-avatar {
          width: 30px;
          height: 30px;
          margin-bottom: 0px;
          cursor: pointer;
        }
        svg {
          margin-left: 3px;
        }
        span {
          display: block;
          max-width: 200px;
          margin-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 30px;
          overflow: hidden;
          transform: translateY(0px);
        }

        @for $i from 1 through 10 {
          &:nth-child(#{$i + 1}) {
            transition: opacity 0.2s ease ($i * 0.15s), transform 0.2s ease-out ($i * 0.15s), background 0.2s;
          }
        }
      }
    }

    .in-act {
      opacity: 0;
      transform: translateX(-100px);
      pointer-events: none;
      transition: all 0.3s;
    }

    &.open {
      .task-field {
        width: 300px;
      }

      .day-balls {
        li {
          transform: translateX(0);
          opacity: 1;
          pointer-events: all;
        }
      }

      .in-act {
        transform: translateX(0);
        opacity: 1;
        pointer-events: all;
      }
      .in-act-ball {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .dna-action-details-card {
    filter: none !important;
  }

  .home-date {
    position: absolute;
    right: 90px;
    top: 35px;
    opacity: 0.5;
    pointer-events: none;
  }

  &.dna-home-zoom-out {
    > h2,
    .dna-action-tile,
    > .dna-theme-picker {
      filter: blur(6px);
      transform: scale(0.8);
    }
  }

  .dna-go-to-board {
    position: fixed;
    bottom: 5px;
    width: 100px;
    @include zoom-link;
    font-size: 14px;
    left: 50%;
    margin-left: -50px;
    span {
      display: inline-block;
      transform: translateY(3px);
    }
    .dna-svg-icon {
      display: inline-block;
      transform: rotate(90deg);
    }
  }

  &.is-collapsed {
    .dna-home-mkh {
      max-height: 50px;
      .mkh-header-bar {
        span {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.catch-theme {
  background-image: url("/themes/anglers.png"), url("/themes/catch.svg");
  background-size: 50vh, 200px;
  background-repeat: no-repeat;
  background-position: right 70px center, left 10px top 110px;
}

.stars-theme {
  background-image: url("/themes/temp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-theme {
  --y-0: 0%;
  --x-0: 98%;
  --c-0: hsla(343, 100%, 46%, 0.5);
  --x-1: 116%;
  --y-1: 31%;
  --c-1: hsla(331.2, 52%, 37%, 1);
  --y-2: 2%;
  --x-2: 32%;
  --c-2: hsla(340, 100%, 46%, 1);
  --y-3: 7%;
  --c-3: hsla(237, 54%, 26%);
  --x-3: 54%;
  --x-4: 90%;
  --y-4: 28%;
  --c-4: hsla(322, 91%, 54%, 1);
  background-color: hsla(232, 35%, 18%, 1);
  background-image: radial-gradient(
      circle at var(--x-0) var(--y-0),
      var(--c-0) var(--s-start-0),
      transparent var(--s-end-0)
    ),
    radial-gradient(circle at var(--x-1) var(--y-1), var(--c-1) var(--s-start-1), transparent var(--s-end-1)),
    radial-gradient(circle at var(--x-2) var(--y-2), var(--c-2) var(--s-start-2), transparent var(--s-end-2)),
    radial-gradient(circle at var(--x-3) var(--y-3), var(--c-3) var(--s-start-3), transparent var(--s-end-3)),
    radial-gradient(circle at var(--x-4) var(--y-4), var(--c-4) var(--s-start-4), transparent var(--s-end-4));
  animation: hero-gradient-animation 10s linear infinite alternate;
  background-blend-mode: normal, normal, normal, normal, normal;
}

.peak-theme {
  background-image: url("/themes/peak-performance.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.japan-theme {
  background-image: url("/themes/japan.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banksy-theme {
  background-color: #bdb9ae;
  background-image: url("/themes/peace-2.png"), url("/themes/the-wall.jpg");
  background-size: 400px, cover;
  background-repeat: no-repeat;
  background-position: left 160px bottom 3px, center;
}

.dna-dark {
  .banksy-theme {
    background-image: url("/themes/peace-2.png"), url("/themes/the-wall-dark.jpg");
  }
}

.retro-tech-theme {
  background-color: #aaa;
  background-image: url("/themes/comrad-64.png");
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: left 160px bottom 60px;
}

.pool-days-theme {
  background-image: url("/img/astro-pool.svg"), url("/img/logo-x-color.svg");
  background-color: #95c4fc;
  background-size: 150px, 140vh;
  background-repeat: no-repeat;
  background-position: right 12vw top 16vh, left 30vw bottom -120vh;
}

.x-ev-theme {
  background-image: url("/themes/pyra-x.png");
  background-size: 40vw;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.x-fish-theme {
  background-image: url("/themes/fish.png");
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: left 240px bottom 70px;
}

.x-peace-theme {
  background-image: url("/themes/peace.png");
  background-size: 280px;
  background-repeat: no-repeat;
  background-position: left 200px bottom 20px;
}

.dna-dark {
  .x-peace-theme {
    background-image: url("/themes/peace-and-light.png");
  }
}

.x0-theme {
  background-image: url("/themes/comrad-64.png");
  background-size: 35vw;
  background-repeat: no-repeat;
  background-position: left 120px bottom 40px;
}

.x1-theme {
  background-image: url("/img/astro-pool.svg"), url("/img/logo-x-color.svg");
  background-color: #95c4fc;
  background-size: 150px, 140vh;
  background-repeat: no-repeat;
  background-position: right 12vw top 16vh, left 30vw bottom -120vh;
}
.x2-theme {
  background-image: url("/themes/yummy.png");
  background-repeat: repeat-y;
  background-position: top 0px right 220px;
  background-size: 110px;
}
.x3-theme {
  background-image: url("/themes/mommy.jpg");
  background-size: cover;
  background-position: center;
}
.x4-theme {
  background-image: url("/themes/really.png");
  background-size: 30vh;
  background-position: right 50px bottom 100px;
  background-repeat: no-repeat;
}

.dna-dark {
  .x3-theme {
    background-image: url("/themes/mommy-too.jpg");
  }
}

.dna-home-themes {
  display: inline-flex;
  position: fixed;
  bottom: 22px;
  right: 70px;
  z-index: 8;
  align-items: center;

  li {
    width: 22px;
    //border: 1px solid rgba(gray, 0.3);
    height: 22px;
    border-radius: 50%;
    margin: 0 3px;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;

    //background-image: url("/img/org-no-logo.png");

    &.catch-thumb {
      background-image: url("/themes/anglers-thumb.jpg");
      width: 50px;
      height: 50px;
    }

    &:first-child {
      @include bg100;
      background-image: none;
    }

    &:hover {
      transform: scale(2);
      box-shadow: 0 0 0 3px $mint;
    }
  }
}

.dna-dark {
  .dna-home {
    .dna-home-header {
      li,
      .home-week-menu {
        //text-shadow: 0 0 5px rgba($dark,0.6);
      }

      .home-week-menu {
      }
    }

    .dna-home-mkh {
      background: url(/img/noisy.png) rgba($dark, 0.3);
      &:hover {
        background: url(/img/noisy.png) rgba($dark, 0.5);
      }
    }
    span.tt-text {
      mix-blend-mode: difference !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .dna-home {
    .dna-theme-picker {
      max-width: 270px;
      bottom: 10px;
      right: 0;
      li {
        margin-top: 4px !important;
        display: inline-block;
      }
    }
  }
}

.home-approve-leaves {
  padding: 30px;
  padding-top: 10px;

  .mission-header-row {
    margin-top: 25px;
    border-bottom: 1px solid #ccc;
    @include bg05;
    padding-top: 4px;
  }
  .person-header {
    box-shadow: 0px 2px 3px -2px rgba(black, 0.1);
    padding: 5px 0;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .leave-impact {
    padding: 20px;
    padding-bottom: 30px;
    @include bg05;

    .leave-impact-dates {
      padding: 10px;
      @include bg05;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      > div:first-child {
        padding-top: 5px;
      }
    }
    label {
      margin-bottom: 15px;
      font-weight: normal;
      margin-top: 10px;
    }
    .leave-impact-mission {
      margin-bottom: 5px;
      .dna-round-avatar {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        align-items: center;
      }
    }
  }

  .dna-check-boxes {
    @include bg05;
    padding: 10px;
    border-radius: 10px;
    padding-right: 20px;
    padding-left: 20px;

    &.approved {
      background-color: $mint;
    }
    &.rejected {
      background-color: $accent;
    }
  }
}

.egg-shells {
  position: fixed;
  bottom: 13px;
  font-size: 16px;
  right: 85px;
  display: flex;

  li {
    transition: all 0.3s ease-out;
    cursor: crosshair;

    &:nth-child(even) {
      transform: translateY(-20px);
      &:hover {
        color: orange;
        transform: translateY(-20px) scale(2);
      }
    }

    &:hover {
      color: orange;
      transform: scale(1.6);
    }
  }
}

@keyframes hero-gradient-animation {
  0% {
    --y-0: 0%;
    --x-0: 98%;
    --s-start-0: 5%;
    --s-end-0: 61.257148618684276%;
    --c-0: hsla(339, 100%, 46%, 0.5);
    --x-1: 46%;
    --y-1: 11%;
    --c-1: hsla(331.2, 52%, 37%, 0.4);
    --s-start-1: 9%;
    --s-end-1: 55%;
    --y-2: 82%;
    --x-2: 32%;
    --s-start-2: 5%;
    --s-end-2: 52%;
    --c-2: hsla(32.635983263598334, 100%, 46%, 0.6);
    --y-3: 7%;
    --c-3: hsla(237.23076923076923, 38%, 32%, 0.5);
    --s-start-3: 13%;
    --s-end-3: 68%;
    --x-3: 14%;
    --x-4: 10%;
    --y-4: 88%;
    --s-start-4: 8.828155981128067%;
    --s-end-4: 21.32735517429097%;
    --c-4: hsla(37.42857142857143, 91%, 54%, 0.5);
  }

  100% {
    --y-0: 1%;
    --x-0: 1%;
    --s-start-0: 5%;
    --s-end-0: 49.62023409285044%;
    --c-0: hsla(37.42857142857143, 91%, 54%, 0.6);
    --x-1: -4%;
    --y-1: 31%;
    --c-1: hsla(331.2, 52%, 37%, 0.4);
    --s-start-1: 9%;
    --s-end-1: 44.98440985376909%;
    --y-2: 5%;
    --x-2: 106%;
    --s-start-2: 5%;
    --s-end-2: 52%;
    --c-2: hsla(32.635983263598334, 100%, 46%, 0.5);
    --y-3: 12%;
    --c-3: hsla(237.23076923076923, 38%, 32%, 1);
    --s-start-3: 13%;
    --s-end-3: 68%;
    --x-3: 95%;
    --x-4: 30%;
    --y-4: 5%;
    --s-start-4: 13%;
    --s-end-4: 43.79640862337571%;
    --c-4: hsla(37.42857142857143, 91%, 54%, 0.6);
  }
}

@property --y-0 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --x-0 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 98%;
}

@property --s-start-0 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 5%;
}

@property --s-end-0 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 61.257148618684276%;
}

@property --c-0 {
  syntax: "<color>";
  inherits: false;
  initial-value: hsla(32.63598326359833, 100%, 46%, 1);
}

@property --x-1 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 116%;
}

@property --y-1 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 61%;
}

@property --c-1 {
  syntax: "<color>";
  inherits: false;
  initial-value: hsla(331.2, 52%, 37%, 1);
}

@property --s-start-1 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 9%;
}

@property --s-end-1 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 55%;
}

@property --y-2 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 2%;
}

@property --x-2 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 32%;
}

@property --s-start-2 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 5%;
}

@property --s-end-2 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 52%;
}

@property --c-2 {
  syntax: "<color>";
  inherits: false;
  initial-value: hsla(32.635983263598334, 100%, 46%, 1);
}

@property --y-3 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 7%;
}

@property --c-3 {
  syntax: "<color>";
  inherits: false;
  initial-value: hsla(237.23076923076923, 38%, 32%, 1);
}

@property --s-start-3 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 13%;
}

@property --s-end-3 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 68%;
}

@property --x-3 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 24%;
}

@property --x-4 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 60%;
}

@property --y-4 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 58%;
}

@property --s-start-4 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 8.828155981128067%;
}

@property --s-end-4 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 41.32735517429097%;
}

@property --c-4 {
  syntax: "<color>";
  inherits: false;
  initial-value: hsla(37.42857142857143, 91%, 54%, 1);
}
