@import "../scss/vars.scss";
.x-model-for-prop {
  border: 1px solid red;
  padding: 12px 12px;
  color: red;
  font-size: 12px;

  display: inline-block;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(red, 0.1);
  font-weight: bold;
  //margin-top: 10px;
  //transform: translateX(5px);
  border-radius: 3px;
  text-transform: uppercase;
  box-shadow: 1px 1px 4px rgba(black, 0.3);

  &:hover {
    background-color: rgba(red, 0.3);
  }

  &.green {
    background-color: rgba(#a1fc03, 0.3);
    border: 1px solid #a1fc03;
    color: green;

    &:hover {
      background-color: rgba(#a1fc03, 0.5);
    }
  }
}

.grid-stack .grid-stack-item .ui-resizable-handle {
  background: rgba($orange, 0.4) !important;
  transition: all 0.3s;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  opacity: 1;
  top: 4px !important;
  right: -1px;
  bottom: 5px;
  height: auto;
  max-height: none;
  transform: none;
  width: 10px;
  position: absolute;
  z-index: 9;
}

.dna-dark {
  .grid-stack-placeholder {
    .placeholder-content {
      background-color: rgba(black, 0.2) !important;
      box-shadow: inset 2px 2px 2px rgba(black, 0.3);
    }
  }
}

.grid-stack-placeholder {
  transition: all 0.2s !important;
  .placeholder-content {
    border-radius: 4px;
    width: 100%;

    @include bg50;
    box-shadow: inset 2px 2px 2px rgba(black, 0.2);
  }
}
