.dna-people {
  padding: 0;
  margin: 0;
  display: block;
  overflow: visible;
  min-height: 30px;

  li.dna-people-person {
    list-style: none;
    float: left;
    display: inline-block;

    &:nth-child(even) {
      margin: 23px -5px 0 -5px;
      .MuiBadge-root {
        transform: translate(-7px, 1px);
      }
    }

    .MuiBadge-root {
      pointer-events: none;
      transform: translate(-10px, -22px);
    }

    &.dna-person-disabled {
      filter: grayscale(1);
      opacity: 0.5;
      filter: blur(1px);
      pointer-events: none;

      &:hover {
        filter: none;
      }
    }

    &.dna-people-add-trigger {
      position: relative;

      .dna-avatar {
        transition: all 0.2s ease-in;
        box-shadow: none;
        background: linear-gradient(to right, #00c9ff, #92fe9d);
      }
      .dna-icon-x {
        transition: all 0.2s;
        stroke: white !important;
      }

      .dna-avatar-inner {
        transition: background 0.2s ease-in;
        background: none;
      }

      &:hover {
        .dna-avatar {
          box-shadow: 2px 2px 0 black;
        }
        .dna-avatar-inner {
          background: $blueAccent;
        }
        .dna-icon-x {
          transform: rotate(45deg) scale(1);
          stroke: black !important;
        }
      }

      .dna-icon-x {
        transform: rotate(45deg);
        display: block;
        position: absolute;
        left: 8px;
        top: 11px;
        pointer-events: none;
      }
    }

    &.dna-people-overflow {
      position: relative;
      cursor: pointer;
      .dna-avatar-inner {
        transition: 0.3s;
        @include bgSolid;
      }
      &:hover {
        .dna-avatar-inner {
          background-color: $orange !important;
        }
      }
      .dna-flip-text {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 9px;
        position: absolute;
        font-weight: normal;
        left: 0px;
        top: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.dna-people-small {
    &.x-people-round {
      li.dna-people-person {
        width: 22px;
        height: 22px;
        &:not(:first-child) {
          margin-left: -5px;
        }
        &.dna-people-overflow {
          width: 10px;
        }
      }
    }
  }

  &.x-people-round {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li.dna-people-person {
      float: none;
      &.dna-people-overflow {
        width: 10px;
      }
    }
  }

  &.dna-people-small:not(.x-people-round) {
    li.dna-people-person {
      &:nth-child(even) {
        margin: 14px -4px 0 -4px;

        &.dna-people-add-trigger {
          margin: 10px -6px 0 -6px;
        }
      }
    }

    .dna-people-add-trigger {
      transform: scale(0.7);
      margin: -3px 0px 0 -3px;
      .dna-icon-x {
        transform: rotate(45deg);
        display: block;
        position: absolute;
        left: 5px !important;
        top: 8px !important;
        opacity: 0.8;
        pointer-events: none;
      }
    }
  }

  &.x-people-round {
    min-height: unset;
    li.dna-people-person {
      min-width: unset;
      min-height: unset;
      width: 32px;
      height: 32px;
      transform: none;
      margin: 0;
      align-self: flex-start;
      margin-right: 3px;
      transform: none;
      display: flex;
      align-items: center;

      .t-tip {
        display: block !important;
        width: inherit;
        height: inherit;
        align-self: flex-start;
        transform: none;
      }

      div.dna-round-avatar,
      div.dna-round-avatar.small {
        min-width: unset;
        max-width: unset;
        width: inherit;
        height: inherit;
        align-self: flex-start;
        transform: none;
      }

      .x-p-round-trigger {
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $blue;
        color: white;

        font-size: 22px;
        cursor: pointer;
        &:hover {
          background-color: $orange;
        }
      }
    }
  }
}

.dna-dark {
  .dna-add-people-card {
    background-size: 640px, cover !important;
    background-position: -40px -300px, center center;

    input {
      text-align: left;
      background-color: $bgDark !important;
    }
  }
}

.dna-add-people-card {
  z-index: 9999 !important;
  min-height: 200px;
  background-image: url(/img/brand-shape-commet.svg), linear-gradient(to right, #56ccf2, #2f80ed) !important;
  background-size: 550px, cover !important;
  background-repeat: no-repeat, repeat;
  background-position: -40px -280px, center center;

  input {
    text-align: left;
    @include bgSolid;
  }

  .dna-people-list-item {
    border-bottom: 1px dotted rgba(gray, 0.6);
    align-items: flex-start;
  }

  .x-dna-person-adder {
    padding: 20px;

    h3 {
      text-align: left;
      margin: 0;
    }
    label {
      width: 100%;
      text-align: left;
      padding-left: 5px;
      margin-top: 5px;
    }
  }
  .dna-people-invite {
    width: 100%;
    border-radius: 7px;
    margin-top: 3px;
    padding: 20px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include bg05;

    &:hover {
      background-color: rgba($lime, 0.3);
    }

    p {
      margin: 0;
      padding: 0;
      padding-left: 15px;
    }
  }
}

.dna-dark {
  .dna-people {
    li.dna-people-add-trigger {
      .dna-avatar {
        //border: 1px solid rgba($light,0.3);
      }
    }
  }
}
.dna-avatar.dna-person-pending {
  box-shadow: 0 0 5px 1px rgba($accent, 0.3);

  .dna-avatar-img {
    background-color: $accent;
    background-blend-mode: luminosity;
  }
}

.dna-round-avatar.dna-person-pending {
  background-color: $accent;
  background-blend-mode: luminosity;
}

.dna-red-avatar {
  background-color: $accent !important;
  background-blend-mode: luminosity;
}
