.x-of-dep-tags {
  .react-tags__suggestions {
    min-width: 350px;
  }
}

.x-of-of-tags {
  .react-tags__suggestions {
    min-width: 350px;
  }
}
