.tags-none {
  display: none;
}

.react-tags {
  position: relative;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.2;
  cursor: text;
  display: inline-block;

  .no-more-tags & {
    .react-tags__search-input {
      display: none;
    }
  }

  &.no-more-tags {
    .react-tags__search-input {
      display: none;
    }
  }

  &.dna-hash-tags {
    button,
    .x-hash-tag {
      border: 0;
      background: none;
      border-radius: 0;
      padding-bottom: 0px;
      transform: translateY(1px);
      cursor: pointer;
      padding: 0;
      padding-right: 8px;
      padding-left: 10px;
      border: 0;
      position: relative;
      margin-right: 6px;

      span {
        display: inline-block;
        font-weight: normal;
        transform: translateY(0px);
      }
      &:hover {
        color: $accent;
        &:after {
          display: none;
          opacity: 1;
          color: red !important;
        }
      }
      &:before {
        display: inline-block;
        position: absolute;
        left: 0;
        pointer-events: none;
        content: "#";
      }
      &:after {
        display: none;
        position: absolute;
        font-size: 10px;
        opacity: 0;
        right: 0;
        top: 3px;
      }
    }
  }
  &.dna-hash-tags {
    .x-hash-tag {
      transform: unset;
      display: inline-flex;
      padding-left: 0;
      min-height: 33px;
      &:before {
        display: none;
        position: absolute;
        left: 0;
        pointer-events: none;
      }

      .x-ht-label:hover {
        color: #4287f5;
      }
      .x-ht-link {
        opacity: 0.2;
        margin-left: 4px;
        transform: translateY(1px);
        cursor: pointer;
        &:hover {
          opacity: 1;
          color: $accent;
        }
      }
    }
  }
}

.react-tags.is-focused {
  //  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
  button {
    border: 0;
    background-color: #f5f5f5;
    border: 1px solid #e7e7e7;
    height: 40px;
    outline: none;
  }
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 3px 3px 0;
  padding: 4px 6px;
  user-select: none;
  border-radius: 3px;
  font-family: inherit;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: inherit;

  .after-x {
    display: block;
    width: 20px;
    height: 18px;
    padding-top: 1px;
    cursor: pointer;
    &:hover {
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: "\2715";
      margin-left: 8px;
      opacity: 0.1;
      cursor: pointer;
      margin-top: 3px;
      transition: all 0.25s ease-in;
    }
  }

  &.no-after {
    display: inline-flex;

    &:after {
      display: none !important;
    }
  }
}

.react-tags__selected-tag:after {
  content: "\2715";
  margin-left: 8px;
  opacity: 0.1;
  cursor: pointer;
  margin-top: 3px;
  transition: all 0.25s ease-in;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  &.after-x {
    opacity: 1;
  }

  &:after {
    opacity: 1;
  }
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  padding-left: 5px;
  min-width: 150px;
  border-radius: 3px;
  &.rt-min-300 {
    min-width: 300px;
  }

  &.rt-min-200 {
    min-width: 200px;
  }
  &.rt-min-100 {
    min-width: 100px;
  }

  /* remove styles and layout from this element */
  margin: 0;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.flip-tags {
  .react-tags__suggestions {
    transform: translateY(-100%);
    top: -8px;
  }
}
.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 3px 4px rgba(black, 0.3);
  border-radius: 6px;
  background-color: #fafafa;
  padding: 0px;
  margin-top: 2px;
  z-index: 999999;
  width: 100%;
  max-height: 300px;
  overflow: auto;

  > ul {
    background: #fafafa;
    box-shadow: 0 3px 10px rgba($dark, 0.4);
    border-radius: 3px;

    > li {
      padding: 12px 10px;
      cursor: pointer;
      text-align: left;
      border-radius: 3px;
      color: $dark;
      text-align: left;
      cursor: pointer;
      list-style: none;
      margin-left: 0;
      padding: 15px 10px;
      cursor: pointer;
      letter-spacing: 1px;
      font-weight: 300;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.react-tags__suggestions {
  min-width: 200px;
  max-width: 400px;
}

.rt-dd-300 {
  .react-tags__suggestions {
    min-width: 300px;
    max-width: 500px;
  }
}
.rt-dd-400 {
  .react-tags__suggestions {
    min-width: 400px;
    max-width: 600px;
  }
}
.rt-dd-500 {
  .react-tags__suggestions {
    min-width: 500px;
    max-width: 600px;
  }
}

.react-tags__suggestions ul {
  padding: 0;
  list-style: none;
  //  background: white;
}

.react-tags__suggestions li mark {
  background: yellow;
  color: $dark;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  //  background: #eee;
}

.react-tags__suggestions li.is-active {
  //background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.dna-dark {
  .react-tags__selected {
    button {
      color: $light;
      border-color: #666 !important;
      background-color: rgba($light, 0.3);
    }
  }
  .dna-hash-tags {
    .react-tags__selected {
      button {
        background-color: transparent;
      }
    }
  }
  .dna-light {
    .react-tags__selected {
      button {
        color: $dark;
        background-color: rgba($dark, 0.08);
      }
    }
  }
}
.dna-light {
  .react-tags__selected {
    button {
      color: $dark;
      background-color: rgba($dark, 0.08);
    }
  }
}
