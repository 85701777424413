.dna-drive-view--audio {
  transition: opacity 30ms ease;

  &-is-hidden {
    opacity: 0;
  }

  audio {
    width: 800px;
    max-width: 100%;
    outline: none;
  }

}