.dna-web-robot {
  path,
  rect {
    fill: $dark;
  }
}

.dna-icon-leave {
  &:hover {
    circle {
      fill: orange;
    }
  }
}

.dna-icon-check-mark {
  transform: translateY(12px);
}

.dna-path-icon {
  path {
    fill: $dark;
  }
}
.dna-dark {
  .dna-path-icon {
    path {
      fill: white;
    }
  }
}

.dna-svg-icon {
  &.dna-icon-path {
    polygon,
    path {
      fill: $dark;
    }
    fill: $dark;
  }

  &.dna-icon-rect {
    rect {
      fill: $dark;
    }
  }

  &.dna-icon-stroke {
    stroke: $dark;

    polygon,
    path,
    circle {
      stroke: $dark;
    }
  }
  .fill-me {
    stroke: $dark;
  }
}

.dna-icon-dep {
  rect,
  path {
    fill: none;
    stroke: $dark;
  }
}

.dna-check-mark {
  polygon {
    fill: $dark;
  }
}

.dna-dark {
  .dna-icon-dep {
    rect,
    path {
      fill: none;
      stroke: white;
    }
  }

  .dna-icon-oh-check {
    .oh-path {
      &.oh-circle {
        stroke: $light;
      }
      &.oh-check {
        stroke: $light;
      }
    }
  }
}

.dna-icon-oh-check {
  .oh-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;

    &.oh-circle {
      animation: dash 1s ease-in-out forwards;
      stroke: $dark;
    }

    &.oh-check {
      stroke-dashoffset: -100;
      animation: dash-check 0.5s 0.5s ease-in-out forwards;
      stroke: $dark;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.dna-icon-expand {
  transform: rotate(90deg);
  path {
    transform: translate(-50%, -50%);
  }
}

.dna-icon-dots {
  transform: scale(0.6);
  cursor: pointer;
  transition: all 0.3s ease-in;
  &:hover {
    transform: scale(0.8);
  }
  circle {
    stroke: none;
    fill: $dark;
  }
}

.dna-icon-info {
  cursor: pointer;
  opacity: 0.5;
  transform: translate(3px, 2px);
  &:hover {
    opacity: 1;
  }
}

.dna-icon-settings {
  path {
    fill: $dark;
  }
}
.dna-icon-lock {
  path {
    transform: translateX(-50%);
  }
}

.dna-icon-actions {
  rect {
    transition: all 0.3s ease-in-out;
    transform: translateY(5px);
  }
  &:hover {
    rect:nth-child(1) {
      transform: translateY(0px);
    }
    rect:nth-child(2) {
      transform: translateY(0px);
      transition-delay: 0.2s;
    }
    rect:nth-child(4) {
      transform: translateY(0px);
      transition-delay: 0.4s;
    }
    rect:nth-child(3) {
      transform: translateY(0px);
      transition-delay: 0.6s;
    }
  }
}

.dna-icon-search {
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  cursor: pointer;
  transition: all 0.3s;
  transform-origin: center;
}
.dna-icon-board {
  cursor: pointer;
  path {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  &:hover {
    path:nth-child(2) {
      transform: translateY(10px);
      transition-delay: 0.15s;
    }
    path:last-child {
      transform: translateY(5px);
    }
  }
}

.dna-icon-speeder {
  > g {
    fill: $dark;
  }
  .speed-dial {
    transition: all 0.4s;
    transform: translate(-1px, 5px) rotate(-100deg);
    transform-origin: center;
  }
  &:hover {
    .speed-dial {
      transition: all 0.2s;
      transform: translate(1px, 4px) rotate(80deg);
      transform-origin: center;
      fill: aquamarine;
    }
  }
}

.dna-icon-logo {
  circle {
    fill: $dark;
  }
}

.dna-icon-bot {
  circle:first-child {
    stroke: $dark;
  }
  circle:last-child {
    fill: $dark;
  }
}

.dna-dark {
  .dna-web-robot {
    path,
    line,
    rect {
      fill: $light;
    }
    line {
      stroke: white;
    }
  }

  .dna-icon-settings {
    path {
      fill: $light;
    }
  }

  .dna-icon-speeder {
    > g {
      fill: #fff;
    }
  }
  .dna-icon-logo {
    circle {
      fill: $light;
    }
  }
  .dna-icon-bot {
    circle:first-child {
      stroke: $light;
    }
    circle:last-child {
      fill: $light;
    }
  }
  .dna-icon-actions {
    .fill-me {
      stroke: #fff;
    }
  }
  .dna-svg-icon {
    &.dna-icon-path {
      polygon,
      path {
        fill: $light;
      }
      fill: $light;
    }

    &.dna-icon-rect {
      rect {
        fill: $light;
      }
    }
    &.dna-icon-stroke {
      stroke: $light;
      polygon,
      path {
        stroke: $light;
      }
    }
    .fill-me {
      stroke: $light;
    }
  }

  .dna-light {
    .dna-svg-icon {
      &.dna-icon-path {
        polygon,
        path {
          fill: $dark !important;
        }
        fill: $dark !important;
      }
    }
  }

  .dna-icon-note {
    path {
      stroke: $light;
    }
  }

  .dna-icon-discussion {
    path {
      stroke: $light;
    }
    polygon {
      stroke: $light;
    }
  }
}

.dna-light {
  .dna-svg-icon {
    &.dna-icon-path {
      fill: $dark;
    }
  }
}

.dna-icon-discussion {
  path {
    stroke: $dark;
  }
  polygon {
    stroke: $dark;
  }
}

.dna-icon-arrow {
  &.right {
    transform: rotate(-90deg);
  }
  &.left {
    transform: rotate(90deg);
  }
  &.up {
    transform: rotate(180deg);
  }
}

.dna-icon-note {
  path {
    stroke: $dark;
  }
}
.dna-icon-msg {
}

.dna-icon-mc {
  overflow: visible;
  rect {
    fill: $dark;
  }
  path {
    position: fixed;
  }
}

.dna-flip-bg {
  .dna-svg-icon {
    &.dna-icon-path {
      fill: $light;
    }
  }
}

.sr-icon-speeder {
  > g {
    fill: $dark;
  }
  .speed-dial {
    transition: all 0.4s;
    transform: translate(-1px, 5px) rotate(-100deg);
    transform-origin: center;
  }
  &:hover {
    .speed-dial {
      transition: all 0.2s;
      transform: translate(1px, 4px) rotate(80deg);
      transform-origin: center;
    }
  }
}

.dna-dark {
  .dna-flip-bg {
    .dna-svg-icon {
      &.dna-icon-path {
        fill: $dark;
      }
    }
  }
  .dna-icon-dots {
    circle {
      stroke: none;
      fill: $light;
    }
  }

  .dna-light {
    .dna-icon-dots {
      circle {
        stroke: none;
        fill: $dark;
      }
    }
  }
  .sr-icon-speeder {
    > g {
      fill: #fff;
    }
  }
}

.MuiIconButton-root {
  path {
    color: $dark;
  }
}

.dna-dark {
  .MuiIconButton-root {
    background-color: rgba(white, 0);

    &:hover {
      background-color: rgba(white, 0.1);
    }
  }
  .MuiIconButton-root {
    path {
      color: white;
    }
  }
  .MuiSvgIcon-root {
    path {
      fill: white;
    }
  }
}
