@import "./vars";

.dna-table {
  display: table;

  border-collapse: collapse;

  th {
    @include bg05;
    padding: 10px 10px;
    vertical-align: top;
    font-size: 13px;
    text-transform: uppercase;
  }
  td {
    padding: 10px 10px;
    font-size: 13px;
    input {
      font-size: 13px;
    }

    &.right {
      text-align: right;
    }
  }
  tbody {
    tr:hover {
      background-color: rgba($lime, 0.1);
    }
  }

  &-thead {
    display: table-header-group;
  }

  &-tbody {
    display: table-row-group;
  }

  &-colgroup {
    display: table-column-group;
  }

  &-row {
    display: table-row;
  }

  &-cell {
    display: table-cell;
    vertical-align: top;

    &-middle {
      display: table-cell;
      vertical-align: middle;
    }

    &-bottom {
      display: table-cell;
      vertical-align: bottom;
    }
  }
}
