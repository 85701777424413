div {
  .input-range {
    height: 2px;
    position: relative;
    width: 100%;
    * {
      transition: all 0.1s ease;
    }
  }
  .input-range__slider {
    appearance: none;
    background: none;
    border: 2px solid $dark;
    background: $light;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.55rem;
    outline: none;
    position: absolute;
    top: 50%;
    width: 1rem;
  }
  .input-range__slider:active {
  }

  .input-range__slider:focus {
  }
  .input-range--disabled .input-range__slider {
    background: rgba($dark, 0.3);
    border: 0px;
    box-shadow: none;
    transform: none;
  }
  .input-range__slider-container {
  }
  .input-range__track {
    background: rgba($dark, 0.2);
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 2px;
    position: relative;
  }
  .input-range--disabled .input-range__track {
    background: rgba($dark, 0.1);
  }
  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .input-range__track--active {
    background: $dark;
  }

  .input-range__label {
    //color: #aaaaaa;
    //font-family: "Helvetica Neue",san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }
  .input-range__label--min {
    left: 15px;
  }
  .input-range__label--max {
    right: 18px;
  }
  .input-range__label--value {
    position: absolute;
    top: -1.7rem;
  }
  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }
}

.dna-dark {
  div {
    .input-range__slider {
      border: 2px solid $light;
      background: $dark;
    }
  }
  .input-range__track {
    background: rgba($light, 0.3);
  }
  .input-range--disabled .input-range__slider {
    background: rgba($light, 0.3);
  }
  .input-range__track--active {
    background: $light;
  }
}
