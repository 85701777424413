.x-pm-board {
  height: 100vh;
  background: url(/img/vsb.svg);
  z-index: 0;
  position: relative;
  .dna-tabs-list {
    margin-right: 200px;
    margin-top: 15px;
    margin-left: 200px;
    width: auto;

    .x-tab {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    svg {
      transform: translate(-1px, 2px);
      margin-right: 5px;
    }
  }

  .x-board-panels {
    margin-top: 0px;
  }
}
